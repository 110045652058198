<template>
	<div :style="textColor" class="cuadrado">
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="6">
				<p v-if="lowerCaseName !== 'accion'" class="mb-1 pl-1 title-section">
					Seleccionar bien
				</p>
				<v-container>
					<v-row v-if="lowerCaseName !== 'accion'" class="cuadrado">
						<v-col cols="12">
							<select-triangular
								:asset-property-name="room_select_text"
								:background-color="themeColors.selectColor"
								:color="themeColors.selectTextColor"
								@reset-data="resetSubAssetsInitialData"
							/>
						</v-col>
						<v-col cols="12 my-2">
							<select-triangular
								asset-property-name="bathrooms"
								:background-color="themeColors.selectColor"
								:color="themeColors.selectTextColor"
								@reset-data="resetSubAssetsInitialData"
							/>
						</v-col>
						<v-col cols="12">
							<select-triangular
								asset-property-name="apartment"
								:background-color="themeColors.selectColor"
								:color="themeColors.selectTextColor"
								@reset-data="resetSubAssetsInitialData"
							/>
						</v-col>
					</v-row>
				</v-container>
				<v-row
					v-if="projectData.warehouseService || projectData.parkingService"
					class="mt-2"
					no-gutters
				>
					<p
						v-if="getApartmentInfo.associationStatus != 'BIEN_DESVINCULADO'"
						class="ml-2"
					>
						<span
							v-if="projectData.warehouseService && projectData.parkingService"
							class="mr-2"
							>Seleccionar Estacionamiento y/o Bodega</span
						>
						<span
							v-else-if="
								!projectData.warehouseService && projectData.parkingService
							"
							class="mr-2"
							>Seleccionar Estacionamiento</span
						>
						<span v-else class="mr-2">Seleccionar Bodega</span>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-avatar class="mr-2" color="#A1A4A1" size="20">
									<span
										v-bind="attrs"
										class="white--text"
										style="font-size: 15px;"
										v-on="on"
										>?</span
									>
								</v-avatar>
							</template>
							<span
								>Los bienes que son agregados automáticamente son bienes
								conjuntos, no se pueden vender por separado</span
							>
						</v-tooltip>
					</p>
					<select-sub-assets ref="subAssets" class="mt-1" />
				</v-row>
			</v-col>
			<v-col cols="12" sm="12" md="6">
				<p v-if="!hideSelectedAssets" class="mb-5 pl-1 title-section">
					Bienes cotizados
				</p>
				<table class="mb-3 w-100" style="border: 0px">
					<tr>
						<td style="border: 0px">
							<button
								:class="{
									'mr-3 px-3 outline-customer': true,
									active: currency == 'UF',
								}"
								@click="setCurrency('UF')"
							>
								MOSTRAR EN UF
							</button>
							<button
								:class="{
									'px-3 outline-customer': true,
									active: currency == 'CLP',
								}"
								@click="setCurrency('CLP')"
							>
								MOSTRAR EN PESOS
							</button>
						</td>
					</tr>
				</table>

				<v-row
					v-if="!hideSelectedAssets"
					class="mb-5 px-1"
					align="center"
					justify="center"
					no-gutters
				>
					<table-sub-assets-var
						:show-total="true"
						:from-mainview="true"
						text-align="text-start"
						:without-borders="true"
						:currency="currency"
					/>
				</v-row>
			</v-col>
			<v-col cols="12" class="my-4" style="background: #efefeb;">
				<v-row class="mb-1" align="center" justify="center" no-gutters>
					<v-col>
						<v-container fluid>
							<v-row>
								<v-col cols="12" md="6">
									<div class="title-section">
										{{ projectData.projectName }}
									</div>
									<image-dialog
										:thumb="`${getApartmentInfo.image}`"
										:full="`${getApartmentInfo.image}`"
										:mobile="
											this.$vuetify.breakpoint.name == 'xs' ||
											this.$vuetify.breakpoint.name == 'sm'
												? true
												: false
										"
										:style="
											` max-height: 400px: max-width:${imgWidth}px ${
												sendingQuote ? '' : 'z-index: 200; position:relative;'
											} `
										"
									></image-dialog>
								</v-col>
								<v-col cols="12" md="6">
									<p
										class="h4 mb-5"
										style="font-size: 20px; padding-top: 40px;"
									>
										{{ getApartmentInfo.assetType }}
										{{
											getApartmentInfo.assetNumber
												? getApartmentInfo.assetNumber
												: getApartmentInfo.type
										}}
									</p>
									<p class="my-5"><b>PISO:</b> {{ getApartmentInfo.piso }}</p>
									<p class="my-5">
										<b>ORIENTACIÓN:</b> {{ getApartmentInfo.orientation }}
									</p>
									<p class="my-5">
										<b>BAÑO{{ getApartmentInfo.bathrooms > 1 ? 'S' : '' }}:</b>
										{{ getApartmentInfo.bathrooms }}
									</p>
									<p class="my-5">
										<b
											>DORMITORIO{{ getApartmentInfo.rooms > 1 ? 'S' : '' }}:</b
										>
										{{ getApartmentInfo.rooms }}
									</p>
									<p class="my-5">
										<b>SUP. TOTAL:</b>
										{{
											new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(getApartmentInfo.surface)
										}}
										m<sup>2</sup>
									</p>
									<p class="my-5">
										<b>SUP. TERRAZA:</b>
										{{
											new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(getApartmentInfo.terrace)
										}}
										m<sup>2</sup>
									</p>
									<p class="my-4">
										<b>SUP. INTERIOR: </b>
										{{
											new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(getApartmentInfo.inner)
										}}
										m<sup>2</sup>
									</p>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12">
				<p class="pl-1 title-section">
					Ingresa tus datos
				</p>
				<v-container fluid>
					<quote-form ref="formRef" class="mt-2" :no-gutters="false" />
				</v-container>
				<v-row no-gutters class="mt-1">
					<v-col>
						<p v-if="quoteLimit != null && quoteLimit <= 0" style="color: red">
							Ha excedido el número de cotizaciones permitidas por día.
						</p>
						<v-btn
							id="moby-quote-button"
							:color="themeColors.color3"
							x-large
							block
							class="mt-2"
							:style="
								`color: ${
									quoteLimit != null && quoteLimit <= 0
										? '#000'
										: themeColors.buttonTextColor
								}; font-size: 16px;  border-radius: 0px;`
							"
							:disabled="quoteLimit != null && quoteLimit <= 0 ? true : false"
							@click="$refs.formRef.submitForm()"
						>
							{{ textButton }}
						</v-btn>
					</v-col>
					<v-col
						v-if="getOpportunityStatus && !reservation"
						cols="12"
						xs="12"
						sm="6"
					>
						<v-btn
							id="moby-reservation-button"
							:color="themeColors.color3"
							x-large
							block
							class="mt-2 ml-sm-2"
							:style="
								`color: ${themeColors.buttonTextColor}; font-size: 16px; border-radius: 0px;`
							"
							@click="$refs.formRef.submitForm(true)"
						>
							Reservar
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import SelectTriangular from '@/components/SelectTriangular.vue'
import texts from '../mixins/TemplateTexts'
import SelectSubAssets from '@/components/SelectSubAssets.vue'
import TableSubAssetsVar from '@/components/TableSubAssetsVar.vue'
import ImageDialog from '@/components/ImageDialog.vue'
let stepNumber = 1
export default {
	name: 'Default',
	components: {
		QuoteForm,
		SelectTriangular,
		SelectSubAssets,
		TableSubAssetsVar,
		ImageDialog,
	},
	mixins: [texts],
	data() {
		return {
			currency: 'UF',
		}
	},
	computed: {
		...mapState('form', ['countryCode', 'sendingQuote']),
		...mapState('maps', ['useMap']),
		...mapState('general', [
			'projectData',
			'quoteLimit',
			'reservation',
			'hideSelectedAssets',
		]),
		...mapState('assets', ['masterAssetType']),
		...mapGetters('assets', ['getApartmentInfo', 'getOpportunityStatus']),
		...mapGetters('maps', ['getAssetMaps']),
		...mapState('theme', ['themeColors']),
		imgWidth() {
			return this.$vuetify.breakpoint.name == 'xs' ? '90%' : 540
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
		textColor() {
			return {
				'--text-color': this.themeColors.selectTextColor,
				'background-color': this.themeColors.color1,
			}
		},
		lowerCaseName() {
			return this.masterAssetType.join(',').toLowerCase()
		},
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		this.setUfValue()
		document.dispatchEvent(event)
	},
	methods: {
		...mapActions('general', ['setUfValue']),
		resetSubAssetsInitialData(data) {
			if (
				data == 'apartment' &&
				this.getApartmentInfo.associationStatus != null
			)
				this.$refs.subAssets.resetInitialData()
		},
		updateStepNumber(num = null) {
			return num ? (stepNumber = num) : (stepNumber += 1)
		},
		setCurrency(currency) {
			this.currency = currency
		},
	},
}
</script>
<style lang="scss">
mobysuite-quote {
	.v-application p {
		margin: 0px;
	}
	.v-input > .v-input__control > .v-input__slot:before {
		border-color: var(--text-color) !important;
	}
	.cuadrado {
		font-weight: 400;
		.v-input fieldset {
			border: 0px !important;
			border-bottom: 1px solid var(--text-color) !important;
			border-radius: 0px !important;
		}
		.title-section {
			font-size: 23px !important;
			padding: 20px !important;
			color: var(--text-color);
			font-weight: bold;
		}
		.outline-customer {
			border: 1px solid var(--color3) !important;
			color: var(--color3) !important;
			background-color: var(--button-text-color) !important;
			padding: 10px 20px !important;
			font-size: 12px !important;
			font-weight: bold;
			&:hover {
				background-color: var(--color3) !important;
				color: var(--button-text-color) !important;
			}
			&.active {
				background-color: var(--color3) !important;
				color: var(--button-text-color) !important;
			}
		}
		.image-dialog-thumb {
			mix-blend-mode: multiply;
		}
		table {
			border: 0px;
			font-size: 14px;
			font-weight: 400;
			p {
				font-size: 14px;
				font-weight: 400;
			}
			td {
				height: 48px;
				vertical-align: middle;
			}
		}
	}
}
</style>
