const getters = {
	removeDuplicatedRoomsBathrooms: () => apartmentsList => {
		let cleanedApartmentList = []

		for (let apartment of apartmentsList) {
			let areEqual = false

			for (let newApartment of cleanedApartmentList) {
				if (
					apartment.bathroomsAmount == newApartment.bathroomsAmount &&
					apartment.roomsAmount == newApartment.roomsAmount
				) {
					areEqual = true
					break
				}
			}

			if (!areEqual) {
				cleanedApartmentList = [...cleanedApartmentList, apartment]
			}
		}

		return cleanedApartmentList
	},
	getApartmentsRoomsBathrooms: (_state, getters, rootState, rootGetters) => (
		typology = null
	) => {
		const apartmentTypeName = rootState.assets.masterAssetType
		const bathroomsAmountPropertyName =
			rootState.assets.propertyNames.bathroomsAmount
		const roomsAmountPropertyName = rootState.assets.propertyNames.roomsAmount
		const typePropertyName = rootState.assets.propertyNames.type

		let apartmentsList = rootGetters['assets/getAssetByType'](apartmentTypeName)

		if (typology)
			apartmentsList = apartmentsList.filter(apartment =>
				typology.includes(apartment[typePropertyName])
			)

		apartmentsList = apartmentsList.map(apartment => {
			return {
				bathroomsAmount: apartment[bathroomsAmountPropertyName],
				roomsAmount: apartment[roomsAmountPropertyName],
			}
		})

		const cleanedApartmentList = getters.removeDuplicatedRoomsBathrooms(
			apartmentsList
		)

		return ['TODOS', ...cleanedApartmentList]
	},
	getApartmentsTypologiesByRoomsAmountsAndBathroomsAmounts: (
		_state,
		_getters,
		rootState,
		rootGetters
	) => (roomsAndBathroomsAmounts = null) => {
		const apartmentTypeName = rootState.assets.masterAssetType
		const typePropertyName = rootState.assets.propertyNames.type
		const bathroomsAmountPropertyName =
			rootState.assets.propertyNames.bathroomsAmount
		const roomsAmountPropertyName = rootState.assets.propertyNames.roomsAmount

		const apartmentsList = rootGetters['assets/getAssetByType'](
			apartmentTypeName
		).filter(
			apartment => ![null, undefined, ''].includes(apartment[typePropertyName])
		)

		let apartmentTypesList = ['TODOS']

		let apartmentTypesListTmp = apartmentsList.map(
			apartment => apartment[typePropertyName]
		)

		if (!roomsAndBathroomsAmounts || roomsAndBathroomsAmounts == 'TODOS')
			return [...apartmentTypesList, ...new Set(apartmentTypesListTmp)]

		const {
			bathroomsAmount = null,
			roomsAmount = null,
		} = roomsAndBathroomsAmounts

		if ([roomsAmount, bathroomsAmount].includes(null))
			return [...apartmentTypesList, ...new Set(apartmentTypesListTmp)]

		apartmentTypesListTmp = apartmentsList
			.filter(
				apartment =>
					apartment[bathroomsAmountPropertyName] == bathroomsAmount &&
					apartment[roomsAmountPropertyName] == roomsAmount
			)
			.map(apartment => apartment[typePropertyName])

		return [...apartmentTypesList, ...new Set(apartmentTypesListTmp)]
	},
	getApartmentsByTypologyOnly: (_state, _getters, rootState, rootGetters) => (
		typology = null,
		roomsAndBathroomsAmounts = null
	) => {
		const apartmentTypeName = rootState.assets.masterAssetType
		const typePropertyName = rootState.assets.propertyNames.type
		const bathroomsAmountPropertyName =
			rootState.assets.propertyNames.bathroomsAmount
		const roomsAmountPropertyName = rootState.assets.propertyNames.roomsAmount

		let apartmentsList = rootGetters['assets/getAssetByType'](apartmentTypeName)

		if (roomsAndBathroomsAmounts && roomsAndBathroomsAmounts != 'TODOS') {
			const {
				bathroomsAmount = null,
				roomsAmount = null,
			} = roomsAndBathroomsAmounts

			if (![roomsAmount, bathroomsAmount].includes(null)) {
				apartmentsList = apartmentsList.filter(
					apartment =>
						apartment[bathroomsAmountPropertyName] == bathroomsAmount &&
						apartment[roomsAmountPropertyName] == roomsAmount
				)
			}
		}

		if (typology && !typology.includes('TODOS'))
			apartmentsList = apartmentsList.filter(apartment =>
				typology.includes(apartment[typePropertyName])
			)

		return apartmentsList
	},
}

export default {
	namespaced: true,
	getters,
}
