const state = {
	filteredAssets: [],
	noAssetSelected: true,
	skipSecondaryAssetsValidation: false,
}

const getters = {
	getFilteredAssets: (state, getters, rootState) => {
		return state.filteredAssets.map(asset => {
			return {
				id: asset.id,
				number: asset[rootState.assets.propertyNames.assetNumber],
				price: asset[rootState.assets.propertyNames.price],
				rooms: asset[rootState.assets.propertyNames.roomsAmount],
				bathrooms: asset[rootState.assets.propertyNames.bathroomsAmount],
				totalSurface:
					asset[rootState.assets.propertyNames.totalArea] > 0
						? asset[rootState.assets.propertyNames.totalArea].toFixed(2)
						: 0,
				utilSurface:
					asset[rootState.assets.propertyNames.utilSurface] > 0
						? asset[rootState.assets.propertyNames.utilSurface].toFixed(2)
						: 0,
				terraceArea:
					asset[rootState.assets.propertyNames.terraceArea] > 0
						? asset[rootState.assets.propertyNames.terraceArea].toFixed(2)
						: 0,
				orientation: asset[rootState.assets.propertyNames.orientation]
					? asset[rootState.assets.propertyNames.orientation] > 0 ||
					  asset[rootState.assets.propertyNames.orientation] != 'EMPTY'
						? asset[rootState.assets.propertyNames.orientation]
						: '-'
					: '-',
				floor: asset[rootState.assets.propertyNames.floor]
					? asset[rootState.assets.propertyNames.floor]
					: '-',
				typology: asset[rootState.assets.propertyNames.type],
				gardenSurface: asset[rootState.assets.propertyNames.superficieJardin],
			}
		})
	},
	getOrientationOptions: (state, getters, rootState, rootGetters) => {
		const propertyValueList = rootGetters['assets/getAssetPropertyByType'](
			rootState.assets.masterAssetType,
			rootState.assets.propertyNames.orientation
		)

		const cleanedPropertyValueList = [...new Set(propertyValueList)]
			.filter(propertyValue => ![null, undefined].includes(propertyValue))
			.sort()

		return cleanedPropertyValueList
	},
	getFloorOptions: (state, getters, rootState, rootGetters) => {
		const propertyValueList = rootGetters['assets/getAssetPropertyByType'](
			rootState.assets.masterAssetType,
			rootState.assets.propertyNames.floor
		)

		const cleanedPropertyValueList = [...new Set(propertyValueList)]
			.filter(propertyValue => ![null, undefined].includes(propertyValue))
			.sort()

		return cleanedPropertyValueList
	},
	getTypologyOptions: (state, getters, rootState, rootGetters) => {
		const propertyValueList = rootGetters['assets/getAssetPropertyByType'](
			rootState.assets.masterAssetType,
			rootState.assets.propertyNames.type
		)

		const cleanedPropertyValueList = [...new Set(propertyValueList)]
			.filter(propertyValue => ![null, undefined].includes(propertyValue))
			.sort()

		return cleanedPropertyValueList
	},
	getFilter: (state, getters, rootState) => filter => {
		let assets = []
		if (filter.name == 'price' || filter.name == 'utilSurface') {
			assets = state.filteredAssets.filter(asset => {
				if (
					filter.data.min &&
					filter.data.max &&
					asset[rootState.assets.propertyNames[filter.name]] >=
						filter.data.min &&
					asset[rootState.assets.propertyNames[filter.name]] <= filter.data.max
				)
					return true
				else if (
					!filter.data.min &&
					filter.data.max &&
					asset[rootState.assets.propertyNames[filter.name]] <= filter.data.max
				)
					return true
				else if (
					filter.data.min &&
					!filter.data.max &&
					asset[rootState.assets.propertyNames[filter.name]] >= filter.data.min
				)
					return true
			})
		} else {
			assets = state.filteredAssets.filter(asset => {
				return filter.data.includes(
					asset[rootState.assets.propertyNames[filter.name]]
				)
			})
		}
		return assets
	},
	getAssetSelectedStatus: state => state.noAssetSelected,
	getSkipSecondaryAssetsValidation: state =>
		state.skipSecondaryAssetsValidation,
}

const mutations = {
	setNoAssetSelected: (state, payload) => (state.noAssetSelected = payload),
	setSkipSecondaryAssetsValidation: (state, value) =>
		(state.skipSecondaryAssetsValidation = value),
}

const actions = {
	setFilteredAssets: ({ state, rootState, rootGetters, getters }, filters) => {
		state.filteredAssets = rootGetters['assets/getAssetByType'](
			rootState.assets.masterAssetType
		)
		if (filters)
			filters.forEach(filter => {
				if (
					(filter.type == 'selection' && filter.data.length > 0) ||
					(filter.type == 'range' && (filter.data.min || filter.data.max))
				)
					state.filteredAssets = getters.getFilter(filter)
			})
	},
	orderBy: ({ state, rootState }, params) => {
		switch (params.type) {
			case 'number':
				if (params.order == 'minor') {
					state.filteredAssets.sort((a, b) => {
						return (
							a[rootState.assets.propertyNames[params.field]] -
							b[rootState.assets.propertyNames[params.field]]
						)
					})
				} else {
					state.filteredAssets.sort((a, b) => {
						return (
							b[rootState.assets.propertyNames[params.field]] -
							a[rootState.assets.propertyNames[params.field]]
						)
					})
				}
				break
			case 'text':
				if (params.order == 'minor') {
					state.filteredAssets.sort((a, b) => {
						let fa = a[rootState.assets.propertyNames[params.field]]
								? a[rootState.assets.propertyNames[params.field]].toLowerCase()
								: null,
							fb = b[rootState.assets.propertyNames[params.field]]
								? b[rootState.assets.propertyNames[params.field]].toLowerCase()
								: null

						if (fa < fb) {
							return -1
						}
						if (fa > fb) {
							return 1
						}
						return 0
					})
				} else {
					state.filteredAssets.sort((a, b) => {
						let fa = a[rootState.assets.propertyNames[params.field]]
								? a[rootState.assets.propertyNames[params.field]].toLowerCase()
								: null,
							fb = b[rootState.assets.propertyNames[params.field]]
								? b[rootState.assets.propertyNames[params.field]].toLowerCase()
								: null

						if (fa > fb) {
							return -1
						}
						if (fa < fb) {
							return 1
						}
						return 0
					})
				}
				break
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
