<template>
	<v-row
		v-if="
			this.$vuetify.breakpoint.name == 'sm' ||
				this.$vuetify.breakpoint.name == 'xs' ||
				mobileStyle
		"
		no-gutters
		:class="
			projectData.projectLogoUrl
				? 'd-flex justify-space-between'
				: 'd-flex justify-center'
		"
		:style="`background-color:${backgroundColor}; color:${textColor}`"
		align="center"
		justify="center"
	>
		<v-img
			contain
			class="ml-2 mt-2"
			cols="3"
			max-height="80px"
			max-width="120px"
			:src="realEstateLogoUrl"
		/>
		<v-img
			v-if="projectData.projectLogoUrl && showProjectLogo"
			contain
			class="mr-2 mt-2"
			cols="3"
			max-height="80px"
			max-width="120px"
			:src="projectData.projectLogoUrl"
		/>
		<v-col v-if="!hideProjectData" class="text-center" cols="12">
			<p class="text-h5">
				{{ title }}
			</p>
			<p class="subtitle-1 font-weight-thin">
				<font-awesome-icon v-if="icon.length" :icon="icon" />
				{{ subtitle }}
			</p>
		</v-col>
	</v-row>
	<v-row
		v-else
		no-gutters
		:style="`background-color:${backgroundColor}; color:${textColor}`"
		justify="center"
		align="center"
		:class="
			projectData.projectLogoUrl
				? 'd-flex justify-space-between pb-3'
				: 'd-flex justify-center pb-3'
		"
	>
		<v-col
			:justify="title != '' || projectData.projectLogoUrl ? 'start' : 'center'"
			:align="title != '' || projectData.projectLogoUrl ? 'start' : 'center'"
		>
			<v-img
				contain
				class="ml-2"
				max-height="80px"
				max-width="150px"
				:src="realEstateLogoUrl"
			/>
		</v-col>

		<v-col
			v-if="title != '' && !hideProjectData"
			justify="center"
			align="center"
		>
			<p
				:class="
					subtitle != ''
						? 'font-weight-black text-h5'
						: 'font-weight-thin text-h4'
				"
			>
				{{ title }}
			</p>
			<p class="subtitle-1 font-weight-thin">
				<font-awesome-icon v-if="icon.length" :icon="icon" />
				{{ subtitle }}
			</p>
		</v-col>
		<v-col
			v-if="projectData.projectLogoUrl || title != ''"
			class="d-flex justify-end"
		>
			<v-img
				v-if="projectData.projectLogoUrl && showProjectLogo"
				contain
				class="mr-2"
				max-height="80px"
				max-width="150px"
				:src="projectData.projectLogoUrl"
			/>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Banner',
	props: {
		backgroundColor: {
			type: String,
			default: '#000',
		},
		textColor: {
			type: String,
			default: '#FFF',
		},
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		icon: {
			type: Array,
			default: () => [],
		},
		mobileStyle: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('general', [
			'projectData',
			'realEstateLogoUrl',
			'showProjectLogo',
		]),
		...mapState('theme', ['hideProjectData']),
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
</style>
