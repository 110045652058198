var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("background-color:" + (_vm.themeColors.color1) + "; color:" + (_vm.themeColors.color2)))},[_c('banner',{attrs:{"title":_vm.projectData.projectName,"subtitle":_vm.projectData.address,"icon":['fas', 'map-marker-alt'],"background-color":_vm.themeColors.color1,"text-color":_vm.themeColors.color2}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('p',{staticClass:"text-body-1 mb-1"},[_c('v-avatar',{staticClass:"mr-1",attrs:{"color":_vm.themeColors.color3,"size":"24"}},[_c('span',{style:(("font-size: 15px; color: " + (_vm.themeColors.buttonTextColor)))},[_vm._v(_vm._s(_vm.updateStepNumber(1)))])]),_vm._v(" Seleccionar Estacionamiento/Bodega ")],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('select-triangular',{attrs:{"asset-property-name":"lote","background-color":_vm.themeColors.selectColor,"color":_vm.themeColors.selectTextColor}})],1)],1),_c('v-row',{staticClass:"mb-1",attrs:{"align":"center","justify":"center"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","align":"center","justify":"center"}},[_c('p',{staticClass:"font-weight-black"},[_vm._v("Superficie Total")]),_vm._v(" "+_vm._s(new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format(_vm.getApartmentInfo.surface))+" m"),_c('sup',[_vm._v("2")])])],1)],1)]),_c('v-row',{attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('v-col',[_c('image-dialog',{style:((" max-height: 400px: max-width:" + _vm.imgWidth + "px " + (_vm.sendingQuote ? '' : 'z-index: 200; position:relative;') + " ")),attrs:{"thumb":("" + (_vm.getApartmentInfo.image)),"full":("" + (_vm.getApartmentInfo.image)),"mobile":this.$vuetify.breakpoint.name == 'xs' ||
							this.$vuetify.breakpoint.name == 'sm'
								? true
								: false}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[(!_vm.hideSelectedAssets)?_c('p',{staticClass:"text-body-1 mb-5"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":_vm.themeColors.color3,"size":"24"}},[_c('span',{staticClass:"white--text",style:(("font-size: 15px; color: " + (_vm.themeColors.buttonTextColor)))},[_vm._v(_vm._s(_vm.updateStepNumber()))])]),_vm._v(" Bienes cotizados ")],1):_vm._e(),(!_vm.hideSelectedAssets)?_c('v-row',{staticClass:"mb-5",attrs:{"align":"center","justify":"center","no-gutters":""}},[_c('div',{style:(("width:95%; color:" + (_vm.themeColors.color2)))},[_c('v-row',[_c('v-col',{style:(("border: 1px ridge " + (_vm.themeColors.color2))),attrs:{"cols":"6","align":"center","justify":"center"}},[_vm._v("Tipo bien "),_c('br')]),_c('v-col',{style:(("border: 1px ridge " + (_vm.themeColors.color2))),attrs:{"cols":"6","align":"center","justify":"center"}},[_vm._v("Precio lista "),_c('br')])],1),_c('v-row',[_c('v-col',{style:(("border: 1px ridge " + (_vm.themeColors.color2))),attrs:{"cols":"6","align":"center","justify":"center"}},[_vm._v(_vm._s(_vm.getApartmentInfo.title))]),_c('v-col',{style:(("border: 1px ridge " + (_vm.themeColors.color2))),attrs:{"cols":"6","align":"center","justify":"center"}},[_vm._v(" "+_vm._s(new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format(_vm.getApartmentInfo.price))+" UF "),(_vm.getApartmentInfo.discount)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":""}},'v-chip',attrs,false),on),[_c('font-awesome-icon',{staticStyle:{"margin-right":"3px"},attrs:{"icon":['fas', 'tag']}}),_vm._v(" dto ")],1)]}}],null,false,946612108)},[(
										_vm.getApartmentInfo.discount[_vm.propertyNames.amountType] ==
											'PORCENTAJE'
									)?_c('span',{domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											_vm.getApartmentInfo.discount[
												_vm.propertyNames.discountAmount
											] * 100
										)) + "%")
									)}}):_vm._e(),(
										_vm.getApartmentInfo.discount[_vm.propertyNames.amountType] ==
											'CONSTANTE'
									)?_c('span',{domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											_vm.getApartmentInfo.discount[_vm.propertyNames.discountAmount]
										)) + " UF")
									)}}):_vm._e(),(
										_vm.getApartmentInfo.discount[_vm.propertyNames.amountType] ==
											'MONTO'
									)?_c('span',{domProps:{"textContent":_vm._s(
										("descuento $" + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											_vm.getApartmentInfo.discount[_vm.propertyNames.discountAmount]
										)))
									)}}):_vm._e(),(
										_vm.getApartmentInfo.discount[_vm.propertyNames.amountType] ==
											'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
											_vm.getApartmentInfo[_vm.propertyNames.nonAuthorizedDiscount] !=
												null
									)?_c('span',{domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											_vm.getApartmentInfo[_vm.propertyNames.nonAuthorizedDiscount] *
												100
										)) + "%")
									)}}):_vm._e(),(
										_vm.getApartmentInfo.discount[_vm.propertyNames.amountType] ==
											'MONTO_DESCUENTO_BIEN' &&
											_vm.getApartmentInfo[_vm.propertyNames.authorizedDiscount] !=
												null
									)?_c('span',{domProps:{"textContent":_vm._s(
										("descuento " + (new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(
											_vm.getApartmentInfo[_vm.propertyNames.authorizedDiscount] * 100
										)) + "%")
									)}}):_vm._e()]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{style:(("border: 1px ridge " + (_vm.themeColors.color2))),attrs:{"cols":"6","align":"center","justify":"center"}},[_vm._v("Total")]),_c('v-col',{style:(("border: 1px ridge " + (_vm.themeColors.color2))),attrs:{"cols":"6","align":"center","justify":"center"}},[_vm._v(" "+_vm._s(new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format(_vm.getApartmentInfo.price))+" UF")])],1)],1)]):_vm._e(),_c('p',{staticClass:"text-body-1"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":_vm.themeColors.color3,"size":"23"}},[_c('span',{style:(("font-size: 15px; color: " + (_vm.themeColors.buttonTextColor)))},[_vm._v(_vm._s(_vm.updateStepNumber()))])]),_vm._v(" Ingresa tus datos de contacto ")],1),_c('quote-form',{ref:"formRef",staticClass:"mt-2"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_vm.quoteLimit != null && _vm.quoteLimit <= 0)?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" Ha excedido el número de cotizaciones permitidas por día. ")]):_vm._e(),_c('v-btn',{style:(("color: " + (_vm.quoteLimit != null && _vm.quoteLimit <= 0
									? '#000'
									: _vm.themeColors.buttonTextColor) + "; font-size: 16px")),attrs:{"id":"moby-quote-button","color":_vm.themeColors.color3,"block":"","x-large":"","disabled":_vm.quoteLimit != null && _vm.quoteLimit <= 0 ? true : false},on:{"click":function($event){return _vm.$refs.formRef.submitForm()}}},[_vm._v(" "+_vm._s(_vm.textButton)+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }