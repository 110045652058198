<template>
	<div>
		<v-autocomplete
			v-model="field"
			:outlined="outlined"
			:solo="solo"
			dense
			:dark="theme"
			:items="options"
			:label="label"
			:error-messages="errorMessages"
			no-data-text="No hay datos disponibles"
			:background-color="themeColors.inputColor1"
			:color="isInvalid ? themeColors.inputColor2 : '#219224'"
			:style="`color:${isInvalid ? themeColors.inputColor2 : '#219224'}`"
			:attach="true"
			@input="customValidation"
			@blur="touch()"
		></v-autocomplete>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'InputSelect',
	props: {
		label: { type: String, default: 'Seleccionar' },
		options: { type: Array, default: () => [] },
		errorMessages: { type: Array, default: () => [] },
		customValidation: { type: Function, default: () => null },
		touch: { type: Function, default: () => null },
		theme: { type: Boolean, default: false },
		outlined: { type: Boolean, default: false },
		solo: { type: Boolean, default: false },
		isInvalid: { type: Boolean, default: false },
		value: { type: String, default: null },
	},
	data() {
		return {
			field: null,
		}
	},
	computed: {
		...mapState('form', ['fieldValues']),
		...mapState('theme', ['themeColors']),
	},
	created() {
		this.field = this.options[
			this.options.findIndex(item => item == this.value)
		]
	},
}
</script>
