<template>
	<div
		v-if="
			getApartmentInfo.associationStatus != 'BIEN_DESVINCULADO' &&
				!getDiscountAssetsValidation
		"
		style="width:100%"
	>
		<v-row
			v-if="!projectData.warehouseService && !projectData.parkingService"
			no-gutters
			align="center"
			justify="center"
		>
			Error. Esta funcionalidad no está disponible para este proyecto.
		</v-row>
		<div
			v-else-if="
				(projectData.warehouseService || projectData.parkingService) && !useMap
			"
		>
			<v-row
				no-gutters
				:align="
					!projectData.warehouseService || !projectData.parkingService
						? 'start'
						: 'center'
				"
				:justify="
					!projectData.warehouseService || !projectData.parkingService
						? 'start'
						: 'center'
				"
			>
				<v-col
					v-if="projectData.parkingService"
					style="display:contents;"
					cols="6"
				>
					<v-select
						ref="parkingLotSelectComponent"
						v-model="selectedParkingLotId"
						class="ma-1 float-right"
						hide-details
						outlined
						dense
						:items="parkingLotsList"
						item-value="id"
						item-text="addedText"
						label="Estacionamiento"
						:background-color="themeColors.selectColor"
						:color="themeColors.selectTextColor"
						:style="`color:${themeColors.selectTextColor}`"
						:attach="true"
						@change="addParkingLot"
					/>
				</v-col>
				<v-col
					v-if="projectData.warehouseService"
					style="display:contents;"
					cols="6"
				>
					<v-select
						ref="warehouseSelectComponent"
						v-model="selectedWarehouseId"
						class="ma-1 float-right"
						hide-details
						dense
						outlined
						:items="warehousesList"
						item-value="id"
						item-text="addedText"
						label="Bodega"
						:background-color="themeColors.selectColor"
						:color="themeColors.selectTextColor"
						:style="`color:${themeColors.selectTextColor}`"
						:attach="true"
						@change="addWarehouse"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="warehousesList.length > 0 || parkingLotsList.length > 0"
				no-gutters
			>
				<v-col
					v-if="projectData.parkingService"
					cols="6"
					class="px-1 d-flex align-start"
					style="flex-wrap: wrap !important;"
				>
					<v-chip
						v-for="parkingLot in selectedParkingLotsList"
						:key="parkingLot.id"
						class="ma-1"
					>
						E - {{ parkingLot.selectText }}
						<v-btn
							fab
							max-height="15px"
							max-width="15px"
							class="elevation-0 ml-2"
							color="error"
							@click="removeParkingLot(parkingLot.id)"
						>
							<font-awesome-icon
								style="font-size: 13px;"
								:icon="['fas', 'times']"
							/>
						</v-btn>
					</v-chip>
				</v-col>
				<v-col
					v-if="projectData.warehouseService"
					cols="6"
					class="px-1 d-flex align-start"
					style="flex-wrap: wrap !important;"
				>
					<v-chip
						v-for="warehouse in selectedWarehousesList"
						:key="warehouse.id"
						class="ma-1"
					>
						B - {{ warehouse.selectText }}
						<v-btn
							fab
							max-height="15px"
							max-width="15px"
							class="elevation-0 ml-2"
							color="error"
							@click="removeWarehouse(warehouse.id)"
						>
							<font-awesome-icon
								style="font-size: 13px;"
								:icon="['fas', 'times']"
							/>
						</v-btn>
					</v-chip>
				</v-col>
			</v-row>
		</div>
		<div
			v-else-if="
				(projectData.warehouseService || projectData.parkingService) && useMap
			"
		>
			<asset-map
				:color="themeColors.color3"
				:initial-assets="
					getNotSelectableSubAssets.length > 0 ? getNotSelectableSubAssets : []
				"
			></asset-map>
		</div>

		<v-dialog :value="showSecondaryAssetsDialog" width="500">
			<v-card>
				<v-card-title class="headline grey lighten-2">
					Estimado cliente
				</v-card-title>
				<div v-if="subAssetsGlobalMinNumber == null">
					<v-card-text v-if="!minParkingLotsNumberFulfilled">
						Debe seleccionar al menos
						{{ parkingLotsMinNumber }} estacionamiento(s).
					</v-card-text>
					<v-card-text v-else-if="!minWarehouseNumberFulfilled">
						Debe seleccionar al menos {{ warehousesMinNumber }} bodega(s).
					</v-card-text>
				</div>
				<v-card-text v-else>
					Debe seleccionar al menos {{ subAssetsGlobalMinNumber }} bienes
					complementarios para poder cotizar este bien.
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="setShowSecondaryAssetsDialog(false)"
					>
						Aceptar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog :value="showMaxNumberAlert" width="500">
			<v-card>
				<v-card-title class="headline grey lighten-2">
					Estimado cliente
				</v-card-title>
				<div v-if="subAssetsGlobalMaxNumber == null">
					<v-card-text v-if="parkingDisabled && parkingLotSelected">
						No puede cotizar más de
						{{ parkingLotsMaxNumber }} estacionamiento(s).
					</v-card-text>
					<v-card-text v-else-if="warehouseDisabled && wareHouseSelected">
						No puede cotizar más de {{ warehousesMaxNumber }} bodega(s).
					</v-card-text>
				</div>
				<v-card-text v-else>
					No puede cotizar más de {{ subAssetsGlobalMaxNumber }} bienes
					complementarios con este bien.
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="resetAlertData()">
						Aceptar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog :value="showSubAssetHasMasterAsset" width="500">
			<v-card>
				<v-card-title class="headline grey lighten-2">
					Estimado cliente
				</v-card-title>

				<v-card-text>
					No es posible seleccionar este bien, ya que es parte de un bien
					maestro.
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="setShowSubAssetHasMasterAsset(false)"
					>
						Aceptar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
	<v-row v-else no-gutters align="center" justify="center">
		<p style="font-style: italic">
			El bien que ha seleccionado no tiene estacionamientos ni bodegas
			disponibles
		</p>
	</v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import AssetMap from '@/components/AssetMap.vue'

export default {
	name: 'SelectSubAssets',
	components: {
		AssetMap,
	},
	data() {
		return {
			selectedWarehouseId: null,
			selectedWarehousesList: [],
			selectedParkingLotId: null,
			selectedParkingLotsList: [],
			warehousesList: [],
			parkingLotsList: [],
			warehouseDisabled: false,
			parkingDisabled: false,
			showMaxNumberAlert: false,
			wareHouseSelected: false,
			parkingLotSelected: false,
			subAssetsGlobalMaxNumber: null,
			subAssetsGlobalMinNumber: null,
		}
	},
	computed: {
		...mapGetters('assets', [
			'getWarehouses',
			'getParkingLots',
			'getApartmentInfo',
			'getAssociatedStatus',
			'getDiscountAssetsValidation',
			'getNotSelectableSubAssets',
		]),
		...mapState('general', [
			'projectData',
			'warehousesMaxNumber',
			'parkingLotsMaxNumber',
			'warehousesMinNumber',
			'parkingLotsMinNumber',
			'minParkingLotsNumberFulfilled',
			'minWarehouseNumberFulfilled',
			'showSecondaryAssetsDialog',
			'version',
		]),
		...mapState('assets', [
			'showSubAssetHasMasterAsset',
			'associatedLimits',
			'secondaryAssetsToQuote',
		]),
		...mapState('theme', ['themeColors']),
		...mapState('maps', ['useMap']),
	},
	watch: {
		getWarehouses: {
			handler: function() {
				if (this.getWarehouses) {
					this.warehousesList = this.getWarehouses
					if (this.version == 2) {
						this.warehousesList = this.warehousesList.map(asset => {
							if (asset.bMaestro.length > 0) console.log(asset.bMaestro)
							return {
								id: asset.id,
								selectText: `N° ${asset.assetNumber}`,
								addedText: `N° ${asset.assetNumber} ${
									asset.bMaestro.length > 0
										? '(Agregar ' + asset.bMaestro[0].assetNumber + ')'
										: ''
								}`,
								disabled: asset.bMaestro.length > 0 ? true : false,
								bMaestro: asset.bMaestro,
								bConjunto: asset.bConjunto,
							}
						})
					} else {
						this.warehousesList = this.warehousesList.map(asset => {
							return {
								id: asset.id,
								selectText: `N° ${asset.assetNumber}`,
								addedText: `N° ${asset.assetNumber} ${
									asset.bMaestro
										? '(Agregar ' + asset.bMaestro.numeroDeBien + ')'
										: ''
								}`,
								disabled: asset.bMaestro ? true : false,
								bMaestro: asset.bMaestro,
								bConjunto: asset.bConjunto,
							}
						})
					}
				}
			},
			immediate: true,
		},
		getParkingLots: {
			handler: function() {
				if (this.getParkingLots) {
					this.parkingLotsList = this.getParkingLots
					if (this.version == 2) {
						this.parkingLotsList = this.parkingLotsList.map(asset => {
							return {
								id: asset.id,
								selectText: `N° ${asset.assetNumber}`,
								addedText: `N° ${asset.assetNumber} ${
									asset.bMaestro.length > 0
										? '(Agregar ' + asset.bMaestro[0].assetNumber + ')'
										: ''
								}`,
								disabled: asset.bMaestro.length > 0 ? true : false,
								bMaestro: asset.bMaestro,
								bConjunto: asset.bConjunto,
							}
						})
					} else {
						this.parkingLotsList = this.parkingLotsList.map(asset => {
							return {
								id: asset.id,
								selectText: `N° ${asset.assetNumber}`,
								addedText: `N° ${asset.assetNumber} ${
									asset.bMaestro
										? '(Agregar ' + asset.bMaestro.numeroDeBien + ')'
										: ''
								}`,
								disabled: asset.bMaestro ? true : false,
								bMaestro: asset.bMaestro,
								bConjunto: asset.bConjunto,
							}
						})
					}
				}
			},
			immediate: true,
		},
		getAssociatedStatus: {
			handler: function() {
				if (this.getAssociatedStatus != null) {
					this.resetInitialData()
					this.resetSecondaryAssetsToQuote()
				}
			},
			immediate: true,
		},
		getDiscountAssetsValidation: {
			handler: function() {
				if (this.getDiscountAssetsValidation) {
					this.resetInitialData()
					this.resetSecondaryAssetsToQuote()
				}
			},
			immediate: true,
		},
		associatedLimits: {
			handler: function() {
				this.subAssetsGlobalMaxNumber = this.associatedLimits.max
				this.subAssetsGlobalMinNumber = this.associatedLimits.min
			},
			immediate: true,
		},
	},
	created() {},
	methods: {
		...mapMutations('assets', [
			'removeSecondaryAssetToQuote',
			'setShowSubAssetHasMasterAsset',
			'addSecondaryAssetToQuote',
			'setIncreaseSecondaryAssetsAmount',
		]),
		...mapMutations('general', ['setShowSecondaryAssetsDialog']),
		...mapActions('assets', [
			'addSecondaryAsset',
			'resetSecondaryAssetsToQuote',
		]),
		async addWarehouse() {
			if (
				this.subAssetsGlobalMaxNumber != null &&
				this.secondaryAssetsToQuote.length == this.subAssetsGlobalMaxNumber
			) {
				this.warehouseDisabled = true
				this.parkingDisabled = true
			}
			const showMessage = await this.addSecondaryAsset({
				id: this.selectedWarehouseId,
				assetTypeName: 'warehouse',
				filter: this.warehouseDisabled,
				version: this.version,
			})
			if (!this.warehouseDisabled)
				this.setShowSubAssetHasMasterAsset(showMessage)
			if (!this.showSubAssetHasMasterAsset && !this.warehouseDisabled) {
				this.warehousesList = this.warehousesList.map(asset => {
					if (asset.id == this.selectedWarehouseId) {
						asset.disabled = true
						this.selectedWarehousesList = [
							...this.selectedWarehousesList,
							asset,
						]
						if (asset.bConjunto) {
							asset.bConjunto.map(bConjunto => {
								if (bConjunto.tipoBien == 'ESTACIONAMIENTO') {
									this.selectedParkingLotsList = [
										...this.selectedParkingLotsList,
										this.selectedParkingLotsList.find(
											parking => parking.id == bConjunto.id
										),
									]
									this.addSecondaryAssetToQuote(bConjunto.id)
									this.setIncreaseSecondaryAssetsAmount('parkingLot')
								}
							})
						}
					}
					return asset
				}, this)
				if (
					this.selectedWarehousesList.length > this.warehousesMaxNumber - 1 &&
					this.warehousesMaxNumber != null &&
					this.subAssetsGlobalMaxNumber == null
				) {
					this.warehouseDisabled = true
				}
			} else if (this.warehouseDisabled) {
				this.showMaxNumberAlert = true
				this.wareHouseSelected = true
			}
			this.$refs.warehouseSelectComponent.reset()
			this.$refs.parkingLotSelectComponent.reset()
		},
		async addParkingLot() {
			if (
				this.subAssetsGlobalMaxNumber != null &&
				this.secondaryAssetsToQuote.length == this.subAssetsGlobalMaxNumber
			) {
				this.warehouseDisabled = true
				this.parkingDisabled = true
			}
			const showMessage = await this.addSecondaryAsset({
				id: this.selectedParkingLotId,
				assetTypeName: 'parkingLot',
				filter: this.parkingDisabled,
				version: this.version,
			})
			if (!this.parkingDisabled) this.setShowSubAssetHasMasterAsset(showMessage)
			if (!this.showSubAssetHasMasterAsset && !this.parkingDisabled) {
				this.parkingLotsList = this.parkingLotsList.map(asset => {
					if (asset.id == this.selectedParkingLotId) {
						asset.disabled = true
						this.selectedParkingLotsList = [
							...this.selectedParkingLotsList,
							asset,
						]
						if (asset.bConjunto) {
							asset.bConjunto.map(bConjunto => {
								if (bConjunto.tipoBien == 'BODEGA') {
									this.selectedWarehousesList = [
										...this.selectedWarehousesList,
										this.warehousesList.find(
											warehouse => warehouse.id == bConjunto.id
										),
									]
									this.addSecondaryAssetToQuote(bConjunto.id)
									this.setIncreaseSecondaryAssetsAmount('warehouse')
								}
							})
						}
					}
					return asset
				}, this)
				if (
					this.selectedParkingLotsList.length > this.parkingLotsMaxNumber - 1 &&
					this.parkingLotsMaxNumber != null &&
					this.subAssetsGlobalMaxNumber == null
				) {
					this.parkingDisabled = true
				}
			} else if (this.parkingDisabled) {
				this.showMaxNumberAlert = true
				this.parkingLotSelected = true
			}
			this.$refs.parkingLotSelectComponent.reset()
			this.$refs.warehouseSelectComponent.reset()
		},
		removeWarehouse(id) {
			this.removeSecondaryAssetToQuote({ id, assetTypeName: 'warehouse' })

			this.warehousesList = this.warehousesList.map(asset => {
				if (asset.id == id) {
					if (!asset.bMaestro) asset.disabled = false
					this.selectedWarehousesList = this.selectedWarehousesList.filter(
						asset => asset.id != id
					)
					if (asset.bConjunto) {
						asset.bConjunto.map(bConjunto => {
							if (bConjunto.tipoBien == 'ESTACIONAMIENTO') {
								this.selectedParkingLotsList = this.selectedParkingLotsList.filter(
									parking => parking.id != bConjunto.id
								)
								this.removeSecondaryAssetToQuote({
									id: bConjunto.id,
									assetTypeName: 'parkingLot',
								})
							}
						})
					}
					if (asset.bMaestro) {
						if (asset.bMaestro.tipoBien == 'ESTACIONAMIENTO') {
							this.selectedParkingLotsList = this.selectedParkingLotsList.filter(
								parking => parking.id != asset.bMaestro.id
							)
							this.parkingLotsList.find(
								parking => parking.id == asset.bMaestro.id
							).disabled = false
							this.removeSecondaryAssetToQuote({
								id: asset.bMaestro.id,
								assetTypeName: 'parkingLot',
							})
						}
					}
				}
				return asset
			}, this)
			this.warehouseDisabled = false
			if (this.subAssetsGlobalMaxNumber != null) this.parkingDisabled = false
		},
		removeParkingLot(id) {
			this.removeSecondaryAssetToQuote({ id, assetTypeName: 'parkingLot' })

			this.parkingLotsList = this.parkingLotsList.map(asset => {
				if (asset.id == id) {
					if (!asset.bMaestro) asset.disabled = false
					this.selectedParkingLotsList = this.selectedParkingLotsList.filter(
						asset => asset.id != id
					)
					if (asset.bConjunto) {
						asset.bConjunto.map(bConjunto => {
							if (bConjunto.tipoBien == 'BODEGA') {
								this.selectedWarehousesList = this.selectedWarehousesList.filter(
									warehouse => warehouse.id != bConjunto.id
								)
								this.removeSecondaryAssetToQuote({
									id: bConjunto.id,
									assetTypeName: 'warehouse',
								})
							}
						})
					}
					if (asset.bMaestro) {
						if (asset.bMaestro.tipoBien == 'BODEGA') {
							this.selectedWarehousesList = this.selectedWarehousesList.filter(
								warehouse => warehouse.id != asset.bMaestro.id
							)
							this.warehousesList.find(
								warehouse => warehouse.id == asset.bMaestro.id
							).disabled = false
							this.removeSecondaryAssetToQuote({
								id: asset.bMaestro.id,
								assetTypeName: 'warehouse',
							})
						}
					}
				}
				return asset
			})

			this.parkingDisabled = false
			if (this.subAssetsGlobalMaxNumber != null) this.warehouseDisabled = false
		},
		resetAlertData() {
			this.wareHouseSelected = false
			this.parkingLotSelected = false
			this.showMaxNumberAlert = false
		},
		resetInitialData() {
			this.selectedWarehouseId = null
			this.selectedWarehousesList = []
			this.selectedParkingLotId = null
			this.selectedParkingLotsList = []
			this.warehousesList = []
			this.parkingLotsList = []
			this.warehouseDisabled = false
			this.parkingDisabled = false
			this.showMaxNumberAlert = false
			this.wareHouseSelected = false
			this.parkingLotSelected = false
			if (this.version == 2) {
				this.warehousesList = this.getWarehouses.map(asset => {
					return {
						id: asset.id,
						selectText: `N° ${asset.assetNumber}`,
						addedText: `N° ${asset.assetNumber} ${
							asset.bMaestro.length > 0
								? '(Agregar ' + asset.bMaestro[0].assetNumber + ')'
								: ''
						}`,
						disabled: asset.bMaestro.length > 0 ? true : false,
						bMaestro: asset.bMaestro,
						bConjunto: asset.bConjunto,
					}
				})
				this.parkingLotsList = this.getParkingLots.map(asset => {
					return {
						id: asset.id,
						selectText: `N° ${asset.assetNumber}`,
						addedText: `N° ${asset.assetNumber} ${
							asset.bMaestro.length > 0
								? '(Agregar ' + asset.bMaestro[0].assetNumber + ')'
								: ''
						}`,
						disabled: asset.bMaestro.length > 0 ? true : false,
						bMaestro: asset.bMaestro,
						bConjunto: asset.bConjunto,
					}
				})
			} else {
				this.warehousesList = this.getWarehouses.map(asset => {
					return {
						id: asset.id,
						selectText: `N° ${asset.assetNumber}`,
						addedText: `N° ${asset.assetNumber} ${
							asset.bMaestro
								? '(Agregar ' + asset.bMaestro.numeroDeBien + ')'
								: ''
						}`,
						disabled: asset.bMaestro ? true : false,
						bMaestro: asset.bMaestro,
						bConjunto: asset.bConjunto,
					}
				})
				this.parkingLotsList = this.getParkingLots.map(asset => {
					return {
						id: asset.id,
						selectText: `N° ${asset.assetNumber}`,
						addedText: `N° ${asset.assetNumber} ${
							asset.bMaestro
								? '(Agregar ' + asset.bMaestro.numeroDeBien + ')'
								: ''
						}`,
						disabled: asset.bMaestro ? true : false,
						bMaestro: asset.bMaestro,
						bConjunto: asset.bConjunto,
					}
				})
			}
		},
	},
}
</script>
