import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueCustomElement from 'vue-custom-element'
import Vuelidate from 'vuelidate'
import vuetify from '@/plugins/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faBuilding,
	faBath,
	faCar,
	faWarehouse,
	faCheckCircle,
	faExclamationCircle,
	faTimes,
	faMapMarkerAlt,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faAngleDown,
	faCaretDown,
	faTag,
	faTrash,
	faSearchPlus,
	faSearchMinus,
	faChevronDown,
	faChevronUp,
	faEraser,
	faSearch,
	faPlusCircle,
	faMinusCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
	faBedAlt,
	faBarcodeAlt,
	faTimesSquare,
	faChevronRight,
	faChevronDoubleDown,
	faChevronDoubleUp,
	faCheckSquare,
} from '@fortawesome/pro-solid-svg-icons'
import {
	faRulerTriangle,
	faBath as falBath,
	faBedAlt as falBedAlt,
	faRulerHorizontal as falRulerHorizontal,
	faLocationArrow,
	faHashtag,
	faHandHoldingUsd,
	faCompass,
	faRulerCombined,
	faSeedling,
	faTrashAlt,
	faLampFloor,
} from '@fortawesome/pro-light-svg-icons'
import { faSquare } from '@fortawesome/pro-regular-svg-icons'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import InlineSvg from 'vue-inline-svg'
import Panzoom from '@panzoom/panzoom'
import JwPagination from 'jw-vue-pagination'

Vue.config.productionTip = false
Vue.component('inline-svg', InlineSvg)
Object.defineProperty(Vue.prototype, '$panzoom', { value: Panzoom })
const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
})
//IMPORTANT: If you're using new vuetify components you MUST import its icons here in other case you can't see it
library.add(
	faBath,
	faBuilding,
	faCar,
	faWarehouse,
	faBedAlt,
	faRulerTriangle,
	faCheckCircle,
	faExclamationCircle,
	faTimes,
	faMapMarkerAlt,
	faBarcodeAlt,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faAngleDown,
	faCaretDown,
	faTag,
	faTrash,
	faTimesSquare,
	faChevronRight,
	faChevronDoubleDown,
	faChevronDoubleUp,
	faSearchPlus,
	faSearchMinus,
	falBath,
	falBedAlt,
	falRulerHorizontal,
	faLocationArrow,
	faHashtag,
	faHandHoldingUsd,
	faChevronDown,
	faChevronUp,
	faEraser,
	faSearch,
	faCompass,
	faRulerCombined,
	faSeedling,
	faPlusCircle,
	faMinusCircle,
	faSquare,
	faCheckSquare,
	faTrashAlt,
	faLampFloor
)

Vue.use(VueAxios, instance)
Vue.use(vueCustomElement)
Vue.use(Vuelidate)
Vue.use(VueTelInputVuetify, { vuetify })
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueReCaptcha, {
	siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
	loaderOptions: {
		autoHideBadge: true,
	},
})
Vue.component('jw-pagination', JwPagination)

App.router = router
App.store = store
App.vuetify = vuetify

Vue.customElement('mobysuite-quote', App)
