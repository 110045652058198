<template>
	<div
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2}`
		"
	>
		<banner
			:title="projectData.projectName"
			:subtitle="projectData.address"
			:icon="['fas', 'map-marker-alt']"
			:background-color="themeColors.color1"
			:text-color="themeColors.color2"
		/>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="6">
				<p class="text-body-1 mb-1">
					<v-avatar class="mr-1" :color="themeColors.color3" size="24">
						<span
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber(1) }}</span
						>
					</v-avatar>
					Seleccionar Estacionamiento/Bodega
				</p>
				<v-row no-gutters>
					<v-col>
						<select-triangular
							asset-property-name="lote"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
						/>
					</v-col>
				</v-row>
				<v-row class="mb-1" align="center" justify="center">
					<div style="width:100%">
						<v-row>
							<v-col cols="4" align="center" justify="center">
								<p class="font-weight-black">Superficie Total</p>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.surface)
								}}
								m<sup>2</sup></v-col
							>
						</v-row>
					</div>
				</v-row>
				<v-row align="center" justify="center" no-gutters>
					<v-col>
						<image-dialog
							:thumb="`${getApartmentInfo.image}`"
							:full="`${getApartmentInfo.image}`"
							:mobile="
								this.$vuetify.breakpoint.name == 'xs' ||
								this.$vuetify.breakpoint.name == 'sm'
									? true
									: false
							"
							:style="
								` max-height: 400px: max-width:${imgWidth}px ${
									sendingQuote ? '' : 'z-index: 200; position:relative;'
								} `
							"
						></image-dialog>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" sm="12" md="6">
				<p v-if="!hideSelectedAssets" class="text-body-1 mb-5">
					<v-avatar class="mr-2" :color="themeColors.color3" size="24">
						<span
							class="white--text"
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber() }}</span
						>
					</v-avatar>
					Bienes cotizados
				</p>
				<v-row
					v-if="!hideSelectedAssets"
					class="mb-5"
					align="center"
					justify="center"
					no-gutters
				>
					<div :style="`width:95%; color:${themeColors.color2}`">
						<v-row>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
								>Tipo bien <br
							/></v-col>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
								>Precio lista <br
							/></v-col>
						</v-row>
						<v-row>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
								>{{ getApartmentInfo.title }}</v-col
							>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
							>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.price)
								}}
								UF
								<v-tooltip v-if="getApartmentInfo.discount" bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-chip v-bind="attrs" small v-on="on">
											<font-awesome-icon
												style="margin-right: 3px"
												:icon="['fas', 'tag']"
											/>
											dto
										</v-chip>
									</template>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'PORCENTAJE'
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo.discount[
													propertyNames.discountAmount
												] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'CONSTANTE'
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo.discount[propertyNames.discountAmount]
											)} UF`
										"
									></span>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'MONTO'
										"
										v-text="
											`descuento $${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo.discount[propertyNames.discountAmount]
											)}`
										"
									></span>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
												getApartmentInfo[propertyNames.nonAuthorizedDiscount] !=
													null
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo[propertyNames.nonAuthorizedDiscount] *
													100
											)}%`
										"
									></span>
									<span
										v-if="
											getApartmentInfo.discount[propertyNames.amountType] ==
												'MONTO_DESCUENTO_BIEN' &&
												getApartmentInfo[propertyNames.authorizedDiscount] !=
													null
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												getApartmentInfo[propertyNames.authorizedDiscount] * 100
											)}%`
										"
									></span>
								</v-tooltip>
							</v-col>
						</v-row>
						<v-row>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
								>Total</v-col
							>
							<v-col
								cols="6"
								:style="`border: 1px ridge ${themeColors.color2}`"
								align="center"
								justify="center"
							>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.price)
								}}
								UF</v-col
							>
						</v-row>
					</div>
				</v-row>
				<p class="text-body-1">
					<v-avatar class="mr-2" :color="themeColors.color3" size="23">
						<span
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber() }}</span
						>
					</v-avatar>
					Ingresa tus datos de contacto
				</p>
				<quote-form ref="formRef" class="mt-2" />
				<v-row no-gutters>
					<v-col>
						<p v-if="quoteLimit != null && quoteLimit <= 0" style="color: red">
							Ha excedido el número de cotizaciones permitidas por día.
						</p>
						<v-btn
							id="moby-quote-button"
							:color="themeColors.color3"
							block
							x-large
							:style="
								`color: ${
									quoteLimit != null && quoteLimit <= 0
										? '#000'
										: themeColors.buttonTextColor
								}; font-size: 16px`
							"
							:disabled="quoteLimit != null && quoteLimit <= 0 ? true : false"
							@click="$refs.formRef.submitForm()"
						>
							{{ textButton }}
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import SelectTriangular from '@/components/SelectTriangular.vue'
import Banner from '@/components/Banner.vue'
import ImageDialog from '@/components/ImageDialog.vue'
let stepNumber = 1
export default {
	name: 'Secundarios',
	components: {
		QuoteForm,
		SelectTriangular,
		Banner,
		ImageDialog,
	},
	computed: {
		...mapState('general', [
			'projectData',
			'quoteLimit',
			'reservation',
			'hideSelectedAssets',
		]),
		...mapGetters('assets', ['getApartmentInfo']),
		...mapState('theme', ['themeColors']),
		imgWidth() {
			return this.$vuetify.breakpoint.name == 'xs' ? '90%' : 540
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
		textColor() {
			return {
				'--text-color': this.themeColors.selectTextColor,
			}
		},
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
	},
	methods: {
		updateStepNumber(num = null) {
			return num ? (stepNumber = num) : (stepNumber += 1)
		},
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
</style>
<style>
.v-input > .v-input__control > .v-input__slot:before {
	border-color: var(--text-color) !important;
}
</style>
