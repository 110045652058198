export default {
	computed: {
		commune_text() {
			return this.countryCode == 'MX' ? 'Municipio' : 'Comuna'
		},
		price_value() {
			return this.countryCode == 'MX' ? 'MXN' : 'UF'
		},
		room_select_text() {
			return this.countryCode == 'MX' ? 'rooms_mx' : 'rooms'
		},
		rooms_text() {
			return this.countryCode == 'MX' ? 'Recámaras' : 'Dormitorios'
		},
		surface_text() {
			return this.countryCode == 'MX' ? 'Área' : 'Superficie'
		},
	},
}
