export default {
	formData: {
		dniName: 'RFC',
		quoteName: 'Cotización',
		reservationName: 'Reserva',
	},
	quoteSlang: {
		quoteAction: 'Cotizar',
		quotedProducts: 'Productos cotizados',
	},
	tooltips: {
		DNI: 'Número y el dígito verificador separados por un guion',
	},
}
