<template>
	<v-container fluid fill-height>
		<v-row align="center">
			<v-col cols="10" offset="1">
				<div class="success-sub-title">
					{{ projectData.projectName }}
					{{ projectData.commune ? `• ${projectData.commune}` : '' }}
				</div>
				<div class="success-title my-2">
					{{
						quoteNotification.assets
							? quoteNotification.assets.find(asset =>
									[
										'CASA',
										'DEPARTAMENTO',
										'LOCAL',
										'OFICINA',
										'LOTE',
										'ACCION',
										'SITIO',
									].includes(asset.tipoBien)
							  ).tipoBien +
							  ' ' +
							  quoteNotification.assets.find(asset =>
									[
										'CASA',
										'DEPARTAMENTO',
										'LOCAL',
										'OFICINA',
										'LOTE',
										'ACCION',
										'SITIO',
									].includes(asset.tipoBien)
							  ).numeroDeBien
							: ''
					}}
					<br />
					UF
					{{
						quoteNotification.amount
							? new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
							  }).format(quoteNotification.total)
							: ''
					}}
				</div>

				<div class="success-sub-title small">
					Precios no incluyen estacionamientos, ni bodega.
				</div>
				<table class="px-4 py-4 table-payment-plan mt-2">
					<tr>
						<th colspan="4" class="pb-4">SIMULACIÓN DE FINANCIAMIENTO</th>
					</tr>
					<tr>
						<td style="width: 25%"></td>
						<td style="width: 25%"></td>
						<td style="width: 25%">UF</td>
						<td style="width: 25%">MONTO $</td>
					</tr>
					<template
						v-for="(paymentPlan,
						paymentPlanKey) in quoteNotification.paymentPlan"
					>
						<tr
							v-if="paymentPlan.descripcionFormaPago !== 'Reserva en garantía'"
							:key="`paymentplan${paymentPlanKey}`"
						>
							<td>{{ paymentPlan.descripcionFormaPago }}</td>
							<td>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(paymentPlan.pagoEnPorcentaje * 100)
								}}%
							</td>
							<td>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(paymentPlan.pagoEnValorDeAjuste)
								}}
							</td>
							<td>
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(paymentPlan.pagoEnMonedaLocal)
								}}
							</td>
						</tr>
					</template>
				</table>

				<table class="px-4 py-4 table-payment-plan mt-2">
					<tr>
						<td>{{ quoteNotification.creditInfo }}</td>
					</tr>
				</table>

				<v-row class="px-2 d-flex" align="center" justify="center">
					<v-col cols="12">
						<v-row class="mt-6">
							<v-col v-if="quoteNotification.success" cols="6">
								<v-btn
									min-height="60px"
									:color="themeColors.color3"
									class="my-2 rounded-0"
									block
									x-large
									:style="`color: ${themeColors.color3}; font-size: 16px`"
									outlined
									@click="redirectToSite(quoteNotification.pdfURL)"
								>
									Ver PDF
								</v-btn>
							</v-col>
							<v-col
								v-if="projectData.webReservation && quoteNotification.success"
								cols="6"
							>
								<v-btn
									min-height="60px"
									:color="themeColors.color3"
									class="my-2 rounded-0"
									block
									x-large
									:style="
										`color: ${themeColors.buttonTextColor}; font-size: 16px`
									"
									@click="redirectToSite(quoteNotification.reservationURL)"
								>
									Reservar
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						min-height="60px"
						:color="themeColors.color3"
						class="my-2 rounded-0"
						block
						x-large
						:style="`color: ${themeColors.buttonTextColor}; font-size: 16px`"
						@click="newQuote()"
					>
						Nueva cotización
					</v-btn>
				</v-card-actions>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PeruData from '@/Dictionaries/Peru.js'
import ChileData from '@/Dictionaries/Chile.js'
import MexicoData from '@/Dictionaries/Mexico.js'

export default {
	name: 'ViewSuccess',
	computed: {
		...mapState('general', [
			'quoteNotification',
			'projectData',
			'quoteSuccessAndErrorAsDialog',
		]),
		...mapState('theme', [
			'urlSuccess',
			'urlQualified',
			'urlNotQualified',
			'themeColors',
		]),
		...mapGetters('form', ['getIsChilean']),
		...mapState('form', [
			'countryCode',
			'fieldValues',
			'fetchedRentRanges',
			'rentToQualify',
		]),
		getSlang() {
			switch (this.countryCode) {
				case 'CL':
					return ChileData
				case 'PE':
					return PeruData
				case 'MX':
					return MexicoData
				default:
					return ChileData
			}
		},
	},
	created() {
		if (this.urlSuccess) {
			window.location.href = this.urlSuccess
		}
		if (
			this.urlQualified &&
			this.urlNotQualified &&
			this.rentToQualify &&
			this.fieldValues.rentRange
		) {
			console.log(
				`Rango: ${this.fieldValues.rentRange}, Mayor o igual a ${this.rentToQualify}:`,
				this.rentRangeMajorTo(this.fieldValues.rentRange, this.rentToQualify)
			)

			if (
				this.rentRangeMajorTo(this.fieldValues.rentRange, this.rentToQualify)
			) {
				window.location.href = this.urlQualified
			} else {
				window.location.href = this.urlNotQualified
			}
		}
	},
	methods: {
		...mapMutations('general', ['setFormSubmissionResponded']),
		...mapActions('assets', ['resetSecondaryAssetsToQuote']),
		redirectToSite(url) {
			window.open(url)
		},
		newQuote() {
			this.setFormSubmissionResponded(false)
			this.resetSecondaryAssetsToQuote()
			this.$emit('resetQuote')
		},
		rentRangeMajorTo(range, valueToCompare) {
			const baseValue =
				typeof valueToCompare === 'string'
					? parseInt(valueToCompare.replace(/\.|\$|_/g, ''))
					: valueToCompare

			const parts = range.split('-')

			const hasMore = range
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.includes('mas')

			if (hasMore) {
				return true
			}

			if (parts.length === 2) {
				const upperLimit = parseInt(
					parts[1].replace(/[.$]/g, '').replace('$', '')
				)
				return upperLimit >= baseValue
			}

			return false
		},
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
</style>
