const state = {
	allWildcard: 'TODOS',
	selectedBathroomsAmount: 'TODOS',
	selectedRoomsAmount: 'TODOS',
}

const mutations = {
	setSelectedRoomsAmount: (state, payload) =>
		(state.selectedRoomsAmount = payload),
	setSelectedBathroomsAmount: (state, payload) =>
		(state.selectedBathroomsAmount = payload),
}

const actions = {
	triangularSelection: (
		{ getters, commit, rootGetters, rootState, dispatch },
		{ property, value }
	) => {
		if (!['rooms', 'bathrooms', 'apartment', 'lote'].includes(property)) {
			console.log(
				'[MobySuite Quote] Not valid property for triangular selection'
			)
			return
		}

		// Commit order matters
		if (property == 'rooms') {
			commit('setSelectedRoomsAmount', value)
			commit(
				'setSelectedBathroomsAmount',
				getters.getValidBathroomsAmountByRoomsAmount
			)
			commit(
				'assets/setApartmentId',
				getters.getValidApartmentIdByRoomsAndBathroomsAmount,
				{
					root: true,
				}
			)
		} else if (property == 'bathrooms') {
			commit('setSelectedBathroomsAmount', value)
			commit(
				'setSelectedRoomsAmount',
				getters.getValidRoomsAmountByBathroomsAmount
			)
			commit(
				'assets/setApartmentId',
				getters.getValidApartmentIdByRoomsAndBathroomsAmount,
				{
					root: true,
				}
			)
		} else {
			commit('assets/setApartmentId', value, { root: true })
		}
		const apartmentInfo = rootGetters['assets/getApartmentInfo']
		//commit('assets/setAssetMasterType', apartmentInfo.assetType, { root: true })
		if (apartmentInfo.discount) {
			if (
				apartmentInfo.discount[rootState.assets.propertyNames.discountType] ==
				'GRUPO_BIENES'
			) {
				commit('assets/setOpportunityStatus', true, { root: true })
			} else {
				commit('assets/setOpportunityStatus', false, { root: true })
			}
		} else {
			commit('assets/setOpportunityStatus', false, { root: true })
		}
		if (apartmentInfo.associationStatus) {
			if (apartmentInfo.associationStatus == 'BIEN_DESVINCULADO') {
				commit('assets/setAssociatedStatus', 'BIEN_DESVINCULADO', {
					root: true,
				})
				commit(
					'assets/setAssociatedLimits',
					{ max: null, min: null },
					{ root: true }
				)
			}
			if (apartmentInfo.associationStatus == 'BIEN_ASOCIADO') {
				commit('assets/setAssociatedStatus', 'BIEN_ASOCIADO', {
					root: true,
				})
				const obj = {
					max: apartmentInfo.associationLimits.max,
					min: apartmentInfo.associationLimits.min,
				}
				commit('assets/setAssociatedLimits', obj, { root: true })
			}
		} else {
			commit('assets/setAssociatedStatus', null, { root: true })
			commit(
				'assets/setAssociatedLimits',
				{ max: null, min: null },
				{ root: true }
			)
		}

		dispatch('assets/resetSecondaryAssetsToQuote', null, { root: true })
	},
}

const getters = {
	getValidBathroomsAmountByRoomsAmount: (
		state,
		_getters,
		rootState,
		rootGetters
	) => {
		if (state.selectedBathroomsAmount == state.allWildcard)
			return state.selectedBathroomsAmount

		let assets = rootGetters['assets/getAssetByType'](
			rootState.assets.masterAssetType
		)

		if (state.selectedRoomsAmount != state.allWildcard)
			assets = assets.filter(
				asset =>
					asset[rootState.assets.propertyNames.roomsAmount] ==
					state.selectedRoomsAmount
			)

		assets = assets.map(
			asset => asset[rootState.assets.propertyNames.bathroomsAmount]
		)

		return assets.includes(state.selectedBathroomsAmount)
			? state.selectedBathroomsAmount
			: assets[0]
	},
	getValidRoomsAmountByBathroomsAmount: (
		state,
		_getters,
		rootState,
		rootGetters
	) => {
		if (state.selectedRoomsAmount == state.allWildcard)
			return state.selectedRoomsAmount

		let assets = rootGetters['assets/getAssetByType'](
			rootState.assets.masterAssetType
		)

		if (state.selectedBathroomsAmount != state.allWildcard)
			assets = assets.filter(
				asset =>
					asset[rootState.assets.propertyNames.bathroomsAmount] ==
					state.selectedBathroomsAmount
			)

		assets = assets.map(
			asset => asset[rootState.assets.propertyNames.roomsAmount]
		)

		return assets.includes(state.selectedRoomsAmount)
			? state.selectedRoomsAmount
			: assets[0]
	},
	getValidApartmentIdByRoomsAndBathroomsAmount: (
		state,
		_getters,
		rootState,
		rootGetters
	) => {
		let apartments = rootGetters['assets/getAssetByType'](
			rootState.assets.masterAssetType
		)

		if (state.selectedRoomsAmount != state.allWildcard)
			apartments = apartments.filter(
				asset =>
					asset[rootState.assets.propertyNames.roomsAmount] ==
					state.selectedRoomsAmount
			)

		if (state.selectedBathroomsAmount != state.allWildcard)
			apartments = apartments.filter(
				asset =>
					asset[rootState.assets.propertyNames.bathroomsAmount] ==
					state.selectedBathroomsAmount
			)

		apartments = apartments.map(
			asset => asset[rootState.assets.propertyNames.id]
		)

		return apartments.includes(rootState.assets.apartmentId)
			? rootState.assets.apartmentId
			: apartments[0]
	},
	getAllRoomsAmountsWithAllWildcard: (
		state,
		_getters,
		_rootState,
		rootGetters
	) => {
		const roomsAmounts = rootGetters['assets/getRoomsAmounts']

		return [state.allWildcard].concat([...new Set(roomsAmounts)].sort())
	},
	getAllBathroomsAmountsWithAllWildcard: (
		state,
		_getters,
		_rootState,
		rootGetters
	) => {
		const bathroomsAmounts = rootGetters['assets/getBathroomsAmounts']

		return [state.allWildcard].concat([...new Set(bathroomsAmounts)].sort())
	},
	getApartmentsBySelectedRoomsAndBathroomsAmounts: (
		state,
		getters,
		rootState,
		rootGetters
	) => {
		let apartments = rootGetters['assets/getAssetByType'](
			rootState.assets.masterAssetType
		)

		if (state.selectedRoomsAmount != state.allWildcard)
			apartments = apartments.filter(
				asset =>
					asset[rootState.assets.propertyNames.roomsAmount] ==
					state.selectedRoomsAmount
			)

		if (state.selectedBathroomsAmount != state.allWildcard)
			apartments = apartments.filter(
				asset =>
					asset[rootState.assets.propertyNames.bathroomsAmount] ==
					state.selectedBathroomsAmount
			)

		if (rootState.general.projectData.floorService)
			return getters.getApartmentsOrderByFloor(apartments)

		apartments = apartments.map(asset => {
			const discountData = rootGetters['assets/getOpportunityDiscount'](
				asset[rootState.assets.propertyNames.id]
			)
				? rootGetters['assets/getOpportunityDiscount'](
						asset[rootState.assets.propertyNames.id]
				  )
				: rootGetters['assets/getQuoteDiscountInfo'](
						asset[rootState.assets.propertyNames.id]
				  )
			return {
				value: asset[rootState.assets.propertyNames.id],
				text:
					rootState.general.template == 'Lotes'
						? `${asset[rootState.assets.propertyNames.assetNumber]}`
						: `${asset[rootState.assets.propertyNames.assetType]}-${
								asset[rootState.assets.propertyNames.type]
						  }-${asset[rootState.assets.propertyNames.assetNumber]} ${
								rootState.general.orientationOnList
									? asset[rootState.assets.propertyNames.orientation]
										? ' (' +
										  asset[rootState.assets.propertyNames.orientation] +
										  ') '
										: ''
									: ''
						  }`,
				discountInfo: discountData,
				authorizedDiscount:
					asset[rootState.assets.propertyNames.authorizedDiscount] &&
					asset[rootState.assets.propertyNames.authorizedDiscount] != 0
						? asset[rootState.assets.propertyNames.authorizedDiscount]
						: null,
				nonAuthorizedDiscount:
					asset[rootState.assets.propertyNames.nonAuthorizedDiscount] &&
					asset[rootState.assets.propertyNames.nonAuthorizedDiscount] != 0
						? asset[rootState.assets.propertyNames.nonAuthorizedDiscount]
						: null,
			}
		})

		return apartments.sort((a, b) =>
			a['text'].localeCompare(b['text'], 'en', { numeric: true })
		)
	},
	getApartmentsOrderByFloor: (
		_state,
		_getters,
		rootState,
		rootGetters
	) => apartmentsList => {
		const apartmentsAssetFloors = apartmentsList
			.map(apartment => apartment[rootState.assets.propertyNames.floor])
			.sort()

		const apartmentsAssetFloorsCleaned = [...new Set(apartmentsAssetFloors)]

		let finalApartmentsListWithFloor = []

		apartmentsAssetFloorsCleaned.forEach(floor => {
			let currentApartmentsListWithFloor = [
				{
					value: `Piso ${floor}`,
					text: `Piso ${floor}`,
					disabled: true,
				},
			]

			const apartmetsFilterByFloor = apartmentsList
				.filter(
					apartment => apartment[rootState.assets.propertyNames.floor] == floor
				)
				.map(apartment => {
					const discountData = rootGetters['assets/getOpportunityDiscount'](
						apartment[rootState.assets.propertyNames.id]
					)
						? rootGetters['assets/getOpportunityDiscount'](
								apartment[rootState.assets.propertyNames.id]
						  )
						: rootGetters['assets/getQuoteDiscountInfo'](
								apartment[rootState.assets.propertyNames.id]
						  )

					return {
						value: apartment[rootState.assets.propertyNames.id],
						text:
							rootState.general.template == 'Lotes'
								? `${apartment[rootState.assets.propertyNames.assetNumber]}`
								: `${apartment[rootState.assets.propertyNames.assetType]} ${
										apartment[rootState.assets.propertyNames.assetNumber]
								  }`,
						disabled: false,
						discountInfo: discountData,
						authorizedDiscount:
							apartment[rootState.assets.propertyNames.authorizedDiscount] &&
							apartment[rootState.assets.propertyNames.authorizedDiscount] != 0
								? apartment[rootState.assets.propertyNames.authorizedDiscount]
								: null,
						nonAuthorizedDiscount:
							apartment[rootState.assets.propertyNames.nonAuthorizedDiscount] &&
							apartment[rootState.assets.propertyNames.nonAuthorizedDiscount] !=
								0
								? apartment[
										rootState.assets.propertyNames.nonAuthorizedDiscount
								  ]
								: null,
					}
				})

			currentApartmentsListWithFloor = [
				...currentApartmentsListWithFloor,
				...apartmetsFilterByFloor,
			]

			finalApartmentsListWithFloor = [
				...finalApartmentsListWithFloor,
				...currentApartmentsListWithFloor,
			]
		})

		return finalApartmentsListWithFloor
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
