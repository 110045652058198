var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{style:(("width:100%; " + (_vm.backgroundColor ? ("background-color: " + _vm.backgroundColor + ";") : ''))),scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{class:("subtitle-1 " + (_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : '')),style:(("" + (_vm.headersColor ? ("color: " + _vm.headersColor + "; ") : '')))},[_vm._v(" Producto ")]),(!_vm.deleteItems)?_c('th',{class:("subtitle-1 " + (_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : '')),style:(("" + (_vm.headersColor ? ("color: " + _vm.headersColor + ";") : '')))},[_vm._v(" Tipo bien ")]):_vm._e(),_c('th',{class:("subtitle-1 " + (_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : '')),style:(("" + (_vm.headersColor ? ("color: " + _vm.headersColor + "; ") : '')))},[_vm._v(" Precio "),_c('small',[_vm._v("("+_vm._s(_vm.projectData.currencyCode)+")")])])])]),_c('tbody',[_vm._l((_vm.uniqueAssets.filter(
					function (product) { return product != null; }
				)),function(product,key){return _c('tr',{key:("" + key + (product.type))},[_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : ''))},[_c('div',{staticStyle:{"display":"flex !important","align-items":"center"}},[(_vm.deleteItems && product.masterAsset)?_c('font-awesome-icon',{staticClass:"mr-2",staticStyle:{"color":"#AE0808"},attrs:{"icon":['fal', 'trash-alt']},on:{"click":function($event){_vm.updateMasterAssetsSelected({
									action: 'remove',
									assetId: product.id,
								}),
									_vm.$emit('removeAsset', product.id)}}}):_vm._e(),_c('div',{style:(((_vm.textAlign ? '' : 'width: 100%; text-align:center;') + " " + (_vm.deleteItems && !product.masterAsset
										? 'margin-left: 20px;'
										: '')))},[_vm._v(" "+_vm._s(product.name[0].toUpperCase() + product.name.substring(1).toLowerCase())+" "),(product.discount && product.assetPriceWithDiscount)?_c('div',[_c('p',{style:(("font-style: italic; color:" + (_vm.colorName(
											product.discount.color
										))))},[_vm._v(" Precio con descuento ")])]):_vm._e()])],1)]),(!_vm.deleteItems)?_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : ''))},[_vm._v(" "+_vm._s(product.type)+" ")]):_vm._e(),_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : ''))},[_c('p',{style:(((product.assetPriceWithDiscount && product.discount
									? 'text-decoration: line-through red 1px;'
									: '') + " "))},[_vm._v(" "+_vm._s(_vm.formatNumber(product.price))+" ")]),(product.assetPriceWithDiscount && product.discount)?_c('div',[_c('p',{style:(("font-style: italic; color:" + (_vm.colorName(
									product.discount.color
								))))},[_vm._v(" "+_vm._s(_vm.formatNumber(product.assetPriceWithDiscount))+" ")])]):_vm._e()])])}),(_vm.showTotal)?_c('tr',{class:(" " + (_vm.withoutBorders ? 'total-focus' : '')),style:(((_vm.fromMainview
							? ("background-color:" + (_vm.themeColors.totalColor
										? _vm.themeColors.totalColor
										: _vm.themeColors.color3) + "; color: " + (_vm.themeColors.buttonTextColor) + ";")
							: ("background-color:" + (_vm.themeColors.infoAlertColor) + "; color:" + (_vm.themeColors.infoAlertTextColor) + ";")) + " width: 100%"))},[_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " font-weight-black")},[_c('b',[_vm._v("TOTAL")])]),(!_vm.deleteItems)?_c('td'):_vm._e(),_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " font-weight-black")},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.getFinalAssetsAmount))+" ")])]):_vm._e()],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }