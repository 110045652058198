import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const opts = {
	icons: {
		iconfont: 'faSvg',
	},
	theme: {
		disable: true,
	},
}

export default new Vuetify(opts)
