<template>
	<div>
		<slot
			:errors="errors"
			:customValidation="customValidation"
			:touch="$v.field.$touch"
			:value="field"
			:isInvalid="$v.field.$invalid"
		/>
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import {
	validate as isValidRUT,
	format as formatRUT,
	clean as cleanRUT,
} from 'rut.js'

export default {
	name: 'InputValidator',
	props: {
		useValidators: { type: Array, default: () => [] },
		fieldName: { type: String, default: '' },
	},
	data() {
		return {
			field: '',
			customRequired: { name: 'required', valid: false },
			customEmail: { name: 'email', valid: false },
			customRUT: { name: 'rut', valid: false },
			customDNI: { name: 'dni', valid: false },
			customPhone: { name: 'phone', valid: false },
			customRFC: { name: 'rfc', valid: false },
		}
	},
	validations() {
		return {
			field: {
				customRequired: function() {
					return this.customRequired.valid
				},
				customEmail: function() {
					return this.customEmail.valid
				},
				customRUT: function() {
					return this.customRUT.valid
				},
				customDNI: function() {
					return this.customDNI.valid
				},
				customRFC: function() {
					return this.customRFC.valid
				},
				customPhone: function() {
					return this.customPhone.valid
				},
			},
		}
	},
	computed: {
		...mapState('form', ['fieldValues']),
		errors() {
			const errors = []

			if (!this.$v.field.$dirty) return errors

			if (this.useValidators.includes(this.customRequired.name))
				!this.$v.field.customRequired && errors.push('Campo requerido')

			if (this.useValidators.includes(this.customEmail.name))
				!this.$v.field.customEmail && errors.push('Correo inválido')

			if (this.useValidators.includes(this.customRUT.name))
				!this.$v.field.customRUT && errors.push('RUT inválido')

			if (this.useValidators.includes(this.customDNI.name))
				!this.$v.field.customDNI && errors.push('DNI inválido')

			if (this.useValidators.includes(this.customRFC.name))
				!this.$v.field.customRFC && errors.push('RFC inválido')

			if (this.useValidators.includes(this.customPhone.name))
				!this.$v.field.customPhone && errors.push('Número inválido')

			return errors
		},
	},
	created() {
		if (!this.useValidators.length) {
			this.customRequired.valid = true
			this.customEmail.valid = true
			this.customRUT.valid = true
			this.customDNI.valid = true
			this.customRFC.valid = true
			this.customPhone.valid = true
		}

		// "fieldName" must be equal to the name given in the "fieldValues" state in
		// the "form" store.
		this.field = this.fieldValues[this.fieldName]
		if (this.field != null)
			this.customValidation(this.field, this.fieldValues.phoneObject)
	},
	methods: {
		...mapMutations('form', ['setFieldValue']),
		customValidateRUT(value) {
			if (!isValidRUT(value)) return false

			const RUTCleaned = cleanRUT(value)

			let RUTLength = RUTCleaned.length

			if (RUTCleaned.includes('K')) RUTLength -= 1

			return RUTLength >= 6 && RUTLength <= 9
		},
		customValidation(value, phoneObject = null) {
			this.field = value
			let mail = null

			if (this.useValidators.includes(this.customPhone.name))
				this.field = phoneObject.number.e164 ? phoneObject.number.e164 : ''

			if (this.useValidators.includes(this.customRUT.name))
				this.field = !value ? value : formatRUT(this.field)

			if (this.useValidators.includes(this.customEmail.name))
				mail = value.toLowerCase()

			this.setFieldValue({
				fieldName: this.fieldName,
				value: this.field,
				phoneObject,
			})

			this.customRequired.valid =
				!this.useValidators.includes(this.customRequired.name) ||
				required(this.field)

			this.customEmail.valid =
				!this.useValidators.includes(this.customEmail.name) || email(mail)

			this.customRUT.valid =
				!this.useValidators.includes(this.customRUT.name) ||
				this.customValidateRUT(this.field)

			this.customDNI.valid =
				!this.useValidators.includes(this.customDNI.name) ||
				this.field.length === 8

			this.customRFC.valid =
				!this.useValidators.includes(this.customRFC.name) ||
				(this.field.length >= 12 && this.field.length < 14)

			this.customPhone.valid =
				!this.useValidators.includes(this.customPhone.name) ||
				phoneObject.isValid
		},
	},
}
</script>
