<template>
	<div
		class="v-data-table"
		:style="
			`width:100%; color: var(--text-color); border: 0px; ${
				backgroundColor ? `background-color: ${backgroundColor};` : ''
			}`
		"
	>
		<div class="v-data-table__wrapper">
			<table
				style="border: 0px; font-weight: 400 !important; font-size: 14px !important;"
			>
				<thead>
					<tr class="table-title">
						<td
							scope="col"
							:class="
								`${textAlign ? 'text-start' : 'text-center'} ${
									withoutBorders ? 'without-borders' : ''
								} font-weight-black border-title`
							"
						>
							UNIDAD
						</td>
						<td
							scope="col"
							:class="
								`${textAlign ? 'text-start' : 'text-center'} ${
									withoutBorders ? 'without-borders' : ''
								} font-weight-black border-title`
							"
						>
							TIPO
						</td>
						<td
							scope="col"
							:class="
								`${textAlign ? 'text-start' : 'text-center'} ${
									withoutBorders ? 'without-borders' : ''
								} font-weight-black border-title`
							"
						>
							PRECIO ({{ currency }})
						</td>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(product, key) in uniqueAssets.filter(
							product => product != null
						)"
						:key="`${key}${product.type}`"
					>
						<td
							:class="
								`${textAlign ? 'text-start' : 'text-center'} ${
									withoutBorders ? 'without-borders' : ''
								}`
							"
						>
							<div style="display: flex !important; align-items: center;">
								<font-awesome-icon
									v-if="deleteItems && product.masterAsset"
									class="mr-2"
									:icon="['fal', 'trash-alt']"
									style="color: #AE0808;"
									@click="
										updateMasterAssetsSelected({
											action: 'remove',
											assetId: product.id,
										}),
											$emit('removeAsset', product.id)
									"
								/>
								<div
									:style="
										`${textAlign ? '' : 'width: 100%; text-align:center;'} ${
											deleteItems && !product.masterAsset
												? 'margin-left: 20px;'
												: ''
										}`
									"
								>
									{{
										product.name[0].toUpperCase() +
											product.name.substring(1).toLowerCase()
									}}
									<div
										v-if="product.discount && product.assetPriceWithDiscount"
									>
										<p
											:style="
												`font-size: 14px; font-style: italic; color:${colorName(
													product.discount.color
												)}`
											"
										>
											Precio con descuento
										</p>
									</div>
								</div>
							</div>
						</td>
						<td
							v-if="!deleteItems"
							:class="
								`${textAlign ? 'text-start' : 'text-center'} ${
									withoutBorders ? 'without-borders' : ''
								}`
							"
						>
							{{ product.type }}
						</td>
						<td
							:class="
								`${textAlign ? 'text-start' : 'text-center'} ${
									withoutBorders ? 'without-borders' : ''
								}`
							"
						>
							<p
								:style="
									`${
										product.assetPriceWithDiscount && product.discount
											? 'text-decoration: line-through red 1px; font-size: 14px !important;'
											: ''
									}; font-size: 14px !important;`
								"
							>
								{{
									currency == 'UF'
										? `${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
										  }).format(product.price)} UF`
										: `$ ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
										  }).format(Math.round(product.price * ufValue))}`
								}}
							</p>
							<div v-if="product.assetPriceWithDiscount && product.discount">
								<p
									:style="
										`font-style: italic; font-size: 14px !important; color:${colorName(
											product.discount.color
										)}`
									"
								>
									{{
										currency == 'UF'
											? `${new Intl.NumberFormat('es-CL', {
													maximumFractionDigits: 2,
											  }).format(product.assetPriceWithDiscount)} UF`
											: `$ ${new Intl.NumberFormat('es-CL', {
													maximumFractionDigits: 2,
											  }).format(
													Math.round(product.assetPriceWithDiscount * ufValue)
											  )}`
									}}
								</p>
							</div>
						</td>
					</tr>
					<tr
						v-if="showTotal"
						:class="` ${withoutBorders ? 'without-borders' : ''}`"
					>
						<td
							:class="
								`${textAlign ? 'text-start' : 'text-center'} font-weight-black`
							"
							style="border: 0;"
						>
							<b>TOTAL</b>
						</td>
						<td v-if="!deleteItems" style="border: 0;"></td>
						<td
							:class="
								`${textAlign ? 'text-start' : 'text-center'} font-weight-black`
							"
							style="border: 0;"
						>
							{{
								currency == 'UF'
									? `${new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
									  }).format(getFinalAssetsAmount)} UF`
									: `$ ${new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
									  }).format(Math.round(getFinalAssetsAmount * ufValue))}`
							}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
	name: 'TableSubAssets',
	props: {
		showTotal: {
			type: Boolean,
			default: true,
		},
		fromMainview: {
			type: Boolean,
			default: false,
		},
		backgroundColor: {
			type: String,
			default: null,
		},
		headersColor: {
			type: String,
			default: null,
		},
		withoutBorders: {
			type: Boolean,
			default: false,
		},
		deleteItems: {
			type: Boolean,
			default: false,
		},
		textAlign: {
			type: String,
			default: null,
		},
		currency: {
			type: String,
			default: 'UF',
		},
	},
	computed: {
		...mapGetters('assets', ['getAssetInfo', 'getFinalAssetsAmount']),
		...mapState('theme', ['themeColors']),
		...mapState('general', ['ufValue']),
		uniqueAssets() {
			const uniqueIds = new Set()
			return this.getAssetInfo.filter(item => {
				if (item)
					if (!uniqueIds.has(item.name)) {
						uniqueIds.add(item.name)
						return true
					}
				return false
			})
		},
	},
	methods: {
		colorName(color) {
			if (color == 'Verde') {
				return 'green'
			} else if (color == 'Rojo') {
				return 'red'
			} else {
				return 'blue'
			}
		},
		...mapActions('assets', ['updateMasterAssetsSelected']),
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
.without-borders {
	border: none !important;
}
.total-focus {
	background-color: #b7e5ee;
}
.border-title {
	border-bottom: 1px solid var(--text-color) !important;
}
</style>
