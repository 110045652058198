var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"absolute":true,"opacity":0.1,"align":"center","justify":"center","value":!Object.values(_vm.vitalDataLoaded).every(function (data) { return data; })}},[_c('v-img',{staticClass:"mb-5",attrs:{"contain":"","max-width":"300px","src":_vm.realEstateLogoUrl}}),_c('h1',{staticClass:"font-weight-thin black--text my-2"},[_vm._v(" Cargando ")]),_c('v-progress-circular',{staticClass:"my-3",attrs:{"color":"#555","indeterminate":"","size":"40"}}),_c('p',{staticClass:"subtitle-1 black--text"},[_vm._v(" Por favor, espere ")])],1),_c('v-overlay',{attrs:{"absolute":true,"align":"center","justify":"center","value":_vm.sendingQuote}},[_c('h1',{staticClass:"font-weight-thin  my-2"},[_vm._v("Procesando su solicitud")]),_c('v-progress-circular',{attrs:{"color":"#FFF","indeterminate":"","size":"40"}}),_c('h4',{staticClass:"subtitle-1 "},[_vm._v(" Por favor, espere ")])],1),(Object.values(_vm.vitalDataLoaded).every(function (data) { return data; }))?_c('div',[(_vm.atLeastOneVitalResponseFailed || !_vm.projectData.webQuoteService)?_c('div',[(_vm.noAssetsError)?_c('no-assets-error'):_vm._e(),(!_vm.noAssetsError)?_c('server-error'):_vm._e()],1):_c('div',[(
					_vm.formSubmissionResponded &&
						_vm.quoteSuccessAndErrorAsDialog &&
						!_vm.innerSuccess
				)?_c('div',[_c('v-dialog',{attrs:{"value":true,"width":_vm.quoteNotification.success ? '1000px' : '500px'}},[(_vm.quoteNotification.success && !_vm.captchaError)?_c('div',[(_vm.successViewType == 'simple')?_c('view-success-simple'):(_vm.successViewType == 'descriptive')?_c('view-success'):_vm._e()],1):_c('view-error')],1)],1):_vm._e(),(
					_vm.formSubmissionResponded &&
						!_vm.quoteSuccessAndErrorAsDialog &&
						!_vm.innerSuccess
				)?_c('div',[(
						_vm.quoteNotification.success &&
							_vm.successViewType == 'simple' &&
							!_vm.captchaError
					)?_c('view-success-simple'):(
						_vm.quoteNotification.success &&
							_vm.successViewType == 'descriptive' &&
							!_vm.captchaError
					)?_c('view-success'):_c('view-error')],1):_vm._e(),(
					!_vm.formSubmissionResponded ||
						(_vm.formSubmissionResponded && _vm.quoteSuccessAndErrorAsDialog) ||
						_vm.innerSuccess
				)?_c('div',[(_vm.templateName == _vm.availableTemplateNames.carrusel)?_c('carrusel'):(_vm.templateName == _vm.availableTemplateNames.filtro)?_c('filtro'):(_vm.templateName == _vm.availableTemplateNames.default)?_c('default'):(_vm.templateName == _vm.availableTemplateNames.simple)?_c('simple'):(_vm.templateName == _vm.availableTemplateNames.prueba)?_c('prueba'):(_vm.templateName == _vm.availableTemplateNames.filtroPorModelo)?_c('filtro-por-modelo'):(_vm.templateName == _vm.availableTemplateNames.lotes)?_c('lotes'):(_vm.templateName == _vm.availableTemplateNames.lotesPorModelo)?_c('lotes-por-modelo'):(_vm.templateName == _vm.availableTemplateNames.secundarios)?_c('secundarios'):(_vm.templateName == _vm.availableTemplateNames.plano)?_c('plano'):(_vm.templateName == _vm.availableTemplateNames.simpleLeft)?_c('simple-left',{attrs:{"filter-typology":_vm.filterTypology}}):(_vm.templateName == _vm.availableTemplateNames.reserveInvestors)?_c('reserve-investors'):(_vm.templateName == _vm.availableTemplateNames.categorySelector)?_c('filtro-por-sub-tipo'):(_vm.templateName == _vm.availableTemplateNames.squaredTemplate)?_c('cuadrado',{attrs:{"filter-typology":_vm.filterTypology}}):_c('default')],1):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }