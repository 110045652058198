<template>
	<v-container
		:key="selectedApartmentId"
		fluid
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2};`
		"
	>
		<banner
			:title="projectData.projectName"
			:subtitle="projectData.address"
			:icon="['fas', 'map-marker-alt']"
			:background-color="themeColors.color1"
			:text-color="themeColors.color2"
		/>
		<v-row no-gutters class="mt-2" align="center">
			<v-col align="center" justify="center" cols="12" sm="12" md="7">
				<v-row no-gutters align="center" justify="center" class="mr-2">
					<v-col>
						<image-dialog
							:thumb="`${getApartmentInfo.image}`"
							:full="`${getApartmentInfo.image}`"
							:mobile="
								this.$vuetify.breakpoint.name == 'xs' ||
								this.$vuetify.breakpoint.name == 'sm'
									? true
									: false
							"
							:style="
								`position:relative; max-height: 400, max-width: ${imgWidth}; margin-bottom: 8px;`
							"
						></image-dialog>
					</v-col>
				</v-row>
				<v-row :class="spaceBetweenComponents" align="center" justify="center">
					<p class="mb-5">{{ assetSurfaceTitle }}</p>
					<div
						style=" width: 80%; display: flex; justify-content: space-between; text-align: center"
					>
						<div>
							{{ assetSurfaceSubtitle }} ÚTIL <br />
							<p class="font-weight-black">
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.util)
								}}
								m<sup>2</sup>
							</p>
						</div>
						<div
							:style="
								`border-style: solid; border-color: ${themeColors.color2}; border-width: 1px;`
							"
						></div>
						<div
							v-if="
								getApartmentInfo.terrain != null &&
									getApartmentInfo.terrain != '0' &&
									getApartmentInfo.assetType == 'CASA'
							"
						>
							TERRENO <br />
							<p class="font-weight-black">
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.terrain)
								}}
								m<sup>2</sup>
							</p>
						</div>
						<div
							v-else-if="
								getApartmentInfo.terrace != null &&
									getApartmentInfo.terrace != '0'
							"
						>
							TERRAZA <br />
							<p class="font-weight-black">
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.terrace)
								}}
								m<sup>2</sup>
							</p>
						</div>
						<div
							:style="
								`border-style: solid; border-color: ${themeColors.color2}; border-width: 1px;`
							"
						></div>
						<div>
							{{ assetSurfaceSubtitle }} TOTAL <br />
							<p class="font-weight-black">
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.surface)
								}}
								m<sup>2</sup>
							</p>
						</div>
					</div>
				</v-row>
			</v-col>
			<v-col justify="center" cols="12" sm="12" md="5">
				<v-container>
					<v-row class="d-block">
						<v-row no-gutters>
							<v-col cols="12" sm="12" md="6">
								<p class="mr-1">
									{{
										masterAssetType.includes('CASA')
											? 'Nº DORMITORIOS Y BAÑOS'
											: masterAssetType.includes('DEPARTAMENTO') &&
											  masterAssetType.includes('OFICINA')
											? 'Nº DORM/PRIVADOS Y BAÑOS'
											: masterAssetType.includes('DEPARTAMENTO')
											? 'Nº DORMITORIOS Y BAÑOS'
											: masterAssetType.includes('OFICINA')
											? 'Nº PRIVADOS Y BAÑOS'
											: 'BAÑOS'
									}}
								</p>
								<v-select
									v-model="selectedApartmentsRoomsBathrooms"
									no-data-text="No hay datos disponibles"
									class="mr-1 mb-1"
									:class="
										$vuetify.breakpoint.name == ('md' || 'l' || 'xl')
											? 'mb-1'
											: 'mr-1 mb-1'
									"
									outlined
									dense
									hide-details
									:disabled="apartmentsRoomsBathroomsDisabled"
									:items="apartmentsRoomsBathrooms"
									:item-text="
										item =>
											masterAssetType.includes('CASA')
												? `${item.roomsAmount} DORM ${item.bathroomsAmount} BAÑO(S)`
												: masterAssetType.includes('DEPARTAMENTO') &&
												  masterAssetType.includes('OFICINA')
												? `${item.roomsAmount} DORM/PRIV ${item.bathroomsAmount} BAÑO(S)`
												: masterAssetType.includes('DEPARTAMENTO')
												? `${item.roomsAmount} DORM ${item.bathroomsAmount} BAÑO(S)`
												: masterAssetType.includes('OFICINA')
												? `${item.roomsAmount} PRIV ${item.bathroomsAmount} BAÑO(S)`
												: `DORM ${item.bathroomsAmount} BAÑO(S)`
									"
									:return-object="true"
									placeholder="Seleccionar"
									:background-color="themeColors.selectColor"
									:color="themeColors.selectTextColor"
									:style="textColor"
									:attach="true"
									@change="updateModelSelect"
								>
								</v-select>
							</v-col>
							<v-col cols="12" sm="12" md="6">
								<p class="ml-1">MODELO</p>
								<v-select
									v-model="selectedModel"
									no-data-text="No hay datos disponibles"
									class="ml-1 mb-1"
									:class="
										$vuetify.breakpoint.name == 'xs' ||
										$vuetify.breakpoint.name == 'sm'
											? 'mb-1'
											: 'ml-1 mb-1'
									"
									outlined
									dense
									hide-details
									:disabled="modelsDisabled"
									:items="models"
									placeholder="Seleccionar"
									:background-color="themeColors.selectColor"
									:color="themeColors.selectTextColor"
									:style="`color:${themeColors.selectTextColor}`"
									:attach="true"
									@change="updateApartmentsSelect"
								>
								</v-select>
							</v-col>
						</v-row>
						<p>BIENES DISPONIBLES</p>
						<v-select
							v-model="selectedApartmentId"
							no-data-text="No hay datos disponibles"
							class="mb-1"
							:class="
								$vuetify.breakpoint.name == 'xs' ||
								$vuetify.breakpoint.name == 'sm'
									? 'mb-1'
									: 'mr-1 mb-1'
							"
							outlined
							dense
							hide-details
							:disabled="apartmentsDisabled"
							:items="apartments"
							:item-value="item => item[propertyNames.id]"
							placeholder="Seleccionar"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							:style="`color:${themeColors.selectTextColor}`"
							:attach="true"
							@change="updateAssetData"
						>
							<template v-slot:selection="{ item }">
								<span
									style="
											margin-right: 10px;
										"
									v-text="
										`${item[propertyNames.assetType]} ${
											item[propertyNames.assetNumber]
										}`
									"
								>
								</span>
								<span v-if="item.discountData">
									<span
										v-if="
											item.discountData[propertyNames.discountType] ==
												'GRUPO_BIENES'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="` Reserva hoy con `"
									>
									</span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'PORCENTAJE'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] == 'CONSTANTE'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount]
											)} UF`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] == 'MONTO'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento $${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount]
											)}`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
												item[propertyNames.nonAuthorizedDiscount] != 0 &&
												item[propertyNames.nonAuthorizedDiscount]
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item[propertyNames.nonAuthorizedDiscount] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'MONTO_DESCUENTO_BIEN' &&
												item[propertyNames.authorizedDiscount] != 0 &&
												item[propertyNames.authorizedDiscount]
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(item[propertyNames.authorizedDiscount] * 100)}%`
										"
									></span>
								</span>
							</template>
							<template v-slot:item="{ item }">
								<span
									style="
											margin-right: 10px;
										"
									v-text="
										`${item[propertyNames.assetType]} ${
											item[propertyNames.assetNumber]
										}`
									"
								>
								</span>
								<span v-if="item.discountData">
									<span
										v-if="
											item.discountData[propertyNames.discountType] ==
												'GRUPO_BIENES'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="` Reserva hoy con `"
									>
									</span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'PORCENTAJE'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] == 'CONSTANTE'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount]
											)} UF`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] == 'MONTO'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento $${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount]
											)}`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
												item[propertyNames.nonAuthorizedDiscount] != 0 &&
												item[propertyNames.nonAuthorizedDiscount]
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item[propertyNames.nonAuthorizedDiscount] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'MONTO_DESCUENTO_BIEN' &&
												item[propertyNames.authorizedDiscount] != 0 &&
												item[propertyNames.authorizedDiscount]
										"
										:style="
											`margin-left:3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(item[propertyNames.authorizedDiscount] * 100)}%`
										"
									></span>
								</span>
							</template>
						</v-select>
						<v-row
							v-if="projectData.warehouseService || projectData.parkingService"
							class="mt-2"
							no-gutters
						>
							<p
								v-if="getApartmentInfo.associationStatus != 'BIEN_DESVINCULADO'"
								class="text-body-1"
							>
								<span
									v-if="
										projectData.warehouseService && projectData.parkingService
									"
									class="mr-2"
									>Seleccionar Estacionamiento y/o Bodega</span
								>
								<span
									v-else-if="
										!projectData.warehouseService && projectData.parkingService
									"
									class="mr-2"
									>Seleccionar Estacionamiento</span
								>
								<span v-else class="mr-2">Seleccionar Bodega</span>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<v-avatar class="mr-2" color="#A1A4A1" size="20">
											<span
												v-bind="attrs"
												class="white--text"
												style="font-size: 15px;"
												v-on="on"
												>?</span
											>
										</v-avatar>
									</template>
									<span
										>Los bienes que son agregados automáticamente son bienes
										conjuntos, no se pueden vender por separado</span
									>
								</v-tooltip>
							</p>
							<select-sub-assets ref="subAssets" class="mt-1" />
						</v-row>
					</v-row>
					<v-row class="mt-6">
						<quote-form ref="formRef" />
					</v-row>
					<v-row>
						<v-col>
							<p
								v-if="quoteLimit != null && quoteLimit <= 0"
								style="color: red"
							>
								Ha excedido el número de cotizaciones permitidas por día.
							</p>
							<v-btn
								id="moby-quote-button"
								min-height="50px"
								:color="themeColors.color3"
								dark
								class="my-2"
								block
								x-large
								:style="
									`color: ${
										quoteLimit != null && quoteLimit <= 0
											? '#000'
											: themeColors.buttonTextColor
									}; font-size: 18px`
								"
								:disabled="quoteLimit != null && quoteLimit <= 0 ? true : false"
								@click="$refs.formRef.submitForm()"
							>
								{{ textButton }}
							</v-btn>
						</v-col>
						<v-col
							v-if="getOpportunityStatus && !reservation"
							cols="12"
							xs="12"
							sm="6"
						>
							<v-btn
								id="moby-reservation-button"
								:color="themeColors.color3"
								x-large
								block
								class="mt-2 ml-sm-2"
								:style="
									`color: ${themeColors.buttonTextColor}; font-size: 16px`
								"
								@click="$refs.formRef.submitForm(true)"
							>
								Reservar
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// Model == Type ('tipo')
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import Banner from '@/components/Banner.vue'
import SelectSubAssets from '@/components/SelectSubAssets.vue'
import ImageDialog from '@/components/ImageDialog.vue'

export default {
	name: 'FiltroPorModelo',
	components: {
		QuoteForm,
		Banner,
		SelectSubAssets,
		ImageDialog,
	},
	data() {
		return {
			apartmentsRoomsBathrooms: [],
			selectedApartmentsRoomsBathrooms: null,
			apartmentsRoomsBathroomsDisabled: false,
			models: [],
			selectedModel: null,
			modelsDisabled: false,
			apartments: [],
			selectedApartmentId: null,
			apartmentsDisabled: false,
		}
	},
	computed: {
		...mapGetters('filterByModel', [
			'getApartmentsRoomsBathrooms',
			'getApartmentsTypologiesByRoomsAmountsAndBathroomsAmounts',
			'getApartmentsByTypologyOnly',
		]),
		...mapGetters('assets', [
			'getApartmentInfo',
			'getApartmentById',
			'getOpportunityStatus',
			'getOpportunityDiscount',
			'getQuoteDiscountInfo',
		]),
		...mapState('assets', ['propertyNames', 'masterAssetType']),
		...mapState('theme', ['themeColors']),
		...mapState('general', [
			'projectData',
			'assetIdParam',
			'typology',
			'roomsAmount',
			'bathroomsAmount',
			'quoteLimit',
			'reservation',
			'floorFilter',
		]),
		imgWidth() {
			return this.$vuetify.breakpoint.name == 'xs' ? '90%' : 580
		},
		assetSurfaceTitle: function() {
			return this.$vuetify.breakpoint.name == 'xs' ? 'SUPERFICIE' : ''
		},
		assetSurfaceSubtitle: function() {
			return this.$vuetify.breakpoint.name == 'xs' ? '' : 'SUPERFICIE'
		},
		spaceBetweenComponents: function() {
			return this.$vuetify.breakpoint.name == 'xl' ||
				this.$vuetify.breakpoint.name == 'lg'
				? 'mt-10'
				: 'mt-10 mb-3'
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
		textColor() {
			return {
				'--text-color': this.themeColors.selectTextColor,
			}
		},
	},
	watch: {
		typology: {
			handler: function() {
				if (this.typology != null) this.setInitDataByTypology()
			},
			immediate: true,
		},
		floorFilter: {
			handler: function() {
				if (this.floorFilter != null) this.setInitData()
			},
			immediate: true,
		},
	},
	created() {
		console.log('assetIdParam: ', this.assetIdParam)
		console.log('typology: ', this.typology)
		console.log('roomsAmount: ', this.roomsAmount)
		if (this.assetIdParam != null) {
			this.setInitDataByAssetId()
			return
		}

		if (this.typology != null) {
			this.setInitDataByTypology()
			return
		}

		if (this.roomsAmount != null && this.bathroomsAmount != null) {
			this.setInitDataByRoomsAndBathroomsAmount()
			return
		}

		this.setInitData()
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
	},
	methods: {
		...mapMutations('assets', [
			'setApartmentId',
			'setAssetMasterType',
			'setOpportunityStatus',
			'setAssociatedStatus',
			'setAssociatedLimits',
		]),
		...mapActions('assets', ['resetSecondaryAssetsToQuote']),
		updateModelSelect(roomsAndBathroomsAmounts) {
			this.models = this.getApartmentsTypologiesByRoomsAmountsAndBathroomsAmounts(
				roomsAndBathroomsAmounts
			)

			if (this.models.length && !this.typology)
				this.selectedModel = this.models[0]

			this.updateApartmentsSelect()
		},
		updateApartmentsSelect() {
			this.apartments = this.getApartmentsByTypologyOnly(
				this.selectedModel.split(','),
				this.selectedApartmentsRoomsBathrooms
			)
			this.apartments = this.apartments.map(asset => {
				const discountData = this.getOpportunityDiscount(
					asset[this.propertyNames.id]
				)
					? this.getOpportunityDiscount(asset[this.propertyNames.id])
					: this.getQuoteDiscountInfo(asset[this.propertyNames.id])
				return { ...asset, ...{ discountData: discountData } }
			})
			console.log('apartments', this.apartments)
			if (this.apartments.length) {
				this.selectedApartmentId = this.apartments[0][this.propertyNames.id]
				this.setApartmentId(this.selectedApartmentId)
				console.log('selected', this.selectedApartmentId)
				//this.setAssetMasterType(this.getApartmentInfo.assetType)
				if (this.getApartmentInfo.discount) {
					if (
						this.getApartmentInfo.discount[this.propertyNames.discountType] ==
						'GRUPO_BIENES'
					) {
						this.setOpportunityStatus(true)
					} else {
						this.setOpportunityStatus(false)
					}
				} else {
					this.setOpportunityStatus(false)
				}
				if (this.getApartmentInfo.associationStatus) {
					if (
						this.getApartmentInfo.discount[this.propertyNames.discountType] ==
						'BIEN_DESVINCULADO'
					)
						this.setAssociatedStatus('BIEN_DESVINCULADO')
					if (
						this.getApartmentInfo.discount[this.propertyNames.discountType] ==
						'BIEN_ASOCIADO'
					) {
						this.setAssociatedStatus('BIEN_ASOCIADO')
						const obj = {
							max: this.getApartmentInfo.associationLimits.max,
							min: this.getApartmentInfo.associationLimits.min,
						}
						this.setAssociatedLimits(obj)
					}
				} else {
					this.setAssociatedStatus(null)
				}
				if (this.floorFilter != null)
					this.apartments = this.apartments.filter(apartment =>
						this.floorFilter.includes(
							String(apartment[this.propertyNames.floor])
						)
					)
			}
		},
		setInitDataByAssetId() {
			this.apartmentsRoomsBathrooms = this.getApartmentsRoomsBathrooms()

			this.updateModelSelect('TODOS')

			const asset = this.getApartmentById(this.assetIdParam)

			const type = asset[this.propertyNames.type]
			const roomsAmount = asset[this.propertyNames.roomsAmount]
			const bathroomsAmount = asset[this.propertyNames.bathroomsAmount]

			this.setApartmentId = this.assetIdParam
			this.setAssetMasterType(this.getApartmentInfo.assetType)
			if (this.getApartmentInfo.discount) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'GRUPO_BIENES'
				) {
					this.setOpportunityStatus(true)
				} else {
					this.setOpportunityStatus(false)
				}
			} else {
				this.setOpportunityStatus(false)
			}
			if (this.getApartmentInfo.associationStatus) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_DESVINCULADO'
				)
					this.setAssociatedStatus('BIEN_DESVINCULADO')
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_ASOCIADO'
				) {
					this.setAssociatedStatus('BIEN_ASOCIADO')
					const obj = {
						max: this.getApartmentInfo.associationLimits.max,
						min: this.getApartmentInfo.associationLimits.min,
					}
					this.setAssociatedLimits(obj)
				}
			} else {
				this.setAssociatedStatus(null)
			}

			this.selectedApartmentId = this.assetIdParam
			this.selectedApartmentsRoomsBathrooms = { bathroomsAmount, roomsAmount }
			this.selectedModel = type

			this.apartmentsRoomsBathroomsDisabled = true
			this.modelsDisabled = true
			this.apartmentsDisabled = true
		},
		setInitDataByTypology() {
			this.apartmentsRoomsBathrooms = this.getApartmentsRoomsBathrooms(
				this.typology
			)
			if (this.apartmentsRoomsBathrooms.length)
				this.selectedApartmentsRoomsBathrooms = this.apartmentsRoomsBathrooms[0]

			this.selectedModel = String(this.typology)
			this.updateModelSelect('TODOS')

			this.models.push(String(this.typology))
			this.modelsDisabled = true
		},
		setInitDataByRoomsAndBathroomsAmount() {
			this.apartmentsRoomsBathrooms = this.getApartmentsRoomsBathrooms()

			this.selectedApartmentsRoomsBathrooms = {
				bathroomsAmount: this.bathroomsAmount,
				roomsAmount: this.roomsAmount,
			}

			this.updateModelSelect(this.selectedApartmentsRoomsBathrooms)

			this.apartmentsRoomsBathroomsDisabled = true
		},
		setInitData() {
			this.apartmentsRoomsBathrooms = this.getApartmentsRoomsBathrooms()

			if (this.apartmentsRoomsBathrooms.length)
				this.selectedApartmentsRoomsBathrooms = this.apartmentsRoomsBathrooms[0]

			this.updateModelSelect('TODOS')
		},
		colorName(color) {
			if (color == 'Verde') {
				return 'green'
			} else if (color == 'Rojo') {
				return 'red'
			} else {
				return 'blue'
			}
		},
		updateAssetData(item) {
			this.setApartmentId(item)
			this.setAssetMasterType(this.getApartmentInfo.assetType)
			if (
				this.getApartmentInfo.associationStatus == 'BIEN_DESVINCULADO' ||
				this.getApartmentInfo.associationStatus == 'BIEN_ASOCIADO'
			) {
				this.resetSecondaryAssetsToQuote()
				this.$refs.subAssets.resetInitialData()
			}
			if (this.getApartmentInfo.discount) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'GRUPO_BIENES'
				) {
					this.setOpportunityStatus(true)
				} else {
					this.setOpportunityStatus(false)
				}
			} else {
				this.setOpportunityStatus(false)
			}
			if (this.getApartmentInfo.associationStatus) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_DESVINCULADO'
				)
					this.setAssociatedStatus('BIEN_DESVINCULADO')
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_ASOCIADO'
				) {
					this.setAssociatedStatus('BIEN_ASOCIADO')
					const obj = {
						max: this.getApartmentInfo.associationLimits.max,
						min: this.getApartmentInfo.associationLimits.min,
					}
					this.setAssociatedLimits(obj)
				}
			} else {
				this.setAssociatedStatus(null)
			}
		},
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
</style>
<style>
.v-input
	> .v-input__control
	> .v-input__slot
	> .v-select__slot
	> .v-select__selections
	> .v-select__selection,
.v-select__selection--comma {
	color: var(--text-color) !important;
}
</style>
