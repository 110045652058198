<template>
	<div>
		<v-card
			align="center"
			justify="center"
			:style="quoteSuccessAndErrorAsDialog ? '' : 'min-height:100vh'"
			:class="quoteSuccessAndErrorAsDialog ? '' : 'd-flex'"
			min-height="200px"
		>
			<v-card-title
				v-if="quoteSuccessAndErrorAsDialog"
				class="red darken-4 white--text"
			>
				<v-avatar size="24" class="mr-4">
					<font-awesome-icon :icon="['fas', 'exclamation-circle']" />
				</v-avatar>
				{{ quoteNotification.title }}
			</v-card-title>
			<v-card-text class="align-self-center mt-4 text-center">
				<v-row class="d-block  mt-4" align="center" justify="center">
					<h2 class="font-weight-thin" style="margin-bottom: 5px">
						{{ quoteNotification.body }}
					</h2>
					<h3 class="font-weight-thin">
						Por favor, intentelo de nuevo más tarde
					</h3>
					<v-btn
						color="red darken-4 white--text"
						class="mt-6"
						dark
						@click="setFormSubmissionResponded(false)"
					>
						Regresar
					</v-btn>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
	name: 'ViewError',
	computed: {
		...mapState('general', [
			'quoteNotification',
			'quoteSuccessAndErrorAsDialog',
		]),
	},
	methods: {
		...mapMutations('general', ['setFormSubmissionResponded']),
	},
}
</script>
