const getters = {
	getApartmentsByRoomsAmount: (
		_state,
		getters,
		rootState,
		rootGetters
	) => roomsAmount => {
		let finalApartments = []

		rootGetters['assets/getAssetByType'](rootState.assets.masterAssetType)
			.filter(
				asset =>
					asset[rootState.assets.propertyNames.roomsAmount] == roomsAmount
			)
			.forEach(apartment => {
				const apartmentFound = finalApartments.find(finalApartment =>
					getters.getApartmentsHaveSameProperties(apartment, finalApartment)
				)

				if (!apartmentFound) {
					finalApartments = [...finalApartments, apartment]
					return
				}

				finalApartments = finalApartments.map(finalApartment => {
					if (
						!getters.getApartmentsHaveSameProperties(
							apartmentFound,
							finalApartment
						)
					)
						return finalApartment

					return finalApartment[rootState.assets.propertyNames.price] <
						apartmentFound[rootState.assets.propertyNames.price]
						? finalApartment
						: apartmentFound
				})
			})

		finalApartments = finalApartments
			.map(apartment => {
				const discountData = rootGetters['assets/getOpportunityDiscount'](
					apartment[rootState.assets.propertyNames.id]
				)
					? rootGetters['assets/getOpportunityDiscount'](
							apartment[rootState.assets.propertyNames.id]
					  )
					: rootGetters['assets/getQuoteDiscountInfo'](
							apartment[rootState.assets.propertyNames.id]
					  )
				return {
					imageURL: apartment[rootState.assets.propertyNames.imageURLOne]
						? `${apartment[rootState.assets.propertyNames.imageURLOne]}`
						: 'https://748073e22e8db794416a-cc51ef6b37841580002827d4d94d19b6.ssl.cf3.rackcdn.com/not-found.png',
					id: apartment[rootState.assets.propertyNames.id],
					type: apartment[rootState.assets.propertyNames.type],
					totalArea: apartment[rootState.assets.propertyNames.totalArea],
					roomsAmount: roomsAmount,
					bathroomsAmount:
						apartment[rootState.assets.propertyNames.bathroomsAmount],
					discountInfo: discountData,
					authorizedDiscount:
						apartment[rootState.assets.propertyNames.authorizedDiscount] &&
						apartment[rootState.assets.propertyNames.authorizedDiscount] != 0
							? apartment[rootState.assets.propertyNames.authorizedDiscount]
							: null,
					nonAuthorizedDiscount:
						apartment[rootState.assets.propertyNames.nonAuthorizedDiscount] &&
						apartment[rootState.assets.propertyNames.nonAuthorizedDiscount] != 0
							? apartment[rootState.assets.propertyNames.nonAuthorizedDiscount]
							: null,
				}
			})
			.sort((aApartment, bApartment) => aApartment.price - bApartment.price)

		return finalApartments
	},
	getCheaperApartmentByRoomsAmount: (_state, getters) => roomsAmount =>
		getters.getApartmentsByRoomsAmount(roomsAmount)[0],
	getApartmentsHaveSameProperties: (_state, _getters, rootState) => (
		firstApartment,
		secondApartment
	) => {
		return (
			firstApartment[rootState.assets.propertyNames.bathroomsAmount] ==
				secondApartment[rootState.assets.propertyNames.bathroomsAmount] &&
			firstApartment[rootState.assets.propertyNames.type] ==
				secondApartment[rootState.assets.propertyNames.type]
		)
	},
}

export default {
	namespaced: true,
	getters,
}
