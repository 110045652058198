import Vue from 'vue'
import Vuex from 'vuex'
import assets from '@/store/modules/assets'
import general from '@/store/modules/general'
import form from '@/store/modules/form'
import theme from '@/store/modules/theme'
import fai from '@/store/modules/templates/fai'
import neourbe from '@/store/modules/templates/neourbe'
import filterByModel from '@/store/modules/templates/filterByModel'
import filterByModelLote from '@/store/modules/templates/filterByModelLote'
import maps from '@/store/modules/maps'
import reserveInvestors from '@/store/modules/templates/reserveInvestors'
import VuexReset from '@ianwalter/vuex-reset'

Vue.use(Vuex)

const modules = {
	assets,
	general,
	form,
	theme,
	fai,
	neourbe,
	filterByModel,
	filterByModelLote,
	maps,
	reserveInvestors,
}

export default new Vuex.Store({
	modules,
	plugins: [VuexReset()],
	mutations: {
		reset: () => {},
	},
})
