import Vue from 'vue'

const state = {
	propertyNames: {
		assetNumber: 'numeroDeBien',
		assetType: 'tipoBien',
		bathroomsAmount: 'numeroDeBanos',
		floor: 'piso',
		id: 'id',
		imageURLOne: 'urlImagenUno',
		imageURLTwo: 'urlImagenDos',
		imageURLThree: 'urlImagenTres',
		roomsAmount: 'numeroDeDormitorios',
		subAsset: 'bConjunto',
		totalArea: 'superficieTotal',
		terraceArea: 'superficieTerraza',
		utilSurface: 'superficieUtil',
		innerArea: 'superficieInterior',
		terrainArea: 'superficieTerreno',
		orientation: 'orientacion',
		price: 'valorLista',
		IVAprice: 'valorListaIVA',
		type: 'tipo',
		masterAsset: 'bMaestro',
		masterAssetTypes: [
			'CASA',
			'DEPARTAMENTO',
			'LOCAL',
			'OFICINA',
			'LOTE',
			'ACCION',
			'SITIO',
		],
		assetGroup: 'grupoBienes',
		discountGroup: 'descuentoGrupo',
		associationType: 'tipoAsociacion',
		discountType: 'tipoDescuento',
		discountAmount: 'montoDescuento',
		amountType: 'tipoMonto',
		associatedSubAssets: 'bAsociacion',
		discountAssetsValidation: 'validarBienesOportunidad',
		authorizedDiscount: 'descuentoAutorizadoBien',
		nonAuthorizedDiscount: 'descuentoNoAutorizadoBien',
		primaryAssetDiscount: 'bienMaestroDescuento',
		opportunityDiscountValue: 'descuentoOportunidad',
		discountValue: 'calculoDscto',
		assetSubtype: 'assetSubtype',
		associatedLimits: {
			max: 'maximo',
			min: 'minimo',
		},
		gardenSurface: 'superficieJardin',
	},
	typeNames: {
		apartment: 'DEPARTAMENTO',
		parkingLot: 'ESTACIONAMIENTO',
		warehouse: 'BODEGA',
		lote: 'LOTE',
	},
	masterAssetType: null,
	apartmentId: null,
	discountId: null,
	fetchedAssets: [],
	secondaryAssetsToQuote: [],
	secondaryAssets: null,
	showSubAssetHasMasterAsset: false,
	useSecondaryImage: false,
	useThirdImage: false,
	selectedParkingLotsAmount: 0,
	selectedWarehousesAmount: 0,
	isOpportunity: false,
	associatedStatus: null,
	associatedLimits: {
		min: null,
		max: null,
	},
	selectedMasterAssets: [],
	permitMultipleMasterAssetSelection: false,
}

const mutations = {
	setSecondaryAssets: (state, payload) => (state.secondaryAssets = payload),
	setAssets: (state, payload) => (state.fetchedAssets = payload),
	setApartmentId: (state, payload) => (state.apartmentId = payload),
	setAssetMasterType: (state, payload) => (state.masterAssetType = payload),
	setDiscountId: (state, payload) => (state.discountId = payload),
	addSecondaryAssetToQuote: (state, id) => {
		state.secondaryAssetsToQuote = [...state.secondaryAssetsToQuote, { id }]
	},
	removeSecondaryAssetToQuote: (state, { id, assetTypeName }) => {
		state.secondaryAssetsToQuote = state.secondaryAssetsToQuote.filter(
			asset => asset.id != id
		)
		if (assetTypeName == 'warehouse') state.selectedWarehousesAmount -= 1
		else if (assetTypeName == 'parkingLot') state.selectedParkingLotsAmount -= 1
	},
	setShowSubAssetHasMasterAsset: (state, value) => {
		state.showSubAssetHasMasterAsset = value
	},
	setImagePreference: (state, payload) => {
		state[payload.field] = payload.value
	},
	setIncreaseSecondaryAssetsAmount: (state, assetTypeName) => {
		if (assetTypeName == 'warehouse') state.selectedWarehousesAmount += 1
		else if (assetTypeName == 'parkingLot') state.selectedParkingLotsAmount += 1
	},
	setOpportunityStatus: (state, payload) => (state.isOpportunity = payload),
	setAssociatedStatus: (state, payload) => {
		state.associatedStatus = payload
	},
	setAssociatedLimits: (state, payload) => {
		state.associatedLimits = payload
	},
	setSecondaryAssetsData: state => {
		state.secondaryAssetsToQuote = []
		state.selectedParkingLotsAmount = 0
		state.selectedWarehousesAmount = 0
	},
	setMultipleMasterAssetSelectionPermission: (state, payload) =>
		(state.permitMultipleMasterAssetSelection = payload),
	addSelectedMasterAsset: (state, assetId) => {
		state.selectedMasterAssets.push(assetId)
	},
	removeSelectedMasterAsset: (state, assetId) => {
		state.selectedMasterAssets = state.selectedMasterAssets.filter(
			asset => asset != assetId
		)
	},
	setPropertyNames: (state, version) => {
		if (version == 1) {
			state.propertyNames = {
				assetNumber: 'numeroDeBien',
				assetType: 'tipoBien',
				bathroomsAmount: 'numeroDeBanos',
				floor: 'piso',
				id: 'id',
				imageURLOne: 'urlImagenUno',
				imageURLTwo: 'urlImagenDos',
				imageURLThree: 'urlImagenTres',
				roomsAmount: 'numeroDeDormitorios',
				subAsset: 'bConjunto',
				totalArea: 'superficieTotal',
				terraceArea: 'superficieTerraza',
				utilSurface: 'superficieUtil',
				innerArea: 'superficieInterior',
				terrainArea: 'superficieTerreno',
				orientation: 'orientacion',
				price: 'valorLista',
				IVAprice: 'valorListaIVA',
				type: 'tipo',
				masterAsset: 'bMaestro',
				masterAssetTypes: [
					'CASA',
					'DEPARTAMENTO',
					'LOCAL',
					'OFICINA',
					'LOTE',
					'ACCION',
					'SITIO',
				],
				assetGroup: 'grupoBienes',
				discountGroup: 'descuentoGrupo',
				associationType: 'tipoAsociacion',
				discountType: 'tipoDescuento',
				discountAmount: 'montoDescuento',
				amountType: 'tipoMonto',
				associatedSubAssets: 'bAsociacion',
				discountAssetsValidation: 'validarBienesOportunidad',
				authorizedDiscount: 'descuentoAutorizadoBien',
				nonAuthorizedDiscount: 'descuentoNoAutorizadoBien',
				primaryAssetDiscount: 'bienMaestroDescuento',
				opportunityDiscountValue: 'descuentoOportunidad',
				discountValue: 'calculoDscto',
				assetSubtype: 'assetSubtype',
				associatedLimits: {
					max: 'maximo',
					min: 'minimo',
				},
				gardenSurface: 'superficieJardin',
			}
		} else if (version == 2) {
			state.propertyNames = {
				assetNumber: 'assetNumber',
				assetType: 'assetTypeValue',
				bathroomsAmount: 'numberOfBathrooms',
				floor: 'floor',
				id: 'id',
				imageURLOne: 'fullUrlImageOne',
				imageURLTwo: 'fullUrlImageTwo',
				imageURLThree: 'fullUrlImageTree',
				roomsAmount: 'numberOfRooms',
				subAsset: 'jointAssets',
				totalArea: 'totalArea',
				terraceArea: 'terraceArea',
				utilSurface: 'usableArea',
				innerArea: 'interiorArea',
				terrainArea: 'terrainArea',
				orientation: 'orientation',
				price: 'listValue',
				IVAprice: 'ivaListValue',
				type: 'departmentTypology',
				masterAsset: 'masterAsset',
				masterAssetTypes: [
					'CASA',
					'DEPARTAMENTO',
					'LOCAL',
					'OFICINA',
					'LOTE',
					'ACCION',
					'SITIO',
				],
				assetGroup: 'assetGroup',
				discountGroup: 'discountGroup',
				associationType: 'associationType',
				discountType: 'discountType',
				amountType: 'amountType',
				discountAmount: 'amount',
				associatedSubAssets: 'associationAssets',
				discountAssetsValidation: 'validateAssets',
				authorizedDiscount: 'authorizedDiscount',
				nonAuthorizedDiscount: 'nonAuthorizedDiscount',
				primaryAssetDiscount: 'primaryAssetDiscount',
				opportunityDiscountValue: 'opportunityDiscountValue',
				discountValue: 'discountValue',
				assetSubtype: 'assetSubtype',
				associatedLimits: {
					max: 'associationMax',
					min: 'associationMin',
				},
				gardenSurface: 'gardenArea',
			}
		}
	},
}

const actions = {
	setAssetsData: ({ state, rootState, commit }, { assets, filterTypology }) => {
		if (state.secondaryAssets) {
			state.secondaryAssets.split(',').forEach(asset => {
				state.propertyNames.masterAssetTypes.push(asset)
			})
		}
		if (rootState.general.assetType != null) {
			state.propertyNames.masterAssetTypes = [rootState.general.assetType]
		}
		const firstApartment = assets.find(asset =>
			state.propertyNames.masterAssetTypes.includes(
				asset[state.propertyNames.assetType]
			)
		)
		if (!assets.length || !firstApartment) {
			if (!assets.length) {
				console.log(
					'[MobySuite Quote] No assets provided, check if you have assets active in your project'
				)
				commit('general/noAssetsError', 'assets', { root: true })
			} else if (!firstApartment) {
				console.log('[MobySuite Quote] No apartments provided')
				commit('general/noAssetsError', 'assets', { root: true })
			} else console.log('[MobySuite Quote] Error trying to get assets')
			commit('general/setAtLeastOneVitalResponseFailed', null, {
				root: true,
			})

			commit('general/setVitalDataLoaded', 'assets', { root: true })
			return
		}

		commit('setAssets', assets)
		if (filterTypology.length == 0) {
			commit('setApartmentId', firstApartment[state.propertyNames.id])
		}

		commit('setAssetMasterType', [
			...new Set(
				assets
					.filter(asset =>
						state.propertyNames.masterAssetTypes.includes(
							asset[state.propertyNames.assetType]
						)
					)
					.map(asset => asset[state.propertyNames.assetType])
			),
		])
		const discountData = getters.getOpportunityDiscount(
			firstApartment[state.propertyNames.id]
		)
			? getters.getOpportunityDiscount(firstApartment[state.propertyNames.id])
			: getters.getQuoteDiscountInfo(firstApartment[state.propertyNames.id])
		if (
			discountData &&
			discountData[state.propertyNames.discountType] == 'GRUPO_BIENES'
		)
			commit('setOpportunityStatus', true)
		else commit('setOpportunityStatus', false)
		commit('general/setVitalDataLoaded', 'assets', { root: true })
		if (firstApartment[state.propertyNames.associationType]) {
			if (
				firstApartment[state.propertyNames.associationType] ==
				'BIEN_DESVINCULADO'
			)
				commit('setAssociatedStatus', 'BIEN_DESVINCULADO')
			if (
				firstApartment[state.propertyNames.associationType] == 'BIEN_ASOCIADO'
			) {
				commit('setAssociatedStatus', 'BIEN_ASOCIADO')
				const obj = {
					max: firstApartment[state.propertyNames.associatedLimits.max],
					min: firstApartment[state.propertyNames.associatedLimits.min],
				}
				commit('setAssociatedLimits', obj)
			}
		} else commit('setAssociatedStatus', null)
	},
	fetchAssets: async ({ commit, rootState, dispatch }, filterTypology) => {
		let assets = []
		if (rootState.general.typology && !rootState.general.typologySetByEvent) {
			for (const index in rootState.general.typology) {
				const version = rootState.general.version == 2 ? 'v3' : 'v2'
				await Vue.axios
					.get(`${version}/quotes/list-available-assets`, {
						params: {
							real_estate: rootState.general.realEstate,
							project_id: rootState.general.projectId,
							typology: rootState.general.typology[index],
							new_route:
								rootState.general.realEstate == 'demo' &&
								rootState.general.projectId == 2
									? true
									: false,
						},
					})
					.then(response => {
						if (response.data.length > 0) assets = [...assets, ...response.data]
					})
					.catch(error => {
						commit('general/setAtLeastOneVitalResponseFailed', null, {
							root: true,
						})
						commit('general/setVitalDataLoaded', 'assets', { root: true })
						console.log(error)
					})
			}
		} else {
			const version = rootState.general.version == 2 ? 'v3' : 'v2'
			await Vue.axios
				.get(`${version}/quotes/list-available-assets`, {
					params: {
						real_estate: rootState.general.realEstate,
						project_id: rootState.general.projectId,
						new_route:
							rootState.general.realEstate == 'demo' &&
							rootState.general.projectId == 2
								? true
								: false,
					},
				})
				.then(response => {
					if (response.data.length > 0) assets = [...assets, ...response.data]
				})
				.catch(error => {
					commit('general/setAtLeastOneVitalResponseFailed', null, {
						root: true,
					})
					commit('general/setVitalDataLoaded', 'assets', { root: true })
					console.log(error)
				})
		}
		dispatch('setAssetsData', { assets, filterTypology })
	},
	addSecondaryAsset: (
		{ state, commit },
		{ id, assetTypeName, filter, version }
	) => {
		if (filter) return true
		if (version == 2) {
			if (
				state.fetchedAssets.find(
					asset =>
						asset[state.propertyNames.id] == id &&
						asset[state.propertyNames.masterAsset].length > 0
				)
			) {
				return true
			}
		} else {
			if (
				state.fetchedAssets.find(
					asset =>
						asset[state.propertyNames.id] == id &&
						asset[state.propertyNames.masterAsset]
				)
			) {
				return true
			}
		}
		commit('addSecondaryAssetToQuote', id)
		commit('setIncreaseSecondaryAssetsAmount', assetTypeName)
		return false
	},
	resetSecondaryAssetsToQuote: ({ commit }) => {
		commit('setSecondaryAssetsData')
		commit('maps/setSecondaryAssetsData', null, { root: true })
	},
	updateMasterAssetsSelected: ({ commit, state }, data) => {
		if (data.action == 'add') commit('addSelectedMasterAsset', data.assetId)
		else {
			commit('removeSelectedMasterAsset', data.assetId)
			if (state.selectedMasterAssets.length > 0) {
				commit(
					'setApartmentId',
					state.selectedMasterAssets[state.selectedMasterAssets.length - 1]
				)
			} else {
				commit('reserveInvestors/setNoAssetSelected', true, { root: true })
			}
		}
	},
}

const getters = {
	getAssets: state => state.fetchedAssets,
	getAssetByType: state => assetType => {
		return state.fetchedAssets.filter(asset => {
			if (Array.isArray(assetType)) {
				return assetType.includes(asset[state.propertyNames.assetType])
			} else {
				return asset[state.propertyNames.assetType] == assetType
			}
		})
	},
	getAssetPropertyByType: (_state, getters) => (assetType, assetProperty) =>
		getters.getAssetByType(assetType).map(asset => asset[assetProperty]),
	getApartmentById: (state, getters) => (id = state.apartmentId) =>
		getters
			.getAssetByType(state.masterAssetType)
			.find(asset => asset[state.propertyNames.id] == id),
	getAssetById: state => (id = state.apartmentId) => {
		let result = null
		result = state.fetchedAssets.find(
			asset => asset[state.propertyNames.id] == id
		)
		if (!result) {
			//Search it in associated sub assets array
			state.fetchedAssets.find(asset => {
				if (asset[state.propertyNames.associatedSubAssets])
					result = asset[state.propertyNames.associatedSubAssets].find(
						subAsset => subAsset[state.propertyNames.id] == id
					)

				if (!result && asset[state.propertyNames.discountGroup])
					asset[state.propertyNames.discountGroup].map(asset => {
						result = asset[state.propertyNames.assetGroup].find(
							asset => asset[state.propertyNames.id] == id
						)
					})
				return result ? true : false
			})
		}
		return result
	},
	getSecondaryAssets: (state, getters) => typeName => {
		let secondaryAssets = []
		if (state.associatedStatus == 'BIEN_ASOCIADO') {
			secondaryAssets = getters.getMainAssetSubAssets.filter(
				asset => asset[state.propertyNames.assetType] == typeName
			)
		} else {
			secondaryAssets = getters.getAssetByType(typeName)
		}
		return secondaryAssets
			.filter(
				asset =>
					!(
						asset[state.propertyNames.associationType] &&
						asset[state.propertyNames.associationType] == 'BIEN_DESVINCULADO'
					)
			)
			.map(asset => {
				return {
					id: asset[state.propertyNames.id],
					assetNumber: asset[state.propertyNames.assetNumber],
					bMaestro: asset[state.propertyNames.masterAsset],
					bConjunto: asset[state.propertyNames.subAsset],
				}
			})
	},
	getApartmentInfo: (state, getters) => {
		const apartment = getters.getApartmentById()
		let URLValue = null
		if (state.useSecondaryImage) {
			URLValue = apartment?.[state.propertyNames.imageURLTwo] || null
		} else if (state.useThirdImage) {
			URLValue = apartment?.[state.propertyNames.imageURLThree] || null
		} else {
			URLValue = apartment?.[state.propertyNames.imageURLOne] || null
		}

		const imageURL = URLValue
			? `${URLValue}`
			: 'https://748073e22e8db794416a-cc51ef6b37841580002827d4d94d19b6.ssl.cf3.rackcdn.com/not-found.png'

		const discountData = getters.getOpportunityDiscount()
			? getters.getOpportunityDiscount()
			: getters.getQuoteDiscountInfo()
		return apartment
			? {
					title: `${apartment[state.propertyNames.assetType]} ${
						apartment[state.propertyNames.assetNumber]
					}`,
					type: apartment[state.propertyNames.type],
					surface:
						apartment[state.propertyNames.totalArea] > 0
							? apartment[state.propertyNames.totalArea].toFixed(2)
							: 0,
					inner:
						apartment[state.propertyNames.innerArea] > 0
							? apartment[state.propertyNames.innerArea].toFixed(2)
							: 0,
					image: imageURL,
					bathrooms: apartment[state.propertyNames.bathroomsAmount],
					rooms: apartment[state.propertyNames.roomsAmount],
					floor: apartment[state.propertyNames.floor],
					terrace:
						apartment[state.propertyNames.terraceArea] > 0
							? apartment[state.propertyNames.terraceArea].toFixed(2)
							: 0,
					terrain:
						apartment[state.propertyNames.terrainArea] > 0
							? apartment[state.propertyNames.terrainArea].toFixed(2)
							: 0,
					util:
						apartment[state.propertyNames.utilSurface] > 0
							? apartment[state.propertyNames.utilSurface].toFixed(2)
							: 0,
					orientation: apartment[state.propertyNames.orientation],
					assetType: apartment[state.propertyNames.assetType],
					price: discountData
						? discountData[state.propertyNames.discountValue]
							? discountData[state.propertyNames.discountValue]
							: apartment[state.propertyNames.primaryAssetDiscount]
						: apartment[state.propertyNames.IVAprice]
						? apartment[state.propertyNames.IVAprice]
						: apartment[state.propertyNames.price],
					discount: discountData ? discountData : false,
					descuentoAutorizadoBien:
						apartment[state.propertyNames.authorizedDiscount] &&
						apartment[state.propertyNames.authorizedDiscount] != 0
							? apartment[state.propertyNames.authorizedDiscount]
							: null,
					descuentoNoAutorizadoBien:
						apartment[state.propertyNames.nonAuthorizedDiscount] &&
						apartment[state.propertyNames.nonAuthorizedDiscount] != 0
							? apartment[state.propertyNames.nonAuthorizedDiscount]
							: null,
					associationStatus: apartment[state.propertyNames.associationType]
						? apartment[state.propertyNames.associationType]
						: null,
					associationLimits: {
						max: apartment[state.propertyNames.associatedLimits.max],
						min: apartment[state.propertyNames.associatedLimits.min],
					},
					image2: apartment[state.propertyNames.imageURLTwo]
						? apartment[state.propertyNames.imageURLTwo]
						: 'https://748073e22e8db794416a-cc51ef6b37841580002827d4d94d19b6.ssl.cf3.rackcdn.com/not-found.png',
					image3: apartment[state.propertyNames.imageURLThree]
						? apartment[state.propertyNames.imageURLThree]
						: 'https://748073e22e8db794416a-cc51ef6b37841580002827d4d94d19b6.ssl.cf3.rackcdn.com/not-found.png',
			  }
			: {
					title: '',
					type: '',
					surface: 0,
					image: imageURL,
					bathrooms: null,
					rooms: null,
					floor: null,
					terrace: 0,
					util: 0,
					orientation: null,
					assetType: null,
					price: 0,
			  }
	},
	getAparmentSubAssets: (state, getters) => assetType => {
		const apartment = getters.getApartmentById()

		if (!apartment || !apartment[state.propertyNames.subAsset]) return []

		const subAssets = apartment[state.propertyNames.subAsset].filter(
			asset => asset[state.propertyNames.assetType] == assetType
		)

		return subAssets.map(
			asset =>
				`${asset[state.propertyNames.assetType]}-${
					asset[state.propertyNames.assetNumber]
				}`
		)
	},
	getApartmentParkingLots: (_state, getters) => {
		return getters.getAparmentSubAssets(state.typeNames.parkingLot)
	},
	getApartmentWarehouses: (state, getters) => {
		return getters.getAparmentSubAssets(state.typeNames.warehouse)
	},
	getSelectedApartmentAndSubAssets: (state, getters) => (
		assetId = state.apartmentId
	) => {
		const discountData = getters.getOpportunityDiscount(assetId)
			? getters.getOpportunityDiscount(assetId)
			: getters.getQuoteDiscountInfo(assetId)

		let assets = [
			{
				id: assetId,
				discountId: discountData ? discountData[state.propertyNames.id] : null,
				masterAsset: true,
			},
		]

		const apartment = getters.getApartmentById(assetId)

		if (apartment) {
			if (apartment[state.propertyNames.subAsset]) {
				assets = [
					...assets,
					...apartment[state.propertyNames.subAsset].map(subAsset => {
						return {
							id: subAsset[state.propertyNames.id],
							discountId: null,
						}
					}),
				]
			}
			if (discountData) {
				if (
					discountData[state.propertyNames.assetGroup] &&
					(discountData[state.propertyNames.discountType] == 'GRUPO_BIENES' ||
						(discountData[state.propertyNames.discountType] ==
							'BIENES_INDIVIDUALES' &&
							getters.getDiscountAssetsValidation))
				) {
					assets = [
						...assets,
						...discountData[state.propertyNames.assetGroup]
							.filter(
								subAsset =>
									subAsset[state.propertyNames.id] !=
									apartment[state.propertyNames.id]
							)
							.map(subAsset => {
								return {
									id: subAsset[state.propertyNames.id],
									discountId: getters.getOpportunityDiscount()
										? null
										: getters.getQuoteDiscountInfo(
												subAsset[state.propertyNames.id]
										  ),
								}
							}),
					]
				}
			}
		}

		//set discount id
		state.discountId = discountData ? discountData['id'] : null
		return assets
	},
	getRoomsAmounts: (state, getters) => {
		const propertyValueList = getters.getAssetPropertyByType(
			state.masterAssetType,
			state.propertyNames.roomsAmount
		)

		const cleanedPropertyValueList = [...new Set(propertyValueList)]
			.filter(propertyValue => ![null, undefined].includes(propertyValue))
			.sort()

		return cleanedPropertyValueList
	},
	getBathroomsAmounts: (state, getters) => {
		const propertyValueList = getters.getAssetPropertyByType(
			state.masterAssetType,
			state.propertyNames.bathroomsAmount
		)

		const cleanedPropertyValueList = [...new Set(propertyValueList)]
			.filter(propertyValue => ![null, undefined].includes(propertyValue))
			.sort()

		return cleanedPropertyValueList
	},
	getWarehouses: (state, getters) =>
		getters.getSecondaryAssets(state.typeNames.warehouse),
	getParkingLots: (state, getters) =>
		getters.getSecondaryAssets(state.typeNames.parkingLot),
	getFinalAssetsAmount: (state, getters) => {
		let allSelectedAssetsIds = []
		let allAssetsData = []
		if (state.permitMultipleMasterAssetSelection) {
			let assets = []
			let associatedSubAssets = []
			state.selectedMasterAssets.map(asset => {
				assets = assets.concat(getters.getSelectedApartmentAndSubAssets(asset))
				associatedSubAssets = associatedSubAssets.concat(
					getters.getMainAssetSubAssets(asset)
				)
			})
			allSelectedAssetsIds = assets.map(asset => asset.id)
			allAssetsData = [...state.fetchedAssets, ...associatedSubAssets]
		} else {
			allSelectedAssetsIds = [
				...getters.getSelectedApartmentAndSubAssets(state.apartmentId),
				...state.secondaryAssetsToQuote,
			].map(asset => asset.id)
			allAssetsData = [
				...state.fetchedAssets,
				...getters.getMainAssetSubAssets(state.apartmentId),
			]
		}

		const finalAmount = allAssetsData
			.filter(asset => allSelectedAssetsIds.includes(asset.id))
			.map(asset => {
				const discountData = getters.getOpportunityDiscount(
					asset[state.propertyNames.id]
				)
					? getters.getOpportunityDiscount(asset[state.propertyNames.id])
					: getters.getQuoteDiscountInfo(asset[state.propertyNames.id])

				return discountData
					? discountData[state.propertyNames.discountValue]
						? discountData[state.propertyNames.discountValue]
						: asset[state.propertyNames.primaryAssetDiscount]
						? asset[state.propertyNames.primaryAssetDiscount]
						: asset[state.propertyNames.IVAprice]
						? asset[state.propertyNames.IVAprice]
						: asset[state.propertyNames.price]
					: asset[state.propertyNames.IVAprice]
					? asset[state.propertyNames.IVAprice]
					: asset[state.propertyNames.price]
			})
			.reduce((finalAmount, currentAmount) => finalAmount + currentAmount)

		return finalAmount
	},
	getAssetInfo: (state, getters) => {
		let allSelectedAssets = []
		if (state.permitMultipleMasterAssetSelection) {
			state.selectedMasterAssets.map(asset => {
				allSelectedAssets = allSelectedAssets.concat(
					getters.getSelectedApartmentAndSubAssets(asset)
				)
			})
		} else {
			allSelectedAssets = [
				...getters.getSelectedApartmentAndSubAssets(state.apartmentId),
				...state.secondaryAssetsToQuote,
			]
		}
		//NOTE: please make sure there is no duplicates in array, we use find nowadays in order to prevent it
		return allSelectedAssets.map(asset => {
			const assetData = getters.getAssetById(asset.id)
			const discountData = asset.discountId
				? getters.getOpportunityDiscount(assetData[state.propertyNames.id])
					? getters.getOpportunityDiscount(assetData[state.propertyNames.id])
					: getters.getQuoteDiscountInfo(assetData[state.propertyNames.id])
				: null
			if (assetData) {
				return {
					id: assetData[state.propertyNames.id],
					name: `${assetData[state.propertyNames.assetType]}  ${
						assetData[state.propertyNames.assetNumber]
					}`,
					price: assetData[state.propertyNames.IVAprice]
						? assetData[state.propertyNames.IVAprice]
						: assetData[state.propertyNames.price],
					discount: discountData ? discountData : false,
					descuentoAutorizadoBien:
						assetData[state.propertyNames.authorizedDiscount] &&
						assetData[state.propertyNames.authorizedDiscount] != 0
							? assetData[state.propertyNames.authorizedDiscount]
							: null,
					descuentoNoAutorizadoBien:
						assetData[state.propertyNames.nonAuthorizedDiscount] &&
						assetData[state.propertyNames.nonAuthorizedDiscount] != 0
							? assetData[state.propertyNames.nonAuthorizedDiscount]
							: null,
					discountAmount: assetData[
						state.propertyNames.opportunityDiscountValue
					]
						? assetData[state.propertyNames.opportunityDiscountValue]
						: null,
					assetPriceWithDiscount: discountData
						? discountData[state.propertyNames.discountValue]
							? discountData[state.propertyNames.discountValue]
							: assetData[state.propertyNames.primaryAssetDiscount]
							? assetData[state.propertyNames.primaryAssetDiscount]
							: null
						: null,
					type: assetData[state.propertyNames.type],
					masterAsset: asset['masterAsset'] ? true : false,
				}
			} else {
				return null
			}
		})
	},
	getApartmentsByTypology: (state, getters, rootState) => {
		let apartments = getters.getAssetByType(state.masterAssetType)

		if (rootState.general.typology)
			apartments = apartments.filter(apartment =>
				rootState.general.typology.includes(apartment[state.propertyNames.type])
			)

		if (rootState.general.roomsAmount)
			apartments = apartments.filter(
				apartment =>
					apartment[state.propertyNames.roomsAmount] ==
					rootState.general.roomsAmount
			)

		if (rootState.general.bathroomsAmount)
			apartments = apartments.filter(
				apartment =>
					apartment[state.propertyNames.bathroomsAmount] ==
					rootState.general.bathroomsAmount
			)

		return apartments.map(asset => {
			const discountData = getters.getOpportunityDiscount(
				asset[state.propertyNames.id]
			)
				? getters.getOpportunityDiscount(asset[state.propertyNames.id])
				: getters.getQuoteDiscountInfo(asset[state.propertyNames.id])
			return { ...asset, ...{ discountData: discountData } }
		})
	},
	getOpportunityStatus: state => state.isOpportunity,
	getOpportunityDiscount: (state, getters) => (id = state.apartmentId) => {
		const selectedApartment = getters.getAssetById(id)
		return selectedApartment[state.propertyNames.discountGroup] &&
			selectedApartment[state.propertyNames.discountGroup][0]
			? selectedApartment[state.propertyNames.discountGroup].find(
					discount =>
						discount[state.propertyNames.discountType] == 'GRUPO_BIENES'
			  )
			: null
	},
	getQuoteDiscountInfo: (state, getters) => (id = state.apartmentId) => {
		const selectedApartment = getters.getAssetById(id)
		return selectedApartment[state.propertyNames.discountGroup] &&
			selectedApartment[state.propertyNames.discountGroup][0]
			? selectedApartment[state.propertyNames.discountGroup].find(
					discount =>
						discount[state.propertyNames.discountType] != 'GRUPO_BIENES'
			  )
			: null
	},
	getMainAssetSubAssets: (state, getters) => (id = state.apartmentId) => {
		const asset = getters.getAssetById(id)
		return asset[state.propertyNames.associatedSubAssets]
			? asset[state.propertyNames.associatedSubAssets]
			: []
	},
	getAssociatedStatus: state => state.associatedStatus,
	getDiscountAssetsValidation: (state, getters) => {
		return state.isOpportunity
			? getters.getOpportunityDiscount()[
					state.propertyNames.discountAssetsValidation
			  ]
			: getters.getQuoteDiscountInfo()
			? getters.getQuoteDiscountInfo()[
					state.propertyNames.discountAssetsValidation
			  ]
			: false
	},
	getNotSelectableSubAssets: (state, getters) => {
		let assets = []
		const apartment = getters.getApartmentById()

		const discountData = getters.getOpportunityDiscount()
			? getters.getOpportunityDiscount()
			: getters.getQuoteDiscountInfo()

		if (apartment) {
			if (apartment[state.propertyNames.subAsset]) {
				assets = [
					...assets,
					...apartment[state.propertyNames.subAsset].map(subAsset => {
						return subAsset[state.propertyNames.id]
					}),
				]
			}
			if (discountData) {
				if (
					discountData[state.propertyNames.assetGroup] &&
					state.isOpportunity
				) {
					assets = [
						...assets,
						...discountData[state.propertyNames.assetGroup]
							.filter(
								subAsset =>
									subAsset[state.propertyNames.id] !=
									apartment[state.propertyNames.id]
							)
							.map(subAsset => {
								return subAsset[state.propertyNames.id]
							}),
					]
				}
			}
		}

		return assets
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
