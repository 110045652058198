import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import NotFound from '@/views/NotFound.vue'
import ServerError from '@/views/ServerError.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Main',
		component: Main,
	},
	{
		path: '/500',
		name: 'Error500',
		component: ServerError,
	},
	{
		path: '/404',
		name: 'Error404',
		component: NotFound,
		alias: '/*',
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
