var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
		this.$vuetify.breakpoint.name == 'sm' ||
			this.$vuetify.breakpoint.name == 'xs' ||
			_vm.mobileStyle
	)?_c('v-row',{class:_vm.projectData.projectLogoUrl
			? 'd-flex justify-space-between'
			: 'd-flex justify-center',style:(("background-color:" + _vm.backgroundColor + "; color:" + _vm.textColor)),attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-img',{staticClass:"ml-2 mt-2",attrs:{"contain":"","cols":"3","max-height":"80px","max-width":"120px","src":_vm.realEstateLogoUrl}}),(_vm.projectData.projectLogoUrl && _vm.showProjectLogo)?_c('v-img',{staticClass:"mr-2 mt-2",attrs:{"contain":"","cols":"3","max-height":"80px","max-width":"120px","src":_vm.projectData.projectLogoUrl}}):_vm._e(),(!_vm.hideProjectData)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"subtitle-1 font-weight-thin"},[(_vm.icon.length)?_c('font-awesome-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.subtitle)+" ")],1)]):_vm._e()],1):_c('v-row',{class:_vm.projectData.projectLogoUrl
			? 'd-flex justify-space-between pb-3'
			: 'd-flex justify-center pb-3',style:(("background-color:" + _vm.backgroundColor + "; color:" + _vm.textColor)),attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"justify":_vm.title != '' || _vm.projectData.projectLogoUrl ? 'start' : 'center',"align":_vm.title != '' || _vm.projectData.projectLogoUrl ? 'start' : 'center'}},[_c('v-img',{staticClass:"ml-2",attrs:{"contain":"","max-height":"80px","max-width":"150px","src":_vm.realEstateLogoUrl}})],1),(_vm.title != '' && !_vm.hideProjectData)?_c('v-col',{attrs:{"justify":"center","align":"center"}},[_c('p',{class:_vm.subtitle != ''
					? 'font-weight-black text-h5'
					: 'font-weight-thin text-h4'},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"subtitle-1 font-weight-thin"},[(_vm.icon.length)?_c('font-awesome-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.subtitle)+" ")],1)]):_vm._e(),(_vm.projectData.projectLogoUrl || _vm.title != '')?_c('v-col',{staticClass:"d-flex justify-end"},[(_vm.projectData.projectLogoUrl && _vm.showProjectLogo)?_c('v-img',{staticClass:"mr-2",attrs:{"contain":"","max-height":"80px","max-width":"150px","src":_vm.projectData.projectLogoUrl}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }