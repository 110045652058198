<template>
	<div
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2}`
		"
	>
		<banner
			class="pt-3"
			:title="projectData.projectName"
			:subtitle="projectData.address"
			:icon="['fas', 'map-marker-alt']"
			:background-color="themeColors.color1"
			:text-color="themeColors.color2"
		/>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="6">
				<p v-if="lowerCaseName !== 'accion'" class="text-body-1 mb-1 pl-1">
					<v-avatar class="mr-1" :color="themeColors.color3" size="24">
						<span
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber(1) }}</span
						>
					</v-avatar>
					Seleccionar bien
				</p>
				<v-row v-if="lowerCaseName !== 'accion'" no-gutters>
					<v-col cols="12" xs="12" sm="6">
						<select-triangular
							:asset-property-name="room_select_text"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							@reset-data="resetSubAssetsInitialData"
						/>
					</v-col>
					<v-col cols="12" xs="12" sm="6">
						<select-triangular
							asset-property-name="bathrooms"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							@reset-data="resetSubAssetsInitialData"
						/>
					</v-col>
					<v-col cols="12">
						<select-triangular
							asset-property-name="apartment"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							@reset-data="resetSubAssetsInitialData"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="projectData.warehouseService || projectData.parkingService"
					class="mt-2"
					no-gutters
				>
					<p
						v-if="getApartmentInfo.associationStatus != 'BIEN_DESVINCULADO'"
						class="text-body-1 ml-2"
					>
						<span
							v-if="projectData.warehouseService && projectData.parkingService"
							class="mr-2"
							>Seleccionar Estacionamiento y/o Bodega</span
						>
						<span
							v-else-if="
								!projectData.warehouseService && projectData.parkingService
							"
							class="mr-2"
							>Seleccionar Estacionamiento</span
						>
						<span v-else class="mr-2">Seleccionar Bodega</span>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-avatar class="mr-2" color="#A1A4A1" size="20">
									<span
										v-bind="attrs"
										class="white--text"
										style="font-size: 15px;"
										v-on="on"
										>?</span
									>
								</v-avatar>
							</template>
							<span
								>Los bienes que son agregados automáticamente son bienes
								conjuntos, no se pueden vender por separado</span
							>
						</v-tooltip>
					</p>
					<select-sub-assets ref="subAssets" class="mt-1" />
				</v-row>
				<v-row class="mb-1" align="center" justify="center">
					<v-col>
						<div style="width:100%">
							<v-row>
								<v-col
									v-if="
										getApartmentInfo.rooms != null &&
											getApartmentInfo.rooms != 0
									"
									align="center"
									justify="center"
								>
									<p class="font-weight-black">{{ rooms_text }}</p>
									{{ getApartmentInfo.rooms }}</v-col
								>
								<v-col
									v-if="
										getApartmentInfo.bathrooms != null &&
											getApartmentInfo.bathrooms != 0
									"
									align="center"
									justify="center"
								>
									<p class="font-weight-black">Baños</p>
									{{ getApartmentInfo.bathrooms }}</v-col
								>
								<v-col
									v-if="
										getApartmentInfo.orientation != null &&
											getApartmentInfo.orientation != 0 &&
											getApartmentInfo.orientation != 'EMPTY' &&
											projectData.orientation
									"
									align="center"
									justify="center"
								>
									<p class="font-weight-black">Orientación</p>
									{{ getApartmentInfo.orientation }}</v-col
								>
							</v-row>
							<v-row>
								<v-col
									v-if="
										getApartmentInfo.surface != null &&
											getApartmentInfo.surface != 0
									"
									align="center"
									justify="center"
								>
									<p class="font-weight-black">{{ surface_text }} Total</p>
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(getApartmentInfo.surface)
									}}
									m<sup>2</sup></v-col
								>
								<v-col
									v-if="
										getApartmentInfo.util != null && getApartmentInfo.util != 0
									"
									align="center"
									justify="center"
								>
									<p class="font-weight-black">{{ surface_text }} Útil</p>
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(getApartmentInfo.util)
									}}
									m<sup>2</sup></v-col
								>
								<v-col
									v-if="
										getApartmentInfo.terrain != null &&
											getApartmentInfo.terrain != '0' &&
											getApartmentInfo.assetType == 'CASA'
									"
									align="center"
									justify="center"
								>
									<p class="font-weight-black">{{ surface_text }} Terreno</p>
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(getApartmentInfo.terrain)
									}}
									m<sup>2</sup></v-col
								>
								<v-col
									v-else-if="
										getApartmentInfo.terrace != null &&
											getApartmentInfo.terrace != '0'
									"
									align="center"
									justify="center"
								>
									<p class="font-weight-black">{{ surface_text }} Terraza</p>
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(getApartmentInfo.terrace)
									}}
									m<sup>2</sup></v-col
								>
							</v-row>
						</div>
					</v-col>
				</v-row>
				<v-row align="center" justify="center" no-gutters>
					<v-col class="px-2">
						<image-dialog
							:thumb="`${getApartmentInfo.image}`"
							:full="`${getApartmentInfo.image}`"
							:mobile="
								this.$vuetify.breakpoint.name == 'xs' ||
								this.$vuetify.breakpoint.name == 'sm'
									? true
									: false
							"
							:style="
								` max-height: 400px: max-width:${imgWidth}px ${
									sendingQuote ? '' : 'z-index: 200; position:relative;'
								} `
							"
						></image-dialog>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" sm="12" md="6">
				<p v-if="!hideSelectedAssets" class="text-body-1 mb-5 pl-1">
					<v-avatar class="mr-2" :color="themeColors.color3" size="24">
						<span
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{
								lowerCaseName !== 'accion'
									? updateStepNumber()
									: updateStepNumber(1)
							}}</span
						>
					</v-avatar>
					Bienes cotizados
				</p>
				<v-row
					v-if="!hideSelectedAssets"
					class="mb-5 px-1"
					align="center"
					justify="center"
					no-gutters
				>
					<table-sub-assets :show-total="true" :from-mainview="true" />
				</v-row>
				<p class="text-body-1 pl-1">
					<v-avatar class="mr-2" :color="themeColors.color3" size="23">
						<span
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber() }}</span
						>
					</v-avatar>
					Ingresa tus datos de contacto
				</p>
				<quote-form ref="formRef" class="mt-2" />
				<v-row no-gutters class="mt-1">
					<v-col>
						<p v-if="quoteLimit != null && quoteLimit <= 0" style="color: red">
							Ha excedido el número de cotizaciones permitidas por día.
						</p>
						<v-btn
							id="moby-quote-button"
							:color="themeColors.color3"
							x-large
							block
							class="mt-2"
							:style="
								`color: ${
									quoteLimit != null && quoteLimit <= 0
										? '#000'
										: themeColors.buttonTextColor
								}; font-size: 16px`
							"
							:disabled="quoteLimit != null && quoteLimit <= 0 ? true : false"
							@click="$refs.formRef.submitForm()"
						>
							{{ textButton }}
						</v-btn>
					</v-col>
					<v-col
						v-if="getOpportunityStatus && !reservation"
						cols="12"
						xs="12"
						sm="6"
					>
						<v-btn
							id="moby-reservation-button"
							:color="themeColors.color3"
							x-large
							block
							class="mt-2 ml-sm-2"
							:style="`color: ${themeColors.buttonTextColor}; font-size: 16px`"
							@click="$refs.formRef.submitForm(true)"
						>
							Reservar
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import SelectTriangular from '@/components/SelectTriangular.vue'
import Banner from '@/components/Banner.vue'
import texts from '../mixins/TemplateTexts'
import SelectSubAssets from '@/components/SelectSubAssets.vue'
import TableSubAssets from '@/components/TableSubAssets.vue'
import ImageDialog from '@/components/ImageDialog.vue'
let stepNumber = 1
export default {
	name: 'Default',
	components: {
		QuoteForm,
		SelectTriangular,
		Banner,
		SelectSubAssets,
		TableSubAssets,
		ImageDialog,
	},
	mixins: [texts],
	computed: {
		...mapState('form', ['countryCode', 'sendingQuote']),
		...mapState('maps', ['useMap']),
		...mapState('general', [
			'projectData',
			'quoteLimit',
			'reservation',
			'hideSelectedAssets',
		]),
		...mapState('assets', ['masterAssetType']),
		...mapGetters('assets', ['getApartmentInfo', 'getOpportunityStatus']),
		...mapGetters('maps', ['getAssetMaps']),
		...mapState('theme', ['themeColors']),
		imgWidth() {
			return this.$vuetify.breakpoint.name == 'xs' ? '90%' : 540
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
		textColor() {
			return {
				'--text-color': this.themeColors.selectTextColor,
			}
		},
		lowerCaseName() {
			return this.masterAssetType.join(',').toLowerCase()
		},
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
	},
	methods: {
		resetSubAssetsInitialData(data) {
			if (
				data == 'apartment' &&
				this.getApartmentInfo.associationStatus != null
			)
				this.$refs.subAssets.resetInitialData()
		},
		updateStepNumber(num = null) {
			return num ? (stepNumber = num) : (stepNumber += 1)
		},
	},
}
</script>
<style scoped lang="scss">
mobysuite-quote {
	.v-application p {
		margin: 0px;
	}
}
</style>
<style lang="scss">
mobysuite-quote {
	.v-input > .v-input__control > .v-input__slot:before {
		border-color: var(--text-color) !important;
	}
}
</style>
