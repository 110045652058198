<template>
	<div
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2}`
		"
	>
		<banner
			title="Cotizador"
			:background-color="themeColors.color1"
			:text-color="themeColors.color2"
		/>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="5" no-gutters>
				<p class="text-body-1">
					<v-avatar class="mr-2" :color="themeColors.color3" size="23">
						<span
							:style="`font-size: 15px; color: ${themeColors.buttonTextColor}`"
							>{{ updateStepNumber(1) }}</span
						>
					</v-avatar>
					Seleccionar unidad
				</p>
				<v-row class="mt-2" no-gutters>
					<v-col cols="12" xs="12" sm="6">
						<select-triangular
							class="mr-sm-1"
							asset-property-name="rooms"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							@reset-data="resetSubAssetsInitialData"
						/>
					</v-col>
					<v-col cols="12" xs="12" sm="6">
						<select-triangular
							class="ml-sm-1"
							asset-property-name="bathrooms"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							@reset-data="resetSubAssetsInitialData"
						/>
					</v-col>
					<v-col>
						<select-triangular
							asset-property-name="apartment"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							@reset-data="resetSubAssetsInitialData"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="projectData.warehouseService || projectData.parkingService"
					no-gutters
				>
					<p
						v-if="getApartmentInfo.associationStatus != 'BIEN_DESVINCULADO'"
						class="text-body-1"
					>
						<v-avatar class="mr-2" :color="themeColors.color3" size="23">
							<span
								:style="
									`font-size: 15px; color: ${themeColors.buttonTextColor}`
								"
								>{{ updateStepNumber() }}</span
							>
						</v-avatar>
						<span
							v-if="projectData.warehouseService && projectData.parkingService"
							class="mr-2"
							>Seleccionar Estacionamiento y/o Bodega</span
						>
						<span
							v-else-if="
								!projectData.warehouseService && projectData.parkingService
							"
							class="mr-2"
							>Seleccionar Estacionamiento</span
						>
						<span v-else class="mr-2">Seleccionar Bodega</span>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-avatar class="mr-2" color="#A1A4A1" size="20">
									<span
										v-bind="attrs"
										class="white--text"
										style="font-size: 15px;"
										v-on="on"
										>?</span
									>
								</v-avatar>
							</template>
							<span
								>Los bienes que son agregados automáticamente son bienes
								conjuntos, no se pueden vender por separado</span
							>
						</v-tooltip>
					</p>
					<select-sub-assets ref="subAssets" class="mt-1" />
				</v-row>
				<v-row class="mt-3" no-gutters>
					<p class="text-body-1">
						<v-avatar class="mr-2" :color="themeColors.color3" size="23">
							<span
								v-if="
									(!projectData.warehouseService &&
										!projectData.parkingService) ||
										getApartmentInfo.associationStatus == 'BIEN_DESVINCULADO'
								"
								:style="
									`font-size: 15px; color: ${themeColors.buttonTextColor}`
								"
								>{{ updateStepNumber() }}</span
							>
							<span
								v-else
								:style="
									`font-size: 15px; color: ${themeColors.buttonTextColor}`
								"
								>{{ updateStepNumber() }}</span
							>
						</v-avatar>
						Ingresa tus datos de contacto
					</p>
					<quote-form
						ref="formRef"
						class="mt-3"
						:text-field-outlined="true"
						:select-outlined="true"
					/>
				</v-row>
			</v-col>
			<v-col
				cols="12"
				sm="12"
				md="7"
				align="center"
				justify="center"
				class="d-flex flex-column"
			>
				<v-row
					class="d-flex flex-column align-self-center"
					style="max-width: 100%"
					no-gutters
				>
					<v-col>
						<image-dialog
							:thumb="`${getApartmentInfo.image}`"
							:full="`${getApartmentInfo.image}`"
							:mobile="
								this.$vuetify.breakpoint.name == 'xs' ||
								this.$vuetify.breakpoint.name == 'sm'
									? true
									: false
							"
							:style="
								`position:relative; max-height: 400, max-width: ${imgWidth} `
							"
						></image-dialog>
					</v-col>
					<v-row
						class="my-5 pa-3 align-self-center"
						align="center"
						justify="center"
						:style="
							this.$vuetify.breakpoint.name == 'sm' ||
							this.$vuetify.breakpoint.name == 'xs'
								? `background-color:${themeColors.color3}; color: ${themeColors.buttonTextColor}; opacity: 0.8; min-width: 100%; max-height: 70px;`
								: `background-color:${themeColors.color3}; color: ${themeColors.buttonTextColor}; opacity: 0.8; min-width: 90%; max-height: 50px;`
						"
						no-gutters
					>
						<v-col cols="4" align="center" justify="center">
							<v-row align="center" justify="center" no-gutters>
								<font-awesome-icon
									style="font-size: 20px;"
									class="ma-1"
									:icon="['fas', 'bed-alt']"
								/>
								{{ getApartmentInfo.rooms }}
								Dormitorios
							</v-row>
						</v-col>
						<v-col cols="4" align="center" justify="center">
							<v-row align="center" justify="center" no-gutters>
								<font-awesome-icon
									style="font-size: 20px;"
									class="ma-1"
									:icon="['fas', 'bath']"
								/>
								{{ getApartmentInfo.bathrooms }}
								Baños
							</v-row>
						</v-col>
						<v-col cols="4" align="center" class="d-inline pa-1">
							<v-row align="center" justify="center" no-gutters>
								<font-awesome-icon
									style="font-size: 20px;"
									class="ma-1"
									:icon="['fal', 'ruler-triangle']"
								/>
								{{ getApartmentInfo.surface }} m<sup>2</sup>
							</v-row>
						</v-col>
					</v-row>
				</v-row>

				<v-row class="mt-auto d-flex" no-gutters align="center" justify="end">
					<v-col class="mt-auto">
						<p v-if="quoteLimit != null && quoteLimit <= 0" style="color: red">
							Ha excedido el número de cotizaciones permitidas por día.
						</p>
						<v-btn
							id="moby-quote-button"
							:color="themeColors.color3"
							width="90%"
							x-large
							:style="
								`color: ${
									quoteLimit != null && quoteLimit <= 0
										? '#000'
										: themeColors.buttonTextColor
								}; font-size: 18px`
							"
							:disabled="quoteLimit != null && quoteLimit <= 0 ? true : false"
							@click="$refs.formRef.submitForm()"
						>
							{{ textButton }}
						</v-btn>
					</v-col>
					<v-col
						v-if="getOpportunityStatus && !reservation"
						cols="12"
						xs="12"
						sm="6"
					>
						<v-btn
							id="moby-reservation-button"
							:color="themeColors.color3"
							x-large
							width="90%"
							class="mt-2 ml-sm-2"
							:style="`color: ${themeColors.buttonTextColor}; font-size: 16px`"
							@click="$refs.formRef.submitForm(true)"
						>
							Reservar
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import SelectTriangular from '@/components/SelectTriangular.vue'
import Banner from '@/components/Banner.vue'
import SelectSubAssets from '@/components/SelectSubAssets.vue'
import ImageDialog from '@/components/ImageDialog.vue'
let stepNumber = 1
export default {
	name: 'Filtro',
	components: {
		QuoteForm,
		SelectTriangular,
		Banner,
		SelectSubAssets,
		ImageDialog,
	},
	computed: {
		...mapState('general', ['projectData', 'quoteLimit', 'reservation']),
		...mapGetters('assets', ['getApartmentInfo', 'getOpportunityStatus']),
		...mapState('theme', ['themeColors']),
		imgWidth() {
			return this.$vuetify.breakpoint.name == 'xs' ? '90%' : 580
		},
		textColor() {
			return {
				'--text-color': this.themeColors.selectTextColor,
			}
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
	},
	methods: {
		resetSubAssetsInitialData(data) {
			if (
				data == 'apartment' &&
				this.getApartmentInfo.associationStatus == 'BIEN_DESVINCULADO'
			)
				this.$refs.subAssets.resetInitialData()
		},
		updateStepNumber(num = null) {
			return num ? (stepNumber = num) : (stepNumber += 1)
		},
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
.v-input > .v-input__control > .v-input__slot fieldset {
	color: var(--text-color) !important;
}
</style>
