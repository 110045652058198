var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-data-table",style:(("width:100%; color: var(--text-color); border: 0px; " + (_vm.backgroundColor ? ("background-color: " + _vm.backgroundColor + ";") : '')))},[_c('div',{staticClass:"v-data-table__wrapper"},[_c('table',{staticStyle:{"border":"0px","font-weight":"400 !important","font-size":"14px !important"}},[_c('thead',[_c('tr',{staticClass:"table-title"},[_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : '') + " font-weight-black border-title"),attrs:{"scope":"col"}},[_vm._v(" UNIDAD ")]),_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : '') + " font-weight-black border-title"),attrs:{"scope":"col"}},[_vm._v(" TIPO ")]),_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : '') + " font-weight-black border-title"),attrs:{"scope":"col"}},[_vm._v(" PRECIO ("+_vm._s(_vm.currency)+") ")])])]),_c('tbody',[_vm._l((_vm.uniqueAssets.filter(
						function (product) { return product != null; }
					)),function(product,key){return _c('tr',{key:("" + key + (product.type))},[_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : ''))},[_c('div',{staticStyle:{"display":"flex !important","align-items":"center"}},[(_vm.deleteItems && product.masterAsset)?_c('font-awesome-icon',{staticClass:"mr-2",staticStyle:{"color":"#AE0808"},attrs:{"icon":['fal', 'trash-alt']},on:{"click":function($event){_vm.updateMasterAssetsSelected({
										action: 'remove',
										assetId: product.id,
									}),
										_vm.$emit('removeAsset', product.id)}}}):_vm._e(),_c('div',{style:(((_vm.textAlign ? '' : 'width: 100%; text-align:center;') + " " + (_vm.deleteItems && !product.masterAsset
											? 'margin-left: 20px;'
											: '')))},[_vm._v(" "+_vm._s(product.name[0].toUpperCase() + product.name.substring(1).toLowerCase())+" "),(product.discount && product.assetPriceWithDiscount)?_c('div',[_c('p',{style:(("font-size: 14px; font-style: italic; color:" + (_vm.colorName(
												product.discount.color
											))))},[_vm._v(" Precio con descuento ")])]):_vm._e()])],1)]),(!_vm.deleteItems)?_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : ''))},[_vm._v(" "+_vm._s(product.type)+" ")]):_vm._e(),_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " " + (_vm.withoutBorders ? 'without-borders' : ''))},[_c('p',{style:(((product.assetPriceWithDiscount && product.discount
										? 'text-decoration: line-through red 1px; font-size: 14px !important;'
										: '') + "; font-size: 14px !important;"))},[_vm._v(" "+_vm._s(_vm.currency == 'UF' ? ((new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format(product.price)) + " UF") : ("$ " + (new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format(Math.round(product.price * _vm.ufValue)))))+" ")]),(product.assetPriceWithDiscount && product.discount)?_c('div',[_c('p',{style:(("font-style: italic; font-size: 14px !important; color:" + (_vm.colorName(
										product.discount.color
									))))},[_vm._v(" "+_vm._s(_vm.currency == 'UF' ? ((new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format(product.assetPriceWithDiscount)) + " UF") : ("$ " + (new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format( Math.round(product.assetPriceWithDiscount * _vm.ufValue) ))))+" ")])]):_vm._e()])])}),(_vm.showTotal)?_c('tr',{class:(" " + (_vm.withoutBorders ? 'without-borders' : ''))},[_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " font-weight-black"),staticStyle:{"border":"0"}},[_c('b',[_vm._v("TOTAL")])]),(!_vm.deleteItems)?_c('td',{staticStyle:{"border":"0"}}):_vm._e(),_c('td',{class:((_vm.textAlign ? 'text-start' : 'text-center') + " font-weight-black"),staticStyle:{"border":"0"}},[_vm._v(" "+_vm._s(_vm.currency == 'UF' ? ((new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format(_vm.getFinalAssetsAmount)) + " UF") : ("$ " + (new Intl.NumberFormat('es-CL', { maximumFractionDigits: 2, }).format(Math.round(_vm.getFinalAssetsAmount * _vm.ufValue)))))+" ")])]):_vm._e()],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }