<template>
	<div v-if="buildingImage">
		<transition name="fade" mode="out-in">
			<div v-if="step == 1" key="select-building">
				<v-card background-color="primary" dark class="border-bottom-0">
					<v-card-title class="text-h5">
						{{ projectData.projectName }}
					</v-card-title>
					<v-card-subtitle>{{ projectData.address }}</v-card-subtitle>
				</v-card>
				<div class="p-relative overflow-hidden">
					<v-btn
						v-show="!showMenu"
						dark
						class="navigation btn"
						background-color="primary"
						@click="showMenu = !showMenu"
					>
						<font-awesome-icon :icon="['fas', 'chevron-double-down']" />
					</v-btn>
					<div v-show="showMenu" class="navigation">
						<v-btn-toggle dense background-color="primary" dark multiple>
							<v-btn id="zoom-out">
								<font-awesome-icon :icon="['fas', 'search-minus']" />
							</v-btn>
							<v-btn id="zoom-reset">REINICIAR</v-btn>
							<v-btn id="zoom-in">
								<font-awesome-icon :icon="['fas', 'search-plus']" />
							</v-btn>
							<v-btn @click="showMenu = !showMenu">
								<font-awesome-icon :icon="['fas', 'chevron-double-up']" />
							</v-btn>
						</v-btn-toggle>
					</div>
					<v-navigation-drawer v-model="drawer" absolute permament>
						<v-card background-color="primary" dark class="border-bottom-0">
							<v-list dense outlined>
								<v-list-item-group color="primary">
									<v-list-item
										class="text-right"
										@click.stop="drawer = !drawer"
									>
										<v-list-item-content>
											<v-list-item-title class="white--text"
												>X</v-list-item-title
											>
										</v-list-item-content>
									</v-list-item>
									<v-list-item
										v-for="(floor, i) in floors"
										:key="i"
										:disabled="floor.disabled"
										@mouseover="floor.disabled ? null : showFloor(floor)"
										@mouseleave="floor.disabled ? null : showFloor(null)"
										@click="selectFloor(floor.id)"
									>
										<v-list-item-content>
											<v-list-item-title
												v-text="floor.name"
											></v-list-item-title>
											<v-list-item-subtitle
												v-text="`${floor.value} BIENES`"
											></v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card>
					</v-navigation-drawer>
					<div id="building-container">
						<inline-svg
							id="building-image"
							:src="buildingImage"
							@loaded="setSvgTriggers()"
						/>
						<v-btn
							class="floors-btn"
							x-large
							absolute
							@click.stop="drawer = !drawer"
						>
							VER PISOS
						</v-btn>
					</div>
				</div>
			</div>
			<div v-else-if="step == 2" key="select-floor">
				<v-card background-color="primary" dark class="border-bottom-0">
					<div class="d-flex flex-no-wrap justify-space-between">
						<div>
							<v-card-title class="text-h5">
								Piso {{ selectedFloor }}
							</v-card-title>
							<v-card-subtitle>{{ projectData.projectName }}</v-card-subtitle>
						</div>
						<div>
							<v-card-title class="text-h5">
								<v-btn light background-color="primary" @click="step = 1">
									VOLVER
								</v-btn>
							</v-card-title>
						</div>
					</div>
				</v-card>
				<div :style="buildingUrl" class="p-relative overflow-hidden">
					<v-btn
						v-show="!showMenu"
						dark
						class="navigation btn"
						background-color="primary"
						@click="showMenu = !showMenu"
					>
						<font-awesome-icon :icon="['fas', 'chevron-double-down']" />
					</v-btn>
					<div v-show="showMenu" class="navigation">
						<v-btn-toggle dense background-color="primary" dark multiple>
							<v-btn id="zoom-out">
								<font-awesome-icon :icon="['fas', 'search-minus']" />
							</v-btn>
							<v-btn id="zoom-reset">REINICIAR</v-btn>
							<v-btn id="zoom-in">
								<font-awesome-icon :icon="['fas', 'search-plus']" />
							</v-btn>
							<v-btn @click="showMenu = !showMenu">
								<font-awesome-icon :icon="['fas', 'chevron-double-up']" />
							</v-btn>
						</v-btn-toggle>
						<v-btn-toggle
							class="navigation-buttons"
							dense
							background-color="primary"
							dark
							multiple
						>
						</v-btn-toggle>
					</div>
					<div id="floor-container" class="building-bg">
						<inline-svg
							id="floor-image"
							:src="floorImage"
							@loaded="setSvgTriggers()"
						/>
					</div>
				</div>
			</div>
			<div v-else-if="step == 3" key="select-form" class="p-relative">
				<v-card background-color="primary" dark class="border-bottom-0">
					<v-card-title class="text-h5">
						{{ getApartmentInfo.title }}
					</v-card-title>
					<v-card-subtitle>{{ projectData.projectName }}</v-card-subtitle>
				</v-card>
				<v-container fluid class="p-relative">
					<v-btn
						v-show="!showMenu"
						light
						class="navigation-last btn"
						background-color="primary"
						@click="showMenu = !showMenu"
					>
						<font-awesome-icon :icon="['fas', 'chevron-double-down']" />
					</v-btn>
					<div v-show="showMenu" class="navigation">
						<v-btn-toggle dense background-color="primary" dark multiple>
							<v-btn dark @click="step = 1">
								CAMBIAR PISO
							</v-btn>
							<v-btn dark @click="step = 2">
								VOLVER
							</v-btn>
							<v-btn @click="showMenu = !showMenu">
								<font-awesome-icon :icon="['fas', 'chevron-double-up']" />
							</v-btn>
						</v-btn-toggle>
					</div>

					<v-row class="mb-1" justify="center">
						<v-col cols="12" sm="12" md="6">
							<image-dialog
								:thumb="`${getApartmentInfo.image}`"
								:full="`${getApartmentInfo.image}`"
								:mobile="
									this.$vuetify.breakpoint.name == 'xs' ||
									this.$vuetify.breakpoint.name == 'sm'
										? true
										: false
								"
								:style="
									` max-height: 400px: max-width:${imgWidth}px ${
										sendingQuote ? '' : 'z-index: 200; position:relative;'
									} `
								"
								:thumb-style="
									'max-height: 200px!important; width: auto!important;'
								"
							></image-dialog>
							<small><i>*Presiona la imagen para verla más grande</i></small>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<table
								:style="
									`background-color:${themeColors.infoAlertColor}; color:${themeColors.infoAlertTextColor};
									width: 100%; border-radius: 5px; height: 100%`
								"
							>
								<tbody>
									<tr>
										<th class="text-h5" colspan="3">
											Datos del bien a cotizar
										</th>
									</tr>
									<tr>
										<th class="text-center" style="height: 48px; width: 33%">
											<font-awesome-icon :icon="['fal', 'bed-alt']" />
											{{ getApartmentInfo.rooms }}
										</th>
										<th class="text-center" style="height: 48px; width: 33%">
											<font-awesome-icon :icon="['fal', 'bath']" />
											{{ getApartmentInfo.bathrooms }}
										</th>
										<th class="text-center" style="height: 48px; width: 33%">
											<font-awesome-icon :icon="['fal', 'ruler-horizontal']" />
											{{
												new Intl.NumberFormat('es-CL', {
													maximumFractionDigits: 2,
												}).format(getApartmentInfo.surface)
											}}
											m<sup>2</sup>
											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
													<v-avatar class="ml-2" color="#A1A4A1" size="20">
														<span
															v-bind="attrs"
															class="white--text"
															style="font-size: 15px;"
															v-on="on"
															>?</span
														>
													</v-avatar>
												</template>
												<span>
													<template v-if="getApartmentInfo.surface">
														{{ surface_text }} Total:
														{{
															new Intl.NumberFormat('es-CL', {
																maximumFractionDigits: 2,
															}).format(getApartmentInfo.surface)
														}}
														m<sup>2</sup>
														<br />
													</template>
													<template v-if="getApartmentInfo.util">
														{{ surface_text }} Útil:
														{{
															new Intl.NumberFormat('es-CL', {
																maximumFractionDigits: 2,
															}).format(getApartmentInfo.util)
														}}
														m<sup>2</sup> <br />
													</template>
													<template
														v-if="
															getApartmentInfo.terrain != null &&
																getApartmentInfo.terrain != '0' &&
																getApartmentInfo.assetType == 'CASA'
														"
													>
														Terreno:
														{{
															new Intl.NumberFormat('es-CL', {
																maximumFractionDigits: 2,
															}).format(getApartmentInfo.terrain)
														}}
														m<sup>2</sup>
														<br />
													</template>

													<template
														v-else-if="
															getApartmentInfo.terrace != null &&
																getApartmentInfo.terrace != '0'
														"
													>
														Terrazas:
														{{
															new Intl.NumberFormat('es-CL', {
																maximumFractionDigits: 2,
															}).format(getApartmentInfo.terrace)
														}}
														m<sup>2</sup>
														<br />
													</template>
												</span>
											</v-tooltip>
										</th>
									</tr>
									<tr>
										<th class="text-center" style="height: 48px; width: 33%">
											<font-awesome-icon :icon="['fal', 'location-arrow']" />
											{{ getApartmentInfo.orientation }}
										</th>
										<th class="text-center" style="height: 48px; width: 33%">
											TIPO: {{ getApartmentInfo.type }}
										</th>
										<th class="text-center" style="height: 48px; width: 33%">
											PISO: {{ getApartmentInfo.floor }}
										</th>
									</tr>
								</tbody>
							</table>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12" sm="12" md="6">
							<v-row
								v-if="
									projectData.warehouseService || projectData.parkingService
								"
								class="mt-2"
								no-gutters
							>
								<v-col>
									<p
										v-if="
											getApartmentInfo.associationStatus != 'BIEN_DESVINCULADO'
										"
										class="text-body-1"
									>
										<span
											v-if="
												projectData.warehouseService &&
													projectData.parkingService
											"
											class="text-body-1 mb-1"
										>
											<v-avatar
												class="mr-1"
												:color="themeColors.color3"
												size="24"
											>
												<span
													:style="
														`font-size: 15px; color: ${themeColors.buttonTextColor}`
													"
												>
													<font-awesome-icon :icon="['fas', 'chevron-right']" />
												</span>
											</v-avatar>
											Agrega un Estacionamiento y/o Bodega
										</span>
										<span
											v-else-if="
												!projectData.warehouseService &&
													projectData.parkingService
											"
											class="mr-2"
											>Seleccionar Estacionamiento</span
										>
										<span v-else class="mr-2">Seleccionar Bodega</span>
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-avatar class="mr-2" color="#A1A4A1" size="20">
													<span
														v-bind="attrs"
														class="white--text"
														style="font-size: 15px;"
														v-on="on"
														>?</span
													>
												</v-avatar>
											</template>
											<span
												>Los bienes que son agregados automáticamente son bienes
												conjuntos, no se pueden vender por separado</span
											>
										</v-tooltip>
									</p>
									<div class="text-center">
										<select-sub-assets ref="subAssets" class="mt-1" />
									</div>
								</v-col>
							</v-row>
							<v-row v-if="!hideSelectedAssets">
								<v-col>
									<p class="text-body-1 mt-5">
										<v-avatar
											class="mr-2"
											:color="themeColors.color3"
											size="24"
										>
											<span
												class="white--text"
												:style="
													`font-size: 15px; color: ${themeColors.buttonTextColor}`
												"
											>
												<font-awesome-icon :icon="['fas', 'chevron-right']" />
											</span>
										</v-avatar>
										Bienes cotizados
									</p>
								</v-col>
							</v-row>
							<v-row
								v-if="!hideSelectedAssets"
								class="mb-5 px-1"
								align="center"
								justify="center"
								no-gutters
							>
								<v-col>
									<table-sub-assets :show-total="true" :from-mainview="true" />
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<p class="text-body-1">
								<v-avatar class="mr-2" :color="themeColors.color3" size="23">
									<span
										:style="
											`font-size: 15px; color: ${themeColors.buttonTextColor}`
										"
									>
										<font-awesome-icon :icon="['fas', 'chevron-right']" />
									</span>
								</v-avatar>
								Ingresa tus datos de contacto
							</p>
							<quote-form ref="formRef" class="mt-2" />
							<v-row no-gutters class="mt-1">
								<v-col>
									<p
										v-if="quoteLimit != null && quoteLimit <= 0"
										style="color: red"
									>
										Ha excedido el número de cotizaciones permitidas por día.
									</p>
									<v-btn
										id="moby-quote-button"
										:color="themeColors.color3"
										x-large
										block
										class="mt-2"
										:style="
											`color: ${
												quoteLimit != null && quoteLimit <= 0
													? '#000'
													: themeColors.buttonTextColor
											}; font-size: 16px`
										"
										:disabled="
											quoteLimit != null && quoteLimit <= 0 ? true : false
										"
										@click="$refs.formRef.submitForm()"
									>
										{{ textButton }}
									</v-btn>
								</v-col>
								<v-col
									v-if="getOpportunityStatus && !reservation"
									cols="12"
									xs="12"
									sm="6"
								>
									<v-btn
										id="moby-reservation-button"
										:color="themeColors.color3"
										x-large
										block
										class="mt-2 ml-sm-2"
										:style="
											`color: ${themeColors.buttonTextColor}; font-size: 16px`
										"
										@click="$refs.formRef.submitForm(true)"
									>
										Reservar
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import texts from '../mixins/TemplateTexts'
import SelectSubAssets from '@/components/SelectSubAssets.vue'
import TableSubAssets from '@/components/TableSubAssets.vue'
import ImageDialog from '@/components/ImageDialog.vue'
export default {
	name: 'Filtro',
	components: {
		QuoteForm,
		SelectSubAssets,
		TableSubAssets,
		ImageDialog,
	},
	mixins: [texts],
	data() {
		return {
			step: 1,
			showMenu: false,
			assets: null,
			selectedFloor: null,
			filteredAssets: null,
			selectedAsset: null,
			buildingImage: null,
			floorImage: null,
			floorImages: null,
			floors: [],
			drawer: false,
		}
	},
	computed: {
		...mapState('form', ['countryCode', 'sendingQuote']),
		...mapState('maps', ['useMap', 'useBuildingMap']),
		...mapState('general', [
			'projectData',
			'quoteLimit',
			'reservation',
			'hideSelectedAssets',
		]),
		...mapGetters('assets', [
			'getApartmentInfo',
			'getOpportunityStatus',
			'getAssets',
		]),
		...mapGetters('maps', ['getAssetMaps', 'getBuildingMaps']),
		...mapState('theme', ['themeColors']),
		imgWidth() {
			return this.$vuetify.breakpoint.name == 'xs' ? '90%' : 580
		},
		textColor() {
			return {
				'--text-color': this.themeColors.selectTextColor,
			}
		},
		buildingUrl() {
			return {
				'--url-building': `url('${this.buildingImage}')`,
			}
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
	},
	watch: {
		step: {
			handler: function() {
				this.showMenu = false
			},
			immediate: true,
		},
		getBuildingMaps: {
			handler: function() {
				if (this.getBuildingMaps) {
					this.buildingImage = this.getBuildingMaps[0].value_url
					this.floorImages = this.getBuildingMaps[0].floor_maps
				}
			},
		},
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
		this.assets = this.getAssets
		this.fetchBuildingMaps()
	},
	methods: {
		...mapActions('neourbe', ['triangularSelection']),
		...mapMutations('assets', ['setApartmentId']),
		...mapActions('maps', ['fetchBuildingMaps']),
		resetSubAssetsInitialData(data) {
			if (
				data == 'apartment' &&
				this.getApartmentInfo.associationStatus != null
			)
				this.$refs.subAssets.resetInitialData()
		},
		setZoomControls(svgDoc) {
			if (svgDoc) {
				const panzoom = this.$panzoom(svgDoc, {
					maxScale: 10,
					minScale: 1,
				})
				document
					.getElementById('zoom-in')
					.addEventListener('click', panzoom.zoomIn)
				document
					.getElementById('zoom-out')
					.addEventListener('click', panzoom.zoomOut)
				document
					.getElementById('zoom-reset')
					.addEventListener('click', panzoom.reset)
				svgDoc.parentElement.addEventListener('wheel', function(event) {
					panzoom.zoomWithWheel(event)
				})
			}
		},
		setSvgTriggers() {
			if (this.assets) {
				let self = this
				self.showSvg = true
				if (this.step == 1) {
					let svgDoc = document.getElementById('building-image')
					let elements =
						svgDoc.querySelectorAll('#floor-elements g').length == 0
							? svgDoc.querySelector('#floor-elements').children
							: svgDoc.querySelectorAll('#floor-elements g')
					this.filteredAssets = null
					this.selectedAsset = null
					this.setZoomControls(svgDoc)
					//let assetElement = null;
					this.floors = []
					elements.forEach(function(asset) {
						asset.id = asset.id.replace('_', '')
						let cleanId = asset.id.replace('PISO', '')
						let path =
							typeof asset.getElementsByTagName('rect')[0] == 'undefined'
								? asset.getElementsByTagName('path')[0]
								: asset.getElementsByTagName('rect')[0]
						path = typeof path == 'undefined' ? asset : path
						path.style = ''
						if (
							self.assets.find(curAsset => curAsset.piso == cleanId) &&
							self.floorImages.find(
								floorImage =>
									floorImage.floor.split(',').includes(cleanId) ||
									floorImage.floor == ''
							)
						) {
							path.style.fill = 'rgb(173, 216, 230)'
							path.style.opacity = '0'
							asset.style.cursor = 'pointer'
							asset.classList.add('selectable')
							asset.addEventListener('click', function() {
								self.selectFloor(asset.id.replace('PISO', ''))
							})
							self.floors.push({
								name: `PISO ${cleanId}`,
								id: cleanId,
								value: self.assets.filter(curAsset => curAsset.piso == cleanId)
									.length,
								disabled: false,
							})
							/*if(asset.id == 'PISO19'){
								self.selectedFloor = asset.id.replace('PISO', '')
								self.selectFloor(asset)
							}*/
						} else {
							path.style.fill = '#222222'
							path.style.opacity = '.5'
							asset.style.cursor = 'not-allowed'
							self.floors.push({
								name: `PISO ${cleanId}`,
								id: cleanId,
								value: 0,
								disabled: true,
							})
						}
					})
					this.floors = this.floors.reverse()
				} else if (self.step == 2) {
					let svgDoc = document.getElementById('floor-image')
					let elements =
						svgDoc.querySelectorAll('#floor-elements g').length == 0
							? svgDoc.querySelector('#floor-elements').children
							: svgDoc.querySelectorAll('#floor-elements g')
					this.selectedAsset = null
					elements.forEach(function(asset) {
						asset.id = asset.getAttribute('serif:id')
							? asset.getAttribute('serif:id')
							: asset.id.replace('_', '')
						let path =
							typeof asset.getElementsByTagName('rect')[0] == 'undefined'
								? asset.getElementsByTagName('path')[0]
								: asset.getElementsByTagName('rect')[0]
						path = typeof path == 'undefined' ? asset : path
						path.style = ''
						path.setAttribute('data-type', asset.id.replace('TIPO', ''))
						let filteredAsset = self.filteredAssets.find(curAsset =>
							curAsset.tipo.includes(path.getAttribute('data-type'))
						)
						let text = null
						try {
							let textElements = svgDoc.getElementById('#text-elements')
							text = textElements.querySelector(
								'[serif:id="TEXTO' + path.getAttribute('data-type') + '"]'
							)
								? textElements.querySelector(
										'[serif:id="TEXTO' + path.getAttribute('data-type') + '"]'
								  )
								: textElements.querySelector(
										`#TEXTO${path.getAttribute('data-type')}`
								  )
						} catch (e) {
							console.log(e)
							text = null
						}
						if (filteredAsset) {
							path.setAttribute('data-id', filteredAsset.id)
							path.style.fill = 'rgb(173, 216, 230)'
							path.style.opacity = '0'
							path.style.cursor = 'pointer'
							path.classList.add('selectable')
							if (text) {
								text.innerHTML = filteredAsset.numeroDeBien
								text.style.textAligment = 'center'
							}
							path.addEventListener('click', function() {
								self.setApartment(path)
							})
						} else {
							path.style.fill = '#DDDDDD'
							path.style.opacity = '.5'
							asset.style.cursor = 'not-allowed'
							if (text) {
								text.style.opacity = '0'
							}
						}
					})
				}
			}
		},
		showFloor(floor) {
			let svgDoc = document.getElementById('building-image')
			let elements =
				svgDoc.querySelectorAll('#floor-elements g').length == 0
					? svgDoc.querySelector('#floor-elements').children
					: svgDoc.querySelectorAll('#floor-elements g')
			elements.forEach(function(asset) {
				let path =
					typeof asset.getElementsByTagName('rect')[0] == 'undefined'
						? asset.getElementsByTagName('path')[0]
						: asset.getElementsByTagName('rect')[0]
				path = typeof path == 'undefined' ? asset : path
				if (asset.style.cursor !== 'not-allowed') {
					if (floor) {
						path.style.opacity = asset.id == `PISO${floor.id}` ? '.5' : '0'
					} else {
						path.style.opacity = '0'
					}
				}
			})
		},
		selectFloor(floorId) {
			this.selectedFloor = floorId
			this.filteredAssets = this.assets.filter(
				curAsset => curAsset.piso == floorId
			)
			let floorImage = this.floorImages.find(floorImage =>
				floorImage.floor.split(',').includes(floorId)
			)
			if (floorImage) {
				this.floorImage = floorImage.value_url
			} else {
				this.floorImage = this.floorImages.find(
					floorImage => floorImage.floor == ''
				).value_url
			}
			this.showMenu = false
			this.drawer = false
			this.step = 2
		},
		setApartment(asset) {
			this.selectedAsset = this.filteredAssets.find(
				curAsset => curAsset.id == asset.getAttribute('data-id')
			)
			this.setApartmentId(this.selectedAsset.id)
			let value = this.selectedAsset.id
			this.triangularSelection({ property: 'apartment', value })
			this.showMenu = false
			this.step = 3
		},
	},
}
</script>
<style lang="scss">
mobysuite-quote {
	.navigation {
		position: absolute !important;
		top: -5px;
		right: 10px;
		transition: 0.5s;
		z-index: 100;
		border-top-right-radius: 0px !important;
		border-top-left-radius: 0px !important;
	}
	.navigation-last {
		position: absolute !important;
		top: -60px;
		right: 10px;
		transition: 0.5s;
		z-index: 100;
	}
	.navigation:hover {
		top: 0px;
	}
	.navigation-buttons {
		position: absolute !important;
		top: 50px;
		right: 0px;
	}
	.navigation-buttons-2 {
		position: absolute !important;
		top: 90px;
		right: 0px;
	}
	#floor-elements .selectable {
		transition: 0.5s;
	}
	#floor-elements .selectable:hover {
		opacity: 0.5 !important;
	}
	.building-bg {
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
			var(--url-building);
		background-position: center top;
		background-size: cover;
	}
	.fade-enter-active {
		transition: all 0.3s ease;
	}
	.fade-leave-active {
		transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
	}
	.fade-enter,
	.fade-leave-to,
	.fade-leave-active {
		transform: translateX(10px);
		opacity: 0;
	}
	.p-relative {
		position: relative;
	}
	.overflow-hidden {
		overflow: hidden;
	}
	.border-bottom-0 {
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
	}
	.floors-btn {
		left: 0px;
		top: 20px;
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
}
</style>
