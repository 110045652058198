<template>
	<v-stepper
		v-model="step"
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2}`
		"
		class="elevation-0"
	>
		<v-stepper-header class="elevation-0">
			<banner
				:background-color="themeColors.color1"
				:text-color="themeColors.color2"
			/>
		</v-stepper-header>

		<v-stepper-items>
			<v-stepper-content step="1">
				<v-card
					:style="
						`background-color:${themeColors.color1}; color:${themeColors.color2}`
					"
					flat
				>
					<v-card-title>
						<v-row justify="center">
							<v-col
								cols="12"
								sm="6"
								class="py-2"
								justify="center"
								align="center"
							>
								<p class="text-center" style="text-size: 10px;">
									<v-avatar class="mr-2" :color="themeColors.color3" size="23">
										<span
											:style="
												`font-size: 15px; color: ${themeColors.buttonTextColor}`
											"
											>{{ updateStepNumber(1) }}</span
										>
									</v-avatar>
									Seleccionar dormitorios
								</p>
								<v-btn-toggle rounded>
									<v-btn
										v-for="amount in getRoomsAmounts"
										:key="amount"
										:color="themeColors.color3"
										:style="
											`color: ${
												quoteLimit != null && quoteLimit <= 0
													? '#000'
													: themeColors.buttonTextColor
											}; font-size: 14px`
										"
										:value="amount"
										@click="
											setCheaperApartment(amount)
											model = 0
											getObjectList()
										"
									>
										{{ setAmountText(amount) }}
									</v-btn>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text :style="`background-color: ${themeColors.color1}`">
						<v-row>
							<v-col>
								<v-row
									v-if="
										this.$vuetify.breakpoint.name == 'sm' ||
											this.$vuetify.breakpoint.name == 'xs'
									"
									justify="center"
									align="center"
								>
									<v-col
										justify="center"
										align="center"
										cols="2"
										:style="`background-color: ${themeColors.color1}`"
									>
										<v-btn
											fab
											max-height="32px"
											max-width="32px"
											class="elevation-0"
											dark
											:color="themeColors.color3"
											@click="
												if (model == 0) {
													model =
														getApartmentsByRoomsAmount(selectedRoomsAmount)
															.length - 1
												} else {
													model = model - 1
												}
											"
										>
											<font-awesome-icon
												style="font-size: 30px;"
												:icon="['fas', 'angle-left']"
											/>
										</v-btn>
									</v-col>
									<v-col cols="8">
										<v-carousel
											v-model="model"
											:show-arrows="false"
											hide-delimiters
											class="mt-2"
											:height="height"
										>
											<v-carousel-item
												v-for="apartment in getApartmentsByRoomsAmount(
													selectedRoomsAmount
												)"
												:key="apartment.id"
											>
												<v-sheet
													:color="themeColors.color1"
													:style="
														`color: ${themeColors.color2}; background-color: ${themeColors.color1}`
													"
												>
													<v-row
														align="center"
														justify="center"
														:style="
															`color: ${themeColors.color2}; background-color: ${themeColors.color1} !important;`
														"
													>
														<apartment-info-fai
															:apartment-info="apartment"
															@updateApartmentId="localUpdateApartmentId"
														/>
													</v-row>
												</v-sheet>
											</v-carousel-item>
										</v-carousel>
									</v-col>
									<v-col justify="center" align="center" cols="2">
										<v-btn
											fab
											max-height="32px"
											max-width="32px"
											class="elevation-0"
											dark
											:color="themeColors.color3"
											@click="
												if (
													model ==
													getApartmentsByRoomsAmount(selectedRoomsAmount)
														.length -
														1
												) {
													model = 0
												} else {
													model = model + 1
												}
											"
										>
											<font-awesome-icon
												style="font-size: 30px;"
												:icon="['fas', 'angle-right']"
											/>
										</v-btn>
									</v-col>
								</v-row>
								<v-row v-else>
									<v-carousel
										v-model="model"
										:show-arrows="false"
										hide-delimiters
										class="mt-2"
										:height="height"
										:vertical="true"
									>
										<v-carousel-item
											v-for="items in apartments"
											:key="items.item"
										>
											<v-sheet :color="themeColors.color1">
												<v-row
													align="center"
													justify="center"
													:style="
														`color: ${themeColors.color2}; background-color: ${themeColors.color1}`
													"
												>
													<apartment-info-fai
														v-for="item in items"
														:key="item.id"
														:apartment-info="item"
														@updateApartmentId="localUpdateApartmentId"
													/>
												</v-row>
											</v-sheet>
										</v-carousel-item>
									</v-carousel>
									<v-row justify="center">
										<v-btn
											fab
											max-height="32px"
											max-width="32px"
											class="elevation-0 ma-3"
											dark
											:color="themeColors.color3"
											@click="
												if (
													model ==
													getApartmentsByRoomsAmount(selectedRoomsAmount)
														.length -
														1
												) {
													model = 0
												} else {
													model = model + 1
												}
											"
										>
											<font-awesome-icon
												style="font-size: 30px;"
												:icon="['fas', 'angle-up']"
											/>
										</v-btn>
										<v-btn
											fab
											max-height="32px"
											max-width="32px"
											class="elevation-0 ma-3"
											dark
											:color="themeColors.color3"
											@click="
												if (
													model ==
													getApartmentsByRoomsAmount(selectedRoomsAmount)
														.length -
														1
												) {
													model = 0
												} else {
													model = model + 1
												}
											"
										>
											<font-awesome-icon
												style="font-size: 30px;"
												:icon="['fas', 'angle-down']"
											/>
										</v-btn>
									</v-row>
								</v-row>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								xs="12"
								md="7"
								lg="7"
								justify="center"
								align="center"
								class="d-flex flex-column justify-space-between"
							>
								<image-dialog
									:thumb="`${getApartmentInfo.image}`"
									:full="`${getApartmentInfo.image}`"
									:mobile="
										this.$vuetify.breakpoint.name == 'xs' ||
										this.$vuetify.breakpoint.name == 'sm'
											? true
											: false
									"
									style="position:relative; max-height: 400, max-width: 500px;"
								></image-dialog>
								<v-btn
									class="mt-2"
									height="60px"
									max-height="60px"
									min-width="80%"
									block
									:color="themeColors.color3"
									:style="
										`color: ${
											quoteLimit != null && quoteLimit <= 0
												? '#000'
												: themeColors.buttonTextColor
										}; font-size: 18px`
									"
									@click="step = 2"
								>
									Continuar
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-stepper-content>
			<v-stepper-content align="center" step="2">
				<v-card
					:style="
						this.$vuetify.breakpoint.name == 'sm' ||
						this.$vuetify.breakpoint.name == 'xs'
							? `background-color:${themeColors.color1}; color:${themeColors.color2}; text-align: initial`
							: `background-color:${themeColors.color1}; color:${themeColors.color2}; max-width: 80%; text-align: initial`
					"
					flat
					class="mb-12"
				>
					<h2>Datos de contacto</h2>
					<quote-form
						ref="formRef"
						class="mt-2"
						:text-field-outlined="true"
						:select-outlined="true"
					/>
					<v-row>
						<v-col>
							<p
								v-if="quoteLimit != null && quoteLimit <= 0"
								style="color: red"
							>
								Ha excedido el número de cotizaciones permitidas por día.
							</p>
							<v-btn
								id="moby-quote-button"
								height="60px"
								max-height="60px"
								block
								class="mt-2"
								:color="themeColors.color3"
								:style="
									`color: ${
										quoteLimit != null && quoteLimit <= 0
											? '#000'
											: themeColors.buttonTextColor
									}; font-size: 18px`
								"
								:disabled="quoteLimit != null && quoteLimit <= 0 ? true : false"
								@click="$refs.formRef.submitForm()"
							>
								{{ textButton }}
							</v-btn>
						</v-col>
						<v-col
							v-if="getOpportunityStatus && !reservation"
							cols="12"
							xs="12"
							sm="6"
						>
							<v-btn
								id="moby-reservation-button"
								:color="themeColors.color3"
								x-large
								height="60px"
								max-height="60px"
								block
								class="mt-2 ml-sm-2"
								:style="
									`color: ${themeColors.buttonTextColor}; font-size: 16px`
								"
								@click="$refs.formRef.submitForm(true)"
							>
								Reservar
							</v-btn>
						</v-col>
					</v-row>
				</v-card>
				<v-row align="center">
					<v-btn
						fab
						max-height="32px"
						max-width="32px"
						class="elevation-0 ml-3 mb-3"
						dark
						:color="themeColors.color3"
						style="padding: 0px !important"
						@click="step = 1"
					>
						<font-awesome-icon
							style="font-size: 30px;"
							:icon="['fas', 'angle-left']"
						/>
					</v-btn>
					<p class="ml-2">Seleccionar otro bien</p>
				</v-row>
			</v-stepper-content>
		</v-stepper-items>
	</v-stepper>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import ApartmentInfoFai from '@/components/ApartmentInfoFai.vue'
import Banner from '@/components/Banner.vue'
import ImageDialog from '@/components/ImageDialog.vue'
let stepNumber = 1
export default {
	name: 'Carrusel',
	components: {
		QuoteForm,
		ApartmentInfoFai,
		Banner,
		ImageDialog,
	},
	data() {
		return {
			step: 1,
			selectedRoomsAmount: null,
			model: 0,
			apartments: null,
		}
	},
	computed: {
		...mapState('assets', ['z', 'propertyNames']),
		...mapGetters('assets', [
			'getRoomsAmounts',
			'getApartmentInfo',
			'getOpportunityStatus',
		]),
		...mapGetters('fai', [
			'getCheaperApartmentByRoomsAmount',
			'getApartmentsByRoomsAmount',
		]),
		...mapState('theme', ['themeColors']),
		...mapState('general', ['quoteLimit', 'reservation', 'realEstateLogoUrl']),
		height() {
			var h = 0
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					h = 250
					break
				case 'sm':
					h = 150
					break
				default:
					h = 420
			}
			return h
		},
		logoHeight() {
			var h = 0
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					h = 120
					break
				case 'sm':
					h = 120
					break
				default:
					h = 120
			}
			return h
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
	},
	created() {
		this.selectedRoomsAmount = this.getRoomsAmounts[0]
		this.setCheaperApartment(this.selectedRoomsAmount)
		this.getObjectList()
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
	},
	methods: {
		...mapMutations('assets', [
			'setApartmentId',
			'setAssetMasterType',
			'setOpportunityStatus',
			'setAssociatedStatus',
			'setAssociatedLimits',
		]),
		...mapActions('assets', ['resetSecondaryAssetsToQuote']),
		setCheaperApartment(roomsAmount) {
			this.selectedRoomsAmount = roomsAmount
			const apartment = this.getCheaperApartmentByRoomsAmount(roomsAmount)
			this.setApartmentId(apartment[this.propertyNames.id])
			this.setAssetMasterType(this.getApartmentInfo.assetType)
			if (this.getApartmentInfo.discount) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'GRUPO_BIENES'
				)
					this.setOpportunityStatus(true)
				else this.setOpportunityStatus(false)
			} else {
				this.setOpportunityStatus(false)
			}
			if (this.getApartmentInfo.associationStatus) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_DESVINCULADO'
				)
					this.setAssociatedStatus('BIEN_DESVINCULADO')
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_ASOCIADO'
				) {
					this.setAssociatedStatus('BIEN_ASOCIADO')
					const obj = {
						max: this.getApartmentInfo.associationLimits.max,
						min: this.getApartmentInfo.associationLimits.min,
					}
					this.setAssociatedLimits(obj)
				}
			} else {
				this.setAssociatedStatus(null)
			}
		},
		localUpdateApartmentId(id) {
			this.setApartmentId(id)
			this.setAssetMasterType(this.getApartmentInfo.assetType)
			if (
				this.getApartmentInfo.associationStatus == 'BIEN_DESVINCULADO' ||
				this.getApartmentInfo.associationStatus == 'BIEN_ASOCIADO'
			) {
				this.resetSecondaryAssetsToQuote()
			}
			if (this.getApartmentInfo.discount) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'GRUPO_BIENES'
				)
					this.setOpportunityStatus(true)
				else this.setOpportunityStatus(false)
			} else {
				this.setOpportunityStatus(false)
			}
			if (this.getApartmentInfo.associationStatus) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_DESVINCULADO'
				)
					this.setAssociatedStatus('BIEN_DESVINCULADO')
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_ASOCIADO'
				) {
					this.setAssociatedStatus('BIEN_ASOCIADO')
					const obj = {
						max: this.getApartmentInfo.associationLimits.max,
						min: this.getApartmentInfo.associationLimits.min,
					}
					this.setAssociatedLimits(obj)
				}
			} else {
				this.setAssociatedStatus(null)
			}
		},
		getObjectList() {
			const allApartments = this.getApartmentsByRoomsAmount(
				this.selectedRoomsAmount
			)
			const iterations = parseInt(allApartments.length / 3)
			var index = 0
			let objList = []
			let finalList = []
			for (var i = 0; i < iterations; i++, index += 3) {
				objList.push(allApartments[index])
				objList.push(allApartments[index + 1])
				objList.push(allApartments[index + 2])
				finalList.push(objList)
				objList = []
			}
			for (var j = 0; j < allApartments.length % 3; j++, index += 1) {
				objList.push(allApartments[index])
			}
			if (allApartments.length % 3 > 0) {
				finalList.push(objList)
			}
			this.apartments = finalList
		},
		updateStepNumber(num = null) {
			return num ? (stepNumber = num) : (stepNumber += 1)
		},
		setAmountText(amount) {
			return this.$vuetify.breakpoint.name == 'xs' ||
				this.$vuetify.breakpoint.name == 'sm'
				? amount
				: `${amount} Dorm`
		},
	},
}
</script>
