<template>
	<div @click="$emit('updateApartmentId', apartmentInfo.id)">
		<v-row
			style="min-height: 80px !important;"
			class="mt-2"
			align="center"
			:style="`background-color: ${themeColors.color1}`"
		>
			<v-col cols="12" xs="12" sm="6" align="center" justify="end">
				<v-img contain width="140" :src="apartmentInfo.imageURL" />
			</v-col>
			<v-col cols="12" xs="12" sm="6">
				<v-row
					justify="center"
					align="center"
					:style="
						`color: ${themeColors.color2}; background-color: ${themeColors.color1}`
					"
				>
					<v-col cols="12" class="pa-0 text-center text-sm-start">
						<font-awesome-icon :icon="['fal', 'ruler-triangle']" />
						{{
							new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(apartmentInfo.totalArea)
						}}
						m<sup>2</sup>
					</v-col>
					<v-col cols="12" class="pa-0  text-center text-sm-start">
						<font-awesome-icon :icon="['fas', 'bed-alt']" />
						{{ apartmentInfo.roomsAmount }} Dormitorios
					</v-col>
					<v-col cols="12" class="pa-0  text-center text-sm-start">
						<font-awesome-icon :icon="['fas', 'bath']" />
						{{ apartmentInfo.bathroomsAmount }} Baños
					</v-col>
					<v-col
						cols="12"
						class="pa-0 text-center text-sm-start font-weight-black"
					>
						Tipo
						{{ apartmentInfo.type }}
					</v-col>
					<v-col
						v-if="apartmentInfo.discountInfo != null"
						cols="12"
						class="pa-0  text-center text-sm-start"
					>
						<span
							v-if="
								apartmentInfo.discountInfo[propertyNames.discountType] ==
									'GRUPO_BIENES'
							"
							:style="`color:${colorName(apartmentInfo.discountInfo.color)}`"
							v-text="` Reserva hoy con `"
						>
						</span>
						<span
							v-if="
								apartmentInfo.discountInfo[propertyNames.amountType] ==
									'PORCENTAJE'
							"
							:style="`color:${colorName(apartmentInfo.discountInfo.color)}`"
							v-text="
								`descuento ${new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
								}).format(
									apartmentInfo.discountInfo[propertyNames.discountAmount] * 100
								)}%`
							"
						></span>
						<span
							v-if="
								apartmentInfo.discountInfo[propertyNames.amountType] ==
									'CONSTANTE'
							"
							:style="`color:${colorName(apartmentInfo.discountInfo.color)}`"
							v-text="
								`descuento ${new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
								}).format(
									apartmentInfo.discountInfo[propertyNames.discountAmount]
								)} UF`
							"
						></span>
						<span
							v-if="
								apartmentInfo.discountInfo[propertyNames.amountType] == 'MONTO'
							"
							:style="`color:${colorName(apartmentInfo.discountInfo.color)}`"
							v-text="
								`descuento $${new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
								}).format(
									apartmentInfo.discountInfo[propertyNames.discountAmount]
								)}`
							"
						></span>
						<span
							v-if="
								apartmentInfo.discountInfo[propertyNames.amountType] ==
									'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN'
							"
							:style="`color:${colorName(apartmentInfo.discountInfo.color)}`"
							v-text="
								`descuento ${new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
								}).format(
									apartmentInfo[propertyNames.nonAuthorizedDiscount] * 100
								)}%`
							"
						></span>
						<span
							v-if="
								apartmentInfo.discountInfo[propertyNames.amountType] ==
									'MONTO_DESCUENTO_BIEN'
							"
							:style="`color:${colorName(apartmentInfo.discountInfo.color)}`"
							v-text="
								`descuento ${new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
								}).format(
									apartmentInfo[propertyNames.authorizedDiscount] * 100
								)}%`
							"
						></span>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'ApartmentInfoTable',
	props: {
		apartmentInfo: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	computed: {
		...mapState('theme', ['themeColors']),
		...mapState('assets', ['propertyNames']),
	},
	methods: {
		colorName(color) {
			if (color == 'Verde') {
				return 'green'
			} else if (color == 'Rojo') {
				return 'red'
			} else {
				return 'blue'
			}
		},
	},
}
</script>
