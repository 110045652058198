var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('v-row',{attrs:{"no-gutters":_vm.noGutters}},[_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"rut",attrs:{"field-name":"rut","use-validators":['required', _vm.getDNIValidationName]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var customValidation = ref.customValidation;
var touch = ref.touch;
var value = ref.value;
var isInvalid = ref.isInvalid;
return [_c('div',{staticClass:"text-start d-flex align-start"},[_c('v-text-field',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (isInvalid
											? _vm.themeColors.inputColor2
											: _vm.themeColors.validationColor
											? _vm.themeColors.validationColor
											: '#219224') + ";")),attrs:{"background-color":_vm.themeColors.inputColor1,"dense":"","color":isInvalid
										? _vm.themeColors.inputColor2
										: _vm.themeColors.validationColor
										? _vm.themeColors.validationColor
										: '#219224',"outlined":_vm.textFieldOutlined,"solo":_vm.textFieldSolo,"dark":_vm.theme,"label":_vm.getSlang.formData.dniName,"error-messages":errors,"value":value},on:{"input":customValidation,"blur":function($event){return touch()}}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"fName",attrs:{"field-name":"fName","use-validators":['required']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
										var customValidation = ref.customValidation;
										var touch = ref.touch;
										var value = ref.value;
										var isInvalid = ref.isInvalid;
return [_c('v-text-field',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (isInvalid
										? _vm.themeColors.inputColor2
										: _vm.themeColors.validationColor
										? _vm.themeColors.validationColor
										: '#219224') + "; ")),attrs:{"background-color":_vm.themeColors.inputColor1,"color":isInvalid
									? _vm.themeColors.inputColor2
									: _vm.themeColors.validationColor
									? _vm.themeColors.validationColor
									: '#219224',"outlined":_vm.textFieldOutlined,"solo":_vm.textFieldSolo,"dense":"","dark":_vm.theme,"label":"Nombre","error-messages":errors,"value":value},on:{"input":customValidation,"blur":function($event){return touch()}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"lName",attrs:{"field-name":"lName","use-validators":['required']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
									var customValidation = ref.customValidation;
									var touch = ref.touch;
									var value = ref.value;
									var isInvalid = ref.isInvalid;
return [_c('v-text-field',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (isInvalid
										? _vm.themeColors.inputColor2
										: _vm.themeColors.validationColor
										? _vm.themeColors.validationColor
										: '#219224') + "; ")),attrs:{"background-color":_vm.themeColors.inputColor1,"color":isInvalid
									? _vm.themeColors.inputColor2
									: _vm.themeColors.validationColor
									? _vm.themeColors.validationColor
									: '#219224',"outlined":_vm.textFieldOutlined,"solo":_vm.textFieldSolo,"dense":"","dark":_vm.theme,"label":"Apellido","error-messages":errors,"value":value},on:{"input":customValidation,"blur":function($event){return touch()}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"email",attrs:{"field-name":"email","use-validators":['required', 'email']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
									var customValidation = ref.customValidation;
									var touch = ref.touch;
									var value = ref.value;
									var isInvalid = ref.isInvalid;
return [_c('v-text-field',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (isInvalid
										? _vm.themeColors.inputColor2
										: _vm.themeColors.validationColor
										? _vm.themeColors.validationColor
										: '#219224') + "; ")),attrs:{"background-color":_vm.themeColors.inputColor1,"color":isInvalid
									? _vm.themeColors.inputColor2
									: _vm.themeColors.validationColor
									? _vm.themeColors.validationColor
									: '#219224',"outlined":_vm.textFieldOutlined,"solo":_vm.textFieldSolo,"dense":"","dark":_vm.theme,"label":"Correo electrónico","error-messages":errors,"value":value},on:{"input":customValidation,"blur":function($event){return touch()}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"phone",attrs:{"field-name":"phone","use-validators":['required', 'phone']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
									var customValidation = ref.customValidation;
									var touch = ref.touch;
									var value = ref.value;
									var isInvalid = ref.isInvalid;
return [_c('vue-tel-input-vuetify',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(_vm.textColor),attrs:{"background-color":_vm.themeColors.inputColor1,"color":isInvalid
									? _vm.themeColors.inputColor2
									: _vm.themeColors.validationColor
									? _vm.themeColors.validationColor
									: '#219224',"outlined":_vm.textFieldOutlined,"solo":_vm.textFieldSolo,"dense":"","mode":"international","label":"Número telefónico","placeholder":"","default-country":_vm.countryCode,"disabled-fetching-country":true,"error-messages":errors,"value":value,"attach":true,"only-countries":[("" + _vm.countryCode)],"on-change":_vm.setTelInputColor(isInvalid)},on:{"input":customValidation,"blur":function($event){return touch()}}})]}}])})],1),(_vm.optionalFields.ageRange.set)?_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"ageRange",attrs:{"field-name":"ageRange","use-validators":_vm.optionalFields.ageRange.required ? ['required'] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
									var customValidation = ref.customValidation;
									var touch = ref.touch;
									var value = ref.value;
									var isInvalid = ref.isInvalid;
return [_c('input-select',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (_vm.themeColors.inputColor2))),attrs:{"is-invalid":isInvalid || !_vm.optionalFields.ageRange.required,"color":_vm.themeColors.inputColor2,"outlined":_vm.selectOutlined,"solo":_vm.selectSolo,"theme":_vm.theme,"options":_vm.fetchedAges,"label":("Rango de edad " + (_vm.optionalFields.ageRange.required ? '' : '(opcional)')),"error-messages":errors,"custom-validation":customValidation,"touch":touch,"value":value,"attach":true}})]}}],null,false,632785332)})],1):_vm._e(),(_vm.optionalFields.destinationPurchase.set)?_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"destinationPurchase",attrs:{"field-name":"destinationPurchase","use-validators":_vm.optionalFields.destinationPurchase.required ? ['required'] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
								var customValidation = ref.customValidation;
								var touch = ref.touch;
								var value = ref.value;
								var isInvalid = ref.isInvalid;
return [_c('input-select',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (_vm.themeColors.inputColor2))),attrs:{"is-invalid":isInvalid || !_vm.optionalFields.destinationPurchase.required,"color":_vm.themeColors.inputColor2,"outlined":_vm.selectOutlined,"solo":_vm.selectSolo,"theme":_vm.theme,"options":_vm.fetchedPurchaseDestinations,"label":("Destino de compra " + (_vm.optionalFields.destinationPurchase.required
										? ''
										: '(opcional)')),"error-messages":errors,"custom-validation":customValidation,"touch":touch,"value":value,"attach":true}})]}}],null,false,2812227293)})],1):_vm._e(),(_vm.optionalFields.commune.set)?_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"comuna",attrs:{"field-name":"comuna","use-validators":_vm.optionalFields.commune.required ? ['required'] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
								var customValidation = ref.customValidation;
								var touch = ref.touch;
								var value = ref.value;
								var isInvalid = ref.isInvalid;
return [_c('input-select',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (_vm.themeColors.inputColor2))),attrs:{"is-invalid":isInvalid || !_vm.optionalFields.commune.required,"color":_vm.themeColors.inputColor2,"outlined":_vm.selectOutlined,"solo":_vm.selectSolo,"theme":_vm.theme,"options":_vm.fetchedComunas,"label":(_vm.commune_text + " " + (_vm.optionalFields.commune.required ? '' : '(opcional)')),"error-messages":errors,"custom-validation":customValidation,"touch":touch,"value":value,"attach":true}})]}}],null,false,1391129742)})],1):_vm._e(),(_vm.optionalFields.profession.set)?_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"profesion",attrs:{"field-name":"profesion","use-validators":_vm.optionalFields.profession.required ? ['required'] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
								var customValidation = ref.customValidation;
								var touch = ref.touch;
								var value = ref.value;
								var isInvalid = ref.isInvalid;
return [_c('input-select',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (_vm.themeColors.inputColor2))),attrs:{"is-invalid":isInvalid || !_vm.optionalFields.profession.required,"color":_vm.themeColors.inputColor2,"outlined":_vm.selectOutlined,"solo":_vm.selectSolo,"theme":_vm.theme,"options":_vm.fetchedProfessions,"label":("Profesión " + (_vm.optionalFields.profession.required ? '' : '(opcional)')),"error-messages":errors,"custom-validation":customValidation,"touch":touch,"value":value,"attach":true}})]}}],null,false,1326372712)})],1):_vm._e(),(_vm.optionalFields.sex.set)?_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"sex",attrs:{"field-name":"sex","use-validators":_vm.optionalFields.sex.required ? ['required'] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
								var customValidation = ref.customValidation;
								var touch = ref.touch;
								var value = ref.value;
								var isInvalid = ref.isInvalid;
return [_c('input-select',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (_vm.themeColors.inputColor2))),attrs:{"is-invalid":isInvalid || !_vm.optionalFields.sex.required,"color":_vm.themeColors.inputColor2,"outlined":_vm.selectOutlined,"solo":_vm.selectSolo,"theme":_vm.theme,"options":_vm.fetchedSexs,"label":("Sexo " + (_vm.optionalFields.sex.required ? '' : '(opcional)')),"error-messages":errors,"custom-validation":customValidation,"touch":touch,"value":value,"attach":true}})]}}],null,false,1376258721)})],1):_vm._e(),(_vm.optionalFields.rentRange.set)?_c('v-col',{attrs:{"cols":"12","sm":_vm.smSize,"xs":"12"}},[_c('input-validator',{ref:"rentRange",attrs:{"field-name":"rentRange","use-validators":_vm.optionalFields.rentRange.required ? ['required'] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
								var customValidation = ref.customValidation;
								var touch = ref.touch;
								var value = ref.value;
								var isInvalid = ref.isInvalid;
return [_c('input-select',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (_vm.themeColors.inputColor2))),attrs:{"is-invalid":isInvalid || !_vm.optionalFields.rentRange.required,"color":_vm.themeColors.inputColor2,"outlined":_vm.selectOutlined,"solo":_vm.selectSolo,"theme":_vm.theme,"options":_vm.fetchedRentRanges,"label":("Rango de renta " + (_vm.optionalFields.rentRange.required ? '' : '(opcional)')),"error-messages":errors,"custom-validation":customValidation,"touch":touch,"value":value,"attach":true}})]}}],null,false,2723456269)})],1):_vm._e(),(_vm.optionalFields.observations.set)?_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12"}},[_c('input-validator',{ref:"observation",attrs:{"field-name":"observation","use-validators":_vm.optionalFields.observations.required ? ['required'] : []},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var errors = ref.errors;
								var customValidation = ref.customValidation;
								var touch = ref.touch;
								var value = ref.value;
								var isInvalid = ref.isInvalid;
return [_c('v-textarea',{class:{ getInputClasses: true, 'rounded-0': _vm.isSquared },style:(("color:" + (_vm.themeColors.inputColor2))),attrs:{"rows":"2","label":((_vm.customObservationsQuestion
										? _vm.customObservationsQuestion
										: 'Observaciones') + " " + (_vm.optionalFields.observations.required ? '' : '(opcional)')),"background-color":isInvalid || !_vm.optionalFields.observations.required
									? _vm.themeColors.inputColor1
									: '#219224',"color":_vm.themeColors.inputColor2,"outlined":_vm.textFieldOutlined,"solo":_vm.textFieldSolo,"dense":"","error-messages":errors,"value":value},on:{"input":customValidation,"blur":function($event){return touch()}}})]}}],null,false,665370473)})],1):_vm._e(),(_vm.optionalFields.receiveNews.set)?_c('v-switch',{staticClass:"pb-1 ma-0",attrs:{"dark":_vm.theme,"hide-details":"true","dense":"","color":"green lighten-2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{style:(("color:" + (_vm.themeColors.inputColor2)))},[_vm._v("Acepto recibir información y novedades")])]},proxy:true}],null,false,4245855192),model:{value:(_vm.receiveNewsSwitchValue),callback:function ($$v) {_vm.receiveNewsSwitchValue=$$v},expression:"receiveNewsSwitchValue"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }