import Vue from 'vue'

function getFormattedDate(date) {
	var month = ('0' + (date.getMonth() + 1)).slice(-2)
	var day = ('0' + date.getDate()).slice(-2)
	var year = date.getFullYear()
	return year + '-' + month + '-' + day
}

const state = {
	version: 1,
	template: '',
	realEstate: null,
	projectId: null,
	assetIdParam: null,
	realEstateLogoUrl: null,
	showRealEstateLogo: true,
	showProjectLogo: true,
	hideSelectedAssets: false,
	quoteSuccessAndErrorAsDialog: false,
	typology: null,
	roomsAmount: null,
	bathroomsAmount: null,
	description: null,
	projectData: {
		projectName: null,
		address: '',
		webReservation: false,
		webQuoteService: false,
		projectLogoUrl: null,
		projectImageUrl: null,
		warehouseService: false,
		parkingService: false,
		floorService: false,
		orientation: null,
		subTypes: null,
	},
	formSubmissionResponded: false,
	quoteNotification: {
		success: false,
		title: 'Notification dialog title',
		body: 'Notification dialog body',
		pdfURL: 'url',
		reservationURL: '',
		reservationCode: '',
		id: 'quote id',
		name: 'customer name',
		rooms: 0,
		bathrooms: 0,
		orientation: '',
		totalSurface: 0,
		utilSurface: 0,
		terraceSurface: 0,
	},
	vitalDataLoaded: { assets: false, projectData: false },
	atLeastOneVitalResponseFailed: false,
	noAssetsError: false,
	warehousesMaxNumber: 1,
	warehousesMinNumber: 0,
	parkingLotsMaxNumber: 1,
	parkingLotsMinNumber: 0,
	minParkingLotsNumberFulfilled: true,
	minWarehouseNumberFulfilled: true,
	showSecondaryAssetsDialog: false,
	ufValue: 0,
	quoteLimit: null,
	validateSecondaryAssets: {
		warehouse: false,
		parkingLot: false,
	},
	utmData: {
		utmCampaign: null,
		utmContent: null,
		utmMedium: null,
		utmSource: null,
		utmTerm: null,
	},
	reservation: false,
	secondaryAssets: null,
	propertyNames: {
		requiredFields: 'camposRequeridosProductos',
		requiredFieldsProperties: {
			name: 'nombre',
			included: 'incluido',
			required: 'obligatorio',
			product: 'producto',
			text: 'texto',
		},
		parkingLot: 'Estacionamiento',
		warehouse: 'Bodega',
		floor: 'Piso',
		ageRange: 'Rango edad',
		destinationPurchase: 'Destino compra',
		commune: 'Comuna',
		profession: 'Profesion',
		sex: 'Sexo',
		rentRange: 'Rango renta',
	},
	paymentData: {
		status: null,
		reservation: {
			localCurrencyValue: 0.0,
			percentage: 0,
			adjustmentValue: 0.0,
		},
	},
	loadingPaymentPlan: false,
	typologySetByEvent: false,
	assetType: null,
	//Must be an array
	floorFilter: null,
	customProjectLogo: null,
	orientationOnList: false,
}

const mutations = {
	setAssetIdParam: (state, data) => (state.assetIdParam = data),
	setTemplate: (state, data) => (state.template = data),
	setVersion: (state, data) => (state.version = data),
	setDescription: (state, data) => (state.description = data),
	setRealEstate: (state, data) => (state.realEstate = data),
	setShowRealEstateLogo: (state, data) => (state.showRealEstateLogo = data),
	setShowProjectLogo: (state, data) => (state.showProjectLogo = data),
	setRealEstateLogo: (state, data) =>
		(state.realEstateLogoUrl = state.showRealEstateLogo ? data : null),
	setProjectId: (state, data) => (state.projectId = data),
	setQuoteNotification: (state, data) => (state.quoteNotification = data),
	setProjectData: (state, data) => (state.projectData = data),
	setQuoteSuccessAndErrorAsDialog: (state, data) =>
		(state.quoteSuccessAndErrorAsDialog = data),
	setVitalDataLoaded: (state, assetName) =>
		(state.vitalDataLoaded[assetName] = true),
	setAtLeastOneVitalResponseFailed: state =>
		(state.atLeastOneVitalResponseFailed = true),
	noAssetsError: state => (state.noAssetsError = true),
	setTypology: (state, value) =>
		(state.typology = value ? value.split(',') : null),
	setRoomsAmount: (state, value) => (state.roomsAmount = value),
	setBathroomsAmount: (state, value) => (state.bathroomsAmount = value),
	setFormSubmissionResponded: (state, value) =>
		(state.formSubmissionResponded = value),
	setWarehousesMaxNumber: (state, data) => (state.warehousesMaxNumber = data),
	setParkingLotsMaxNumber: (state, data) => (state.parkingLotsMaxNumber = data),
	setWarehousesMinNumber: (state, data) => (state.warehousesMinNumber = data),
	setParkingLotsMinNumber: (state, data) => (state.parkingLotsMinNumber = data),
	setMinParkingLotsNumberFulfilled: (state, data) =>
		(state.minParkingLotsNumberFulfilled = data),
	setMinWarehouseNumberFulfilled: (state, data) =>
		(state.minWarehouseNumberFulfilled = data),
	setShowSecondaryAssetsDialog: (state, data) =>
		(state.showSecondaryAssetsDialog = data),
	setQuoteLimit: (state, data) => (state.quoteLimit = data),
	setUtmData: (state, payload) => (state.utmData = payload),
	setReservation: (state, payload) => (state.reservation = payload),
	setSecondaryAssets: (state, payload) => (state.secondaryAssets = payload),
	setHideSelectedAssets: (state, value) => (state.hideSelectedAssets = value),
	setSecondaryAssetsValidation: (state, payload) => {
		state.validateSecondaryAssets[payload.name] = payload.value
	},
	setPaymentData: (state, payload) => (state.paymentData = payload),
	setloadingPaymentPlan: (state, data) => (state.loadingPaymentPlan = data),
	setTypologyByEvent: (state, data) => (state.typologySetByEvent = data),
	setAssetType: (state, data) => (state.assetType = data),
	setFloorFilter: (state, payload) =>
		(state.floorFilter = payload ? payload.split(',') : null),
	setCustomProjectLogo: (state, payload) => (state.customProjectLogo = payload),
	setOrientationOnList: (state, payload) => (state.orientationOnList = payload),
}
const actions = {
	async setUfValue({ state, rootState }) {
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		await Vue.axios
			.get(
				`https://cotizacion.mobysuite.com/api/${version}/opportunities/get_uf`,
				{
					params: {
						customer: state.realEstate,
						date: getFormattedDate(new Date()),
					},
				}
			)
			.then(response => {
				state.ufValue = response.data
			})
	},
	async fetchProjectData({ state, rootState, commit }) {
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		await Vue.axios
			.get(`${version}/quotes/list-projects`, {
				params: {
					real_estate: state.realEstate,
				},
			})
			.then(response => {
				const projectData = response.data.find(
					project => project['id'] == state.projectId
				)

				if (!projectData['activoCotizadorWeb']) {
					commit('setAtLeastOneVitalResponseFailed')
					console.log(
						'[MobySuite Quote] No web quote active, check in the project settings'
					)
				} else {
					if (projectData['urlLogoEmpresa']) {
						commit(
							'setRealEstateLogo',
							state.realEstateLogoUrl
								? state.realEstateLogoUrl
								: projectData['urlLogoEmpresa']
								? `${projectData['urlLogoEmpresaCompleta']}`
								: null
						)
					}
					const quoteOptionalFields = projectData[
						state.propertyNames.requiredFields
					].filter(
						property =>
							property[state.propertyNames.requiredFieldsProperties.product] ===
							'COTIZADOR WEB 2.0'
					)
					// CHANGE REQUESTED BY PAMELA AGUIRRE 16/06/2022. PLEASE CHANGE IT TO COMMENTED FUNCTIONALITY ASAP
					// const wareHouseServiceData = quoteOptionalFields.find(
					// 	serviceField => serviceField['nombre'] == 'Bodega'
					// )
					// const parkingLotServiceData = quoteOptionalFields.find(
					// 	serviceField => serviceField['nombre'] == 'Estacionamiento'
					// )
					const floorServiceData = quoteOptionalFields.find(
						serviceField => serviceField['nombre'] == 'Piso'
					)
					const orientationServiceData = quoteOptionalFields.find(
						serviceField => serviceField['nombre'] == 'Orientación'
					)

					// state.validateSecondaryAssets.warehouse = wareHouseServiceData
					// 	? wareHouseServiceData[
					// 			state.propertyNames.requiredFieldsProperties.required
					// 	  ]
					// 	: false
					// state.validateSecondaryAssets.parkingLot = parkingLotServiceData
					// 	? parkingLotServiceData[
					// 			state.propertyNames.requiredFieldsProperties.required
					// 	  ]
					// 	: false

					state.validateSecondaryAssets.warehouse =
						projectData['bodegasCotizadorWebObligatorio']
					state.validateSecondaryAssets.parkingLot =
						projectData['estacionamientosCotizadorWebObligatorio']
					commit('setProjectData', {
						projectName: projectData['nombreProyecto'],
						address:
							state.version == 1
								? `${projectData['direccion'].direccion}, ${projectData['direccion'].comuna}`
								: `${projectData['direccion'].calle}, ${projectData['direccion'].municipio}`,
						commune: projectData['direccion'].comuna
							? projectData['direccion'].comuna
							: projectData['direccion'].municipio,
						webReservation: projectData['activoReservaWeb'],
						webQuoteService: projectData['activoCotizadorWeb'],
						projectLogoUrl: state.customProjectLogo
							? state.customProjectLogo
							: projectData['urlLogoProyecto']
							? projectData['urlLogoProyectoCompleta']
							: null,
						projectImageUrl: projectData['urlImagenUnoProyecto']
							? `${projectData['urlImagenUnoProyectoCompleta']}`
							: null,
						currencyDenomination: projectData['empresa'][
							'denominacionMonedaPrimaria'
						]
							? `${projectData['empresa']['denominacionMonedaPrimaria']}`
							: 'UF',
						currencyCode: projectData['empresa']['codigoMonedaPrimaria']
							? `${projectData['empresa']['codigoMonedaPrimaria']}`
							: 'UF',
						currencySymbol: projectData['empresa']['simboloMonedaPrimaria']
							? `${projectData['empresa']['simboloMonedaPrimaria']}`
							: 'UF',
						currencySymbolPosition: projectData['empresa'][
							'posicionSimboloMonedaPrimaria'
						]
							? `${projectData['empresa']['posicionSimboloMonedaPrimaria']}`
							: 'DESPUES_CANTIDAD',
						currencyThousandSeparator: projectData['empresa'][
							'separadorMilesMonedaPrincipal'
						]
							? `${projectData['empresa']['separadorMilesMonedaPrincipal']}`
							: '.',
						currencyDecimalSeparator: projectData['empresa'][
							'separadorDecimalMonedaPrincipal'
						]
							? `${projectData['empresa']['separadorDecimalMonedaPrincipal']}`
							: ',',
						currencyUseSecondary: projectData['empresa']['usarMonedaSecundaria']
							? projectData['empresa']['usarMonedaSecundaria']
							: false,
						currencyDenominationSecondary: projectData['empresa'][
							'denominacionMonedaSecundaria'
						]
							? `${projectData['empresa']['denominacionMonedaSecundaria']}`
							: null,
						currencyCodeSecondary: projectData['empresa'][
							'codigoMonedaSecundaria'
						]
							? `${projectData['empresa']['codigoMonedaSecundaria']}`
							: null,
						currencySymbolSecondary: projectData['empresa'][
							'simboloMonedaSecundaria'
						]
							? `${projectData['empresa']['simboloMonedaSecundaria']}`
							: null,
						currencySymbolPositionSecondary: projectData['empresa'][
							'posicionSimboloMonedaSecundaria'
						]
							? `${projectData['empresa']['posicionSimboloMonedaSecundaria']}`
							: null,
						currencyThousandSeparatorSecondary: projectData['empresa'][
							'separadorMilesMonedaSecundaria'
						]
							? `${projectData['empresa']['separadorMilesMonedaSecundaria']}`
							: null,
						currencyDecimalSeparatorSecondary: projectData['empresa'][
							'separadorDecimalMonedaSecundaria'
						]
							? `${projectData['empresa']['separadorDecimalMonedaSecundaria']}`
							: null,
						// warehouseService: wareHouseServiceData
						// 	? wareHouseServiceData[
						// 			state.propertyNames.requiredFieldsProperties.included
						// 	  ]
						// 	: false,
						// parkingService: parkingLotServiceData
						// 	? parkingLotServiceData[
						// 			state.propertyNames.requiredFieldsProperties.included
						// 	  ]
						// 	: false,
						warehouseService: projectData['bodegasCotizadorWeb'],
						parkingService: projectData['estacionamientosCotizadorWeb'],
						floorService: floorServiceData
							? floorServiceData[
									state.propertyNames.requiredFieldsProperties.included
							  ]
							: false,
						orientation: projectData['orientacionCotizadorWeb']
							? projectData['orientacionCotizadorWeb']
							: orientationServiceData
							? orientationServiceData[
									state.propertyNames.requiredFieldsProperties.included
							  ]
							: false,
						subTypes: projectData['subtiposBienes'],
					})
					//Check if any of the posible values is null
					if (
						state.warehousesMaxNumber == null ||
						state.warehousesMinNumber == null ||
						state.parkingLotsMaxNumber == null ||
						state.parkingLotsMinNumber == null
					) {
						// Check if there's any data being received through service
						if (projectData['maximosMinimosBienes']) {
							const wareHousesData = projectData['maximosMinimosBienes'].find(
								property => property['tipoBien'] == 'BODEGA'
							)
							const parkingLotsData = projectData['maximosMinimosBienes'].find(
								property => property['tipoBien'] == 'ESTACIONAMIENTO'
							)
							// Now set warehouses service data if exists
							if (wareHousesData) {
								// set the service warehouses maximum data if exists
								if (wareHousesData.cantidadMaxima) {
									state.warehousesMaxNumber = wareHousesData.cantidadMaxima
								}
								// set the service warehouses minimum data if exists
								if (wareHousesData.cantidadMinima) {
									state.warehousesMinNumber = wareHousesData.cantidadMinima
								}
							}
							//now set parkinglots service data if exists
							if (parkingLotsData) {
								// set the service warehouses maximum data if exists
								if (parkingLotsData.cantidadMaxima) {
									state.parkingLotsMaxNumber = parkingLotsData.cantidadMaxima
								}
								// set the service parkinglots minimum data if exists
								if (parkingLotsData.cantidadMinima) {
									state.parkingLotsMinNumber = parkingLotsData.cantidadMinima
								}
							}
						}
					}

					// Set fields config comming from the service (render and required).

					for (let [key, field] of Object.entries(
						rootState.form.optionalFields
					)) {
						const fieldData = quoteOptionalFields.find(
							serviceField => serviceField['nombre'] == field['serviceName']
						)
						if (rootState.form.optionalFields[key].set === null && fieldData) {
							commit(
								'form/setOptionalField',
								{
									field: key,
									isSet:
										fieldData[
											state.propertyNames.requiredFieldsProperties.included
										],
									isRequired:
										fieldData[
											state.propertyNames.requiredFieldsProperties.required
										],
								},
								{ root: true }
							)
							if (
								key == 'observations' &&
								rootState.form.customObservationsQuestion === null &&
								fieldData[state.propertyNames.requiredFieldsProperties.text]
							) {
								commit(
									'form/setCustomObservationsQuestion',
									fieldData[state.propertyNames.requiredFieldsProperties.text],
									{ root: true }
								)
							}
						}
					}
				}

				commit('setVitalDataLoaded', 'projectData')
			})
			.catch(error => {
				commit('setVitalDataLoaded', 'projectData')
				commit('setAtLeastOneVitalResponseFailed')
				console.log(error)
			})
	},
	async fetchPaymentPlan({ state, rootGetters, commit, rootState }) {
		let assets = []
		if (rootState.assets.permitMultipleMasterAssetSelection) {
			rootState.assets.selectedMasterAssets.map(asset => {
				assets = assets.concat(
					rootGetters['assets/getSelectedApartmentAndSubAssets'](asset)
				)
			})
			assets.concat(rootState.assets.secondaryAssetsToQuote)
		} else
			assets = [
				...rootGetters['assets/getSelectedApartmentAndSubAssets'](),
				...rootState.assets.secondaryAssetsToQuote,
			]
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		await Vue.axios
			.post(`${version}/opportunities/calculate-payment`, {
				customer: state.realEstate,
				discountId: rootGetters['opportunities/getOpportunityDiscount']
					? rootGetters['opportunities/getOpportunityDiscount']['id']
					: null,
				assets: assets,
			})
			.then(response => {
				const reservationData = response.data.find(
					paymentMethod =>
						paymentMethod['formaPago']['descripcionFormaPago'].trim() ==
						'Reserva'
				)
				const payload = {
					status: 'success',
					reservation: {
						localCurrencyValue: reservationData['pagoEnMonedaLocal'],
						percentage: reservationData['pagoEnPorcentaje'],
						adjustmentValue: reservationData['pagoEnValorDeAjuste'],
					},
				}
				commit('setPaymentData', payload)
				commit('setloadingPaymentPlan', false)
			})
			.catch(error => {
				console.log(error)
				const payload = {
					status: 'error',
					reservation: {
						localCurrencyValue: 0.0,
						percentage: 0,
						adjustmentValue: 0.0,
					},
				}
				commit('setPaymentData', payload)
				commit('setloadingPaymentPlan', false)
			})
	},
}

const getters = {
	getPaymentData: state => state.paymentData,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
