<template>
	<div>
		<v-form>
			<v-row :no-gutters="noGutters">
				<v-col cols="12" :sm="smSize" xs="12">
					<input-validator
						ref="rut"
						field-name="rut"
						:use-validators="['required', getDNIValidationName]"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<div class="text-start d-flex align-start">
								<v-text-field
									:background-color="themeColors.inputColor1"
									:class="{ getInputClasses: true, 'rounded-0': isSquared }"
									dense
									:color="
										isInvalid
											? themeColors.inputColor2
											: themeColors.validationColor
											? themeColors.validationColor
											: '#219224'
									"
									:style="
										`color:${
											isInvalid
												? themeColors.inputColor2
												: themeColors.validationColor
												? themeColors.validationColor
												: '#219224'
										};`
									"
									:outlined="textFieldOutlined"
									:solo="textFieldSolo"
									:dark="theme"
									:label="getSlang.formData.dniName"
									:error-messages="errors"
									:value="value"
									@input="customValidation"
									@blur="touch()"
								/>
							</div>
						</template>
					</input-validator>
				</v-col>
				<v-col cols="12" :sm="smSize" xs="12">
					<input-validator
						ref="fName"
						field-name="fName"
						:use-validators="['required']"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<v-text-field
								:background-color="themeColors.inputColor1"
								:color="
									isInvalid
										? themeColors.inputColor2
										: themeColors.validationColor
										? themeColors.validationColor
										: '#219224'
								"
								:style="
									`color:${
										isInvalid
											? themeColors.inputColor2
											: themeColors.validationColor
											? themeColors.validationColor
											: '#219224'
									}; `
								"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:outlined="textFieldOutlined"
								:solo="textFieldSolo"
								dense
								:dark="theme"
								label="Nombre"
								:error-messages="errors"
								:value="value"
								@input="customValidation"
								@blur="touch()"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col cols="12" :sm="smSize" xs="12">
					<input-validator
						ref="lName"
						field-name="lName"
						:use-validators="['required']"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<v-text-field
								:background-color="themeColors.inputColor1"
								:color="
									isInvalid
										? themeColors.inputColor2
										: themeColors.validationColor
										? themeColors.validationColor
										: '#219224'
								"
								:style="
									`color:${
										isInvalid
											? themeColors.inputColor2
											: themeColors.validationColor
											? themeColors.validationColor
											: '#219224'
									}; `
								"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:outlined="textFieldOutlined"
								:solo="textFieldSolo"
								dense
								:dark="theme"
								label="Apellido"
								:error-messages="errors"
								:value="value"
								@input="customValidation"
								@blur="touch()"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col cols="12" :sm="smSize" xs="12">
					<input-validator
						ref="email"
						field-name="email"
						:use-validators="['required', 'email']"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<v-text-field
								:background-color="themeColors.inputColor1"
								:color="
									isInvalid
										? themeColors.inputColor2
										: themeColors.validationColor
										? themeColors.validationColor
										: '#219224'
								"
								:style="
									`color:${
										isInvalid
											? themeColors.inputColor2
											: themeColors.validationColor
											? themeColors.validationColor
											: '#219224'
									}; `
								"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:outlined="textFieldOutlined"
								:solo="textFieldSolo"
								dense
								:dark="theme"
								label="Correo electrónico"
								:error-messages="errors"
								:value="value"
								@input="customValidation"
								@blur="touch()"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col cols="12" :sm="smSize" xs="12">
					<input-validator
						ref="phone"
						field-name="phone"
						:use-validators="['required', 'phone']"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<vue-tel-input-vuetify
								:background-color="themeColors.inputColor1"
								:color="
									isInvalid
										? themeColors.inputColor2
										: themeColors.validationColor
										? themeColors.validationColor
										: '#219224'
								"
								:style="textColor"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:outlined="textFieldOutlined"
								:solo="textFieldSolo"
								dense
								mode="international"
								label="Número telefónico"
								placeholder=""
								:default-country="countryCode"
								:disabled-fetching-country="true"
								:error-messages="errors"
								:value="value"
								:attach="true"
								:only-countries="[`${countryCode}`]"
								:on-change="setTelInputColor(isInvalid)"
								@input="customValidation"
								@blur="touch()"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col
					v-if="optionalFields.ageRange.set"
					cols="12"
					:sm="smSize"
					xs="12"
				>
					<input-validator
						ref="ageRange"
						field-name="ageRange"
						:use-validators="
							optionalFields.ageRange.required ? ['required'] : []
						"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<input-select
								:is-invalid="isInvalid || !optionalFields.ageRange.required"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:color="themeColors.inputColor2"
								:style="`color:${themeColors.inputColor2}`"
								:outlined="selectOutlined"
								:solo="selectSolo"
								:theme="theme"
								:options="fetchedAges"
								:label="
									`Rango de edad ${
										optionalFields.ageRange.required ? '' : '(opcional)'
									}`
								"
								:error-messages="errors"
								:custom-validation="customValidation"
								:touch="touch"
								:value="value"
								:attach="true"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col
					v-if="optionalFields.destinationPurchase.set"
					cols="12"
					:sm="smSize"
					xs="12"
				>
					<input-validator
						ref="destinationPurchase"
						field-name="destinationPurchase"
						:use-validators="
							optionalFields.destinationPurchase.required ? ['required'] : []
						"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<input-select
								:is-invalid="
									isInvalid || !optionalFields.destinationPurchase.required
								"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:color="themeColors.inputColor2"
								:style="`color:${themeColors.inputColor2}`"
								:outlined="selectOutlined"
								:solo="selectSolo"
								:theme="theme"
								:options="fetchedPurchaseDestinations"
								:label="
									`Destino de compra ${
										optionalFields.destinationPurchase.required
											? ''
											: '(opcional)'
									}`
								"
								:error-messages="errors"
								:custom-validation="customValidation"
								:touch="touch"
								:value="value"
								:attach="true"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col v-if="optionalFields.commune.set" cols="12" :sm="smSize" xs="12">
					<input-validator
						ref="comuna"
						field-name="comuna"
						:use-validators="
							optionalFields.commune.required ? ['required'] : []
						"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<input-select
								:is-invalid="isInvalid || !optionalFields.commune.required"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:color="themeColors.inputColor2"
								:style="`color:${themeColors.inputColor2}`"
								:outlined="selectOutlined"
								:solo="selectSolo"
								:theme="theme"
								:options="fetchedComunas"
								:label="
									`${commune_text} ${
										optionalFields.commune.required ? '' : '(opcional)'
									}`
								"
								:error-messages="errors"
								:custom-validation="customValidation"
								:touch="touch"
								:value="value"
								:attach="true"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col
					v-if="optionalFields.profession.set"
					cols="12"
					:sm="smSize"
					xs="12"
				>
					<input-validator
						ref="profesion"
						field-name="profesion"
						:use-validators="
							optionalFields.profession.required ? ['required'] : []
						"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<input-select
								:is-invalid="isInvalid || !optionalFields.profession.required"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:color="themeColors.inputColor2"
								:style="`color:${themeColors.inputColor2}`"
								:outlined="selectOutlined"
								:solo="selectSolo"
								:theme="theme"
								:options="fetchedProfessions"
								:label="
									`Profesión ${
										optionalFields.profession.required ? '' : '(opcional)'
									}`
								"
								:error-messages="errors"
								:custom-validation="customValidation"
								:touch="touch"
								:value="value"
								:attach="true"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col v-if="optionalFields.sex.set" cols="12" :sm="smSize" xs="12">
					<input-validator
						ref="sex"
						field-name="sex"
						:use-validators="optionalFields.sex.required ? ['required'] : []"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<input-select
								:is-invalid="isInvalid || !optionalFields.sex.required"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:color="themeColors.inputColor2"
								:style="`color:${themeColors.inputColor2}`"
								:outlined="selectOutlined"
								:solo="selectSolo"
								:theme="theme"
								:options="fetchedSexs"
								:label="
									`Sexo ${optionalFields.sex.required ? '' : '(opcional)'}`
								"
								:error-messages="errors"
								:custom-validation="customValidation"
								:touch="touch"
								:value="value"
								:attach="true"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col
					v-if="optionalFields.rentRange.set"
					cols="12"
					:sm="smSize"
					xs="12"
				>
					<input-validator
						ref="rentRange"
						field-name="rentRange"
						:use-validators="
							optionalFields.rentRange.required ? ['required'] : []
						"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<input-select
								:is-invalid="isInvalid || !optionalFields.rentRange.required"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:color="themeColors.inputColor2"
								:style="`color:${themeColors.inputColor2}`"
								:outlined="selectOutlined"
								:solo="selectSolo"
								:theme="theme"
								:options="fetchedRentRanges"
								:label="
									`Rango de renta ${
										optionalFields.rentRange.required ? '' : '(opcional)'
									}`
								"
								:error-messages="errors"
								:custom-validation="customValidation"
								:touch="touch"
								:value="value"
								:attach="true"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-col v-if="optionalFields.observations.set" cols="12" sm="12" xs="12">
					<input-validator
						ref="observation"
						field-name="observation"
						:use-validators="
							optionalFields.observations.required ? ['required'] : []
						"
					>
						<template
							v-slot="{ errors, customValidation, touch, value, isInvalid }"
						>
							<v-textarea
								rows="2"
								:label="
									`${
										customObservationsQuestion
											? customObservationsQuestion
											: 'Observaciones'
									} ${optionalFields.observations.required ? '' : '(opcional)'}`
								"
								:background-color="
									isInvalid || !optionalFields.observations.required
										? themeColors.inputColor1
										: '#219224'
								"
								:color="themeColors.inputColor2"
								:style="`color:${themeColors.inputColor2}`"
								:class="{ getInputClasses: true, 'rounded-0': isSquared }"
								:outlined="textFieldOutlined"
								:solo="textFieldSolo"
								dense
								:error-messages="errors"
								:value="value"
								@input="customValidation"
								@blur="touch()"
							/>
						</template>
					</input-validator>
				</v-col>
				<v-switch
					v-if="optionalFields.receiveNews.set"
					v-model="receiveNewsSwitchValue"
					:dark="theme"
					hide-details="true"
					dense
					class="pb-1 ma-0"
					color="green lighten-2"
				>
					<template v-slot:label>
						<span :style="`color:${themeColors.inputColor2}`"
							>Acepto recibir información y novedades</span
						>
					</template>
				</v-switch>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import InputValidator from '@/components/InputValidator.vue'
import InputSelect from '@/components/InputSelect.vue'
import PeruData from '@/Dictionaries/Peru.js'
import ChileData from '@/Dictionaries/Chile.js'
import MexicoData from '@/Dictionaries/Mexico.js'
import texts from '../mixins/TemplateTexts'

export default {
	name: 'QuoteForm',
	components: {
		InputValidator,
		InputSelect,
	},
	mixins: [texts],
	props: {
		theme: { type: Boolean, default: false },
		textFieldOutlined: { type: Boolean, default: false },
		textFieldSolo: { type: Boolean, default: false },
		selectOutlined: { type: Boolean, default: false },
		selectSolo: { type: Boolean, default: false },
		smSize: { type: String, default: '6' },
		isSquared: { type: Boolean, default: false },
		noGutters: { type: Boolean, default: true },
	},
	data() {
		return {
			receiveNewsSwitchValue: true,
			telInputColor: null,
		}
	},
	computed: {
		...mapState('theme', ['themeColors']),
		...mapState('form', [
			'fetchedComunas',
			'fetchedAges',
			'fetchedProfessions',
			'fetchedPurchaseDestinations',
			'fetchedSexs',
			'fetchedRentRanges',
			'optionalFields',
			'customObservationsQuestion',
		]),
		...mapState('form', ['countryCode']),
		...mapState('general', [
			'parkingLotsMinNumber',
			'warehousesMinNumber',
			'projectData',
			'validateSecondaryAssets',
			'reservation',
		]),
		...mapState('assets', [
			'selectedParkingLotsAmount',
			'selectedWarehousesAmount',
			'secondaryAssetsToQuote',
			'associatedLimits',
		]),
		...mapGetters('form', ['getDNIValidationName', 'getIsChilean']),
		...mapGetters('assets', ['getAssociatedStatus']),
		...mapGetters('reserveInvestors', ['getSkipSecondaryAssetsValidation']),
		getSlang() {
			switch (this.countryCode) {
				case 'CL':
					return ChileData
				case 'PE':
					return PeruData
				case 'MX':
					return MexicoData
				default:
					return ChileData
			}
		},
		getInputClasses() {
			const marginXClass = ['mx-1']
			const marginYClass = ['my-2']

			if (this.textFieldOutlined || this.selectOutlined) return marginXClass

			// Then default ("Regular")
			return [...marginXClass, ...marginYClass]
		},
		getTelInputClasses() {
			const marginXClass = ['mx-1']
			const marginYClass = ['mt-1']

			if (this.textFieldOutlined || this.selectOutlined) return marginXClass

			// Then default ("Regular")
			return [...marginXClass, ...marginYClass]
		},
		textColor() {
			return {
				'--text-color': this.telInputColor,
			}
		},
	},
	mounted() {
		this.telInputColor = this.themeColors.inputColor2
	},
	methods: {
		async recaptcha(payload, opportunity) {
			//FIX TEMPORAL: GOOGLE CAPTCHA NOT WORKING WITH EURO

			// await this.$recaptchaLoaded()
			// const token = await this.$recaptcha('homepage')

			const self = this
			// const version = rootState.general.version == 2 ? 'v3' : 'v2'
			// this.axios
			// 	.post(`${version}/quotes/validate-captcha-token`, {
			// 		token: token,
			// 	})
			// 	.then(response => {
			// 		if (response.data.valid) {
			// 			let event = new Event('main-button-pressed', { bubbles: true })
			// 			document.dispatchEvent(event)
			// 			if (self.reservation || opportunity) {
			// 				self.reservationToken(payload)
			// 			} else {
			// 				if (this.countryCode != 'CL') {
			// 					const dni = payload.rut
			// 					delete payload['rut']
			// 					payload = {
			// 						...payload,
			// 						...{ dni: dni },
			// 					}
			// 				}
			// 				self.sendForm(payload)
			// 			}
			// 		} else {
			// 			self.setDataError()
			// 		}
			// 	})
			// 	.catch(error => {
			// 		console.log(error)
			// 	})

			let event = new Event('main-button-pressed', { bubbles: true })
			document.dispatchEvent(event)
			if (self.reservation || opportunity) {
				self.reservationToken(payload)
			} else {
				if (this.countryCode != 'CL') {
					//const dni = payload.rut
					//delete payload['rut']
					payload = {
						...payload,
						...{ dni: true },
					}
				}
				self.sendForm(payload)
			}
		},
		...mapActions('form', ['sendForm', 'reservationToken']),
		...mapMutations('form', ['setQuoteStatus', 'setCaptchaError']),
		...mapMutations('general', [
			'setMinParkingLotsNumberFulfilled',
			'setMinWarehouseNumberFulfilled',
			'setShowSecondaryAssetsDialog',
			'setQuoteNotification',
			'setFormSubmissionResponded',
		]),
		setDataError() {
			this.setQuoteStatus(false)
			this.setCaptchaError(true)
			const payload = {
				success: false,
				title: 'ERROR',
				body: `Ocurrió un fallo al realizar la ${this.getSlang.formData.quoteName.toLowerCase()} `,
			}
			this.setQuoteNotification(payload)
			this.setFormSubmissionResponded(true)
		},
		submitForm(opportunity = null) {
			// The refs names in the components must be the same as the keys accepted
			// by the API for this to work.
			let toValidateComponentsList = Object.values(this.$refs)
			toValidateComponentsList = toValidateComponentsList.filter(
				input => input.$v != undefined
			)

			toValidateComponentsList.forEach(input => input.$v.$touch())
			if (!this.getSkipSecondaryAssetsValidation) {
				if (
					this.projectData.warehouseService &&
					((this.selectedWarehousesAmount < this.warehousesMinNumber &&
						this.getAssociatedStatus == null &&
						this.validateSecondaryAssets.warehouse) ||
						(this.secondaryAssetsToQuote.length < this.associatedLimits.min &&
							this.getAssociatedStatus == 'BIEN_ASOCIADO'))
				) {
					this.setMinWarehouseNumberFulfilled(false)
					this.setShowSecondaryAssetsDialog(true)
					return
				} else {
					this.setMinWarehouseNumberFulfilled(true)
					this.setShowSecondaryAssetsDialog(false)
				}

				if (
					this.projectData.parkingService &&
					((this.selectedParkingLotsAmount < this.parkingLotsMinNumber &&
						this.getAssociatedStatus == null &&
						this.validateSecondaryAssets.parkingLot) ||
						(this.secondaryAssetsToQuote.length < this.associatedLimits.min &&
							this.getAssociatedStatus == 'BIEN_ASOCIADO'))
				) {
					this.setMinParkingLotsNumberFulfilled(false)
					this.setShowSecondaryAssetsDialog(true)
					return
				} else {
					this.setMinParkingLotsNumberFulfilled(true)
					this.setShowSecondaryAssetsDialog(false)
				}
			}

			if (toValidateComponentsList.every(input => !input.$v.field.$invalid)) {
				let payload = Object.entries(this.$refs)
					.filter(([, component]) => component.field !== '')
					.reduce((object, [refName, component]) => {
						return { ...object, [refName]: component.field }
					}, {})
				payload.email = payload.email.toLowerCase()
				if (this.optionalFields.receiveNews.set)
					payload = { ...payload, receiveNews: this.receiveNewsSwitchValue }
				this.setQuoteStatus(true)
				this.recaptcha(payload, opportunity)
			}
			this.setCaptchaError(false)
		},
		setTelInputColor(isInvalid) {
			this.telInputColor = isInvalid
				? this.themeColors.inputColor2
				: this.themeColors.validationColor
				? this.themeColors.validationColor
				: '#219224'
		},
	},
}
</script>
<style lang="scss">
mobysuite-quote {
	.v-text-field__slot input {
		color: var(--text-color) !important;
	}
	.v-label {
		color: var(--text-color) !important;
	}
	input {
		color: var(--text-color) !important;
	}
	.v-input textarea {
		color: var(--text-color) !important;
	}
	textarea {
		color: var(--text-color) !important;
	}
	.vue-tel-input-vuetify > .v-input > .v-input__control {
		color: var(--text-color) !important;
	}
	.vue-tel-input-vuetify
		> .v-input
		> .v-input__control
		.v-input__slot
		.primary--text {
		color: var(--text-color) !important;
	}
	.v-input
		> .v-input__control
		> .v-input__slot
		> .v-select__slot
		> .v-input__append-inner
		> .v-input__icon
		> .theme--light.v-icon {
		color: var(--text-color) !important;
	}

	.vue-tel-input-vuetify
		> .country-code
		> .v-input
		> .v-input__control
		> .v-input__slot
		> .v-select__slot
		> .v-input__append-inner
		> .v-input__icon {
		display: none !important;
	}
	.v-input > .v-input__control > .v-input__slot fieldset {
		color: var(--text-color) !important;
	}
	.vue-tel-input-vuetify
		> .country-code
		> .v-input
		> .v-input__control
		> .v-input__slot {
		max-height: 32px !important;
	}
	.vue-tel-input-vuetify
		> .country-code
		> .v-input
		> .v-input__control
		> .v-input__slot
		> .v-select__slot
		> .v-select__selections
		input {
		display: none !important;
	}
	.vue-tel-input-vuetify
		> .country-code
		> .v-input
		> .v-input__control
		> .v-input__slot
		> .v-select__slot {
		min-height: 32px !important;
	}
	.v-text-field__slot > label {
		left: 5px !important;
	}
	.vue-tel-input-vuetify.rounded-0 {
		.v-input {
			border-radius: 0px;
		}
	}
}
</style>
