<template>
	<div>
		<v-overlay
			:absolute="true"
			:opacity="0.1"
			align="center"
			justify="center"
			:value="!Object.values(vitalDataLoaded).every(data => data)"
		>
			<v-img contain max-width="300px" :src="realEstateLogoUrl" class="mb-5" />
			<h1 class="font-weight-thin black--text my-2">
				Cargando
			</h1>
			<v-progress-circular color="#555" indeterminate size="40" class="my-3" />
			<p class="subtitle-1 black--text">
				Por favor, espere
			</p>
		</v-overlay>
		<v-overlay
			:absolute="true"
			align="center"
			justify="center"
			:value="sendingQuote"
		>
			<h1 class="font-weight-thin  my-2">Procesando su solicitud</h1>
			<v-progress-circular
				color="#FFF"
				indeterminate
				size="40"
			></v-progress-circular>
			<h4 class="subtitle-1 ">
				Por favor, espere
			</h4>
		</v-overlay>
		<div v-if="Object.values(vitalDataLoaded).every(data => data)">
			<div v-if="atLeastOneVitalResponseFailed || !projectData.webQuoteService">
				<no-assets-error v-if="noAssetsError" />
				<server-error v-if="!noAssetsError" />
			</div>
			<div v-else>
				<div
					v-if="
						formSubmissionResponded &&
							quoteSuccessAndErrorAsDialog &&
							!innerSuccess
					"
				>
					<v-dialog
						:value="true"
						:width="quoteNotification.success ? '1000px' : '500px'"
					>
						<div v-if="quoteNotification.success && !captchaError">
							<view-success-simple v-if="successViewType == 'simple'" />
							<view-success v-else-if="successViewType == 'descriptive'" />
						</div>
						<view-error v-else />
					</v-dialog>
				</div>

				<div
					v-if="
						formSubmissionResponded &&
							!quoteSuccessAndErrorAsDialog &&
							!innerSuccess
					"
				>
					<view-success-simple
						v-if="
							quoteNotification.success &&
								successViewType == 'simple' &&
								!captchaError
						"
					/>
					<view-success
						v-else-if="
							quoteNotification.success &&
								successViewType == 'descriptive' &&
								!captchaError
						"
					/>
					<view-error v-else />
				</div>

				<div
					v-if="
						!formSubmissionResponded ||
							(formSubmissionResponded && quoteSuccessAndErrorAsDialog) ||
							innerSuccess
					"
				>
					<carrusel v-if="templateName == availableTemplateNames.carrusel" />
					<filtro v-else-if="templateName == availableTemplateNames.filtro" />
					<default v-else-if="templateName == availableTemplateNames.default" />
					<simple v-else-if="templateName == availableTemplateNames.simple" />
					<prueba v-else-if="templateName == availableTemplateNames.prueba" />
					<filtro-por-modelo
						v-else-if="templateName == availableTemplateNames.filtroPorModelo"
					/>
					<lotes v-else-if="templateName == availableTemplateNames.lotes" />
					<lotes-por-modelo
						v-else-if="templateName == availableTemplateNames.lotesPorModelo"
					/>
					<secundarios
						v-else-if="templateName == availableTemplateNames.secundarios"
					/>
					<plano v-else-if="templateName == availableTemplateNames.plano" />
					<simple-left
						v-else-if="templateName == availableTemplateNames.simpleLeft"
						:filter-typology="filterTypology"
					/>
					<reserve-investors
						v-else-if="templateName == availableTemplateNames.reserveInvestors"
					/>
					<filtro-por-sub-tipo
						v-else-if="templateName == availableTemplateNames.categorySelector"
					/>
					<cuadrado
						v-else-if="templateName == availableTemplateNames.squaredTemplate"
						:filter-typology="filterTypology"
					/>
					<default v-else />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import Carrusel from '@/views/Carrusel.vue'
import Filtro from '@/views/Filtro.vue'
import Prueba from '@/views/Prueba.vue'
import ServerError from '@/views/ServerError.vue'
import NoAssetsError from '@/views/NoAssetsError.vue'
import ViewError from '@/components/ViewError.vue'
import ViewSuccessSimple from '@/components/ViewSuccessSimple.vue'
import ViewSuccess from '@/components/ViewSuccess.vue'
import Default from '@/views/Default.vue'
import Simple from '@/views/Simple.vue'
import FiltroPorModelo from '@/views/FiltroPorModelo.vue'
import SimpleLeft from '@/views/SimpleLeft.vue'
import Lotes from '@/views/Lotes.vue'
import Plano from '@/views/Plano.vue'
import LotesPorModelo from '@/views/LotesPorModelo.vue'
import Secundarios from '@/views/Secundarios.vue'
import ReserveInvestors from '@/views/ReserveInvestors.vue'
import FiltroPorSubTipo from '@/views/FiltroPorSubTipo.vue'
import Cuadrado from '@/views/Cuadrado.vue'

export default {
	name: 'Main',
	components: {
		Carrusel,
		Filtro,
		Prueba,
		ServerError,
		NoAssetsError,
		ViewError,
		ViewSuccessSimple,
		Default,
		Simple,
		FiltroPorModelo,
		ViewSuccess,
		Lotes,
		Plano,
		LotesPorModelo,
		Secundarios,
		ReserveInvestors,
		SimpleLeft,
		FiltroPorSubTipo,
		Cuadrado,
	},
	props: {
		filterTypology: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			templateName: null,
			availableTemplateNames: {
				carrusel: 'Carrusel',
				filtro: 'Filtro',
				prueba: 'Prueba',
				default: 'Default',
				simple: 'Simple',
				filtroPorModelo: 'FiltroPorModelo',
				lotes: 'Lotes',
				lotesPorModelo: 'LotesPorModelo',
				secundarios: 'Secundarios',
				plano: 'Plano',
				simpleLeft: 'SimpleLeft',
				reserveInvestors: 'ReserveInvestors',
				categorySelector: 'FiltroPorSubTipo',
				squaredTemplate: 'Cuadrado',
				captchaError: false,
			},
		}
	},
	computed: {
		...mapState('theme', ['successViewType', 'innerSuccess']),
		...mapState('general', [
			'template',
			'quoteNotification',
			'formSubmissionResponded',
			'quoteSuccessAndErrorAsDialog',
			'projectData',
			'vitalDataLoaded',
			'atLeastOneVitalResponseFailed',
			'noAssetsError',
			'reservation',
			'realEstateLogoUrl',
			'secondaryAssets',
		]),
		...mapState('form', ['sendingQuote', 'captchaError']),
	},
	created() {
		this.templateName = this.template

		if (this.$route.query.template) {
			this.setTemplate(this.$route.query.template)
			this.templateName = this.template
		}
		if (this.$route.query.project_id)
			this.setProjectId(this.$route.query.project_id)

		if (this.$route.query.real_estate)
			this.setRealEstate(this.$route.query.real_estate)

		if (this.$route.query.success_and_error_as_dialog) {
			this.setQuoteSuccessAndErrorAsDialog(
				this.$route.query.success_and_error_as_dialog == 'true'
			)
		}

		if (this.templateName == this.availableTemplateNames.secundarios) {
			this.setSecondaryAssets(this.secondaryAssets)
		}

		if (this.$route.query.typology) this.setTypology(this.$route.query.typology)

		if (this.$route.query.rooms_amount)
			this.setRoomsAmount(parseInt(this.$route.query.rooms_amount))

		if (this.$route.query.bathrooms_amount)
			this.setBathroomsAmount(parseInt(this.$route.query.bathrooms_amount))

		this.fetchAssets(this.filterTypology)
		this.fetchProjectData().then(() => {
			this.fetchDemographicAllData()
			this.fetchAssetMaps()
		})
	},
	methods: {
		...mapMutations('assets', ['setSecondaryAssets']),
		...mapActions('assets', ['fetchAssets']),
		...mapActions('form', ['fetchDemographicAllData']),
		...mapActions('general', ['fetchProjectData']),
		...mapActions('theme', ['fetchRealEstateInfo']),
		...mapActions('maps', ['fetchAssetMaps']),
		...mapMutations('general', [
			'setTemplate',
			'setProjectId',
			'setRealEstate',
			'setQuoteSuccessAndErrorAsDialog',
			'setTypology',
			'setRoomsAmount',
			'setBathroomsAmount',
		]),
	},
}
</script>
