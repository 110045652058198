<template>
	<v-container
		fluid
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2};`
		"
	>
		<v-row class="mt-2" align="center">
			<v-col cols="12" sm="12" md="7">
				<v-row align="center" justify="center">
					<v-col class="text-center">
						<h1 class="h4 mb-4 font-weight-black">
							{{ getApartmentInfo.rooms }} Dormitorios
							{{ getApartmentInfo.bathrooms }} Baños
						</h1>
						<h4 class="subtitle-1 font-weight-thin">
							Superficie:
							{{
								new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
								}).format(getApartmentInfo.surface)
							}}
							M<sup>2</sup>
						</h4>
					</v-col>
				</v-row>
				<v-row align="center" justify="center">
					<v-col>
						<image-dialog
							:thumb="`${getApartmentInfo.image}`"
							:full="`${getApartmentInfo.image}`"
							:mobile="
								this.$vuetify.breakpoint.name == 'xs' ||
								this.$vuetify.breakpoint.name == 'sm'
									? true
									: false
							"
							:style="
								`position:relative; max-height: 400, max-width: ${imgWidth};`
							"
						></image-dialog>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" sm="12" md="5">
				<v-container>
					<v-row class="d-block">
						<p class="text-subtitle-1">Seleccionar otra planta</p>
						<v-select
							v-model="selectedApartmentId"
							class="ma-1"
							outlined
							dense
							hide-details
							:items="getApartmentsByTypology"
							:item-value="item => item[propertyNames.id]"
							:return-object="false"
							:background-color="themeColors.selectColor"
							:color="themeColors.selectTextColor"
							:style="`color:${themeColors.selectTextColor}`"
							:attach="true"
							@change="updateApartmentId"
						>
							<template v-slot:item="{ item }">
								<span style="margin-right: 10px;">
									{{ item[propertyNames.roomsAmount] }} DORM
									{{ item[propertyNames.bathroomsAmount] }} BAÑO -
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(item[propertyNames.totalArea])
									}}
									M2
								</span>
								<span v-if="item.discountData">
									<span
										v-if="
											item.discountData[propertyNames.discountType] ==
												'GRUPO_BIENES'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="` Reserva hoy con `"
									>
									</span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'PORCENTAJE'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] == 'CONSTANTE'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount]
											)} UF`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] == 'MONTO'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento $${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount]
											)}`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
												item[propertyNames.nonAuthorizedDiscount] != 0 &&
												item[propertyNames.nonAuthorizedDiscount]
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item[propertyNames.nonAuthorizedDiscount] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'MONTO_DESCUENTO_BIEN' &&
												item[propertyNames.authorizedDiscount] != 0 &&
												item[propertyNames.authorizedDiscount]
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(item[propertyNames.authorizedDiscount] * 100)}%`
										"
									></span>
								</span>
							</template>
							<template v-slot:selection="{ item }">
								<span style="margin-right: 10px;">
									{{ item[propertyNames.roomsAmount] }} DORM
									{{ item[propertyNames.bathroomsAmount] }} BAÑO -
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(item[propertyNames.totalArea])
									}}
									M2
								</span>
								<span v-if="item.discountData">
									<span
										v-if="
											item.discountData[propertyNames.discountType] ==
												'GRUPO_BIENES'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="` Reserva hoy con `"
									>
									</span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'PORCENTAJE'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] == 'CONSTANTE'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount]
											)} UF`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] == 'MONTO'
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento $${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item.discountData[propertyNames.discountAmount]
											)}`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
												item[propertyNames.nonAuthorizedDiscount] != 0 &&
												item[propertyNames.nonAuthorizedDiscount]
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(
												item[propertyNames.nonAuthorizedDiscount] * 100
											)}%`
										"
									></span>
									<span
										v-if="
											item.discountData[propertyNames.amountType] ==
												'MONTO_DESCUENTO_BIEN' &&
												item[propertyNames.authorizedDiscount] != 0 &&
												item[propertyNames.authorizedDiscount]
										"
										:style="
											`margin-left: 3px; color:${colorName(
												item.discountData.color
											)}`
										"
										v-text="
											`descuento ${new Intl.NumberFormat('es-CL', {
												maximumFractionDigits: 2,
											}).format(item[propertyNames.authorizedDiscount] * 100)}%`
										"
									></span>
								</span>
							</template>
						</v-select>
					</v-row>
					<v-row>
						<quote-form ref="formRef" class="mt-4" />
					</v-row>
					<v-row>
						<v-col>
							<p
								v-if="quoteLimit != null && quoteLimit <= 0"
								style="color: red"
							>
								Ha excedido el número de cotizaciones permitidas por día.
							</p>
							<v-btn
								id="moby-quote-button"
								min-height="60px"
								:color="themeColors.color3"
								class="my-2"
								block
								x-large
								:style="
									`color: ${
										quoteLimit != null && quoteLimit <= 0
											? '#000'
											: themeColors.buttonTextColor
									}; font-size: 18px`
								"
								:disabled="quoteLimit != null && quoteLimit <= 0 ? true : false"
								@click="$refs.formRef.submitForm()"
							>
								{{ textButton }}
							</v-btn>
						</v-col>
						<v-col
							v-if="getOpportunityStatus && !reservation"
							cols="12"
							xs="12"
							sm="6"
						>
							<v-btn
								id="moby-reservation-button"
								:color="themeColors.color3"
								x-large
								min-height="60px"
								block
								class="mt-2 ml-sm-2"
								:style="
									`color: ${themeColors.buttonTextColor}; font-size: 16px`
								"
								@click="$refs.formRef.submitForm(true)"
							>
								Reservar
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import ImageDialog from '@/components/ImageDialog.vue'

export default {
	name: 'Simple',
	components: {
		QuoteForm,
		ImageDialog,
	},
	data() {
		return {
			selectedApartmentId: null,
		}
	},
	computed: {
		...mapGetters('assets', [
			'getApartmentsByTypology',
			'getApartmentInfo',
			'getOpportunityStatus',
		]),
		...mapState('assets', ['propertyNames']),
		...mapState('theme', ['themeColors']),
		...mapState('general', ['quoteLimit', 'reservation']),
		imgWidth() {
			var w = ''
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					w = '90%'
					break
				default:
					w = 600
			}
			return w
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
	},
	created() {
		this.selectedApartmentId = this.getApartmentsByTypology[0][
			this.propertyNames.id
		]
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
	},
	methods: {
		...mapMutations('assets', [
			'setApartmentId',
			'setAssetMasterType',
			'setOpportunityStatus',
			'setAssociatedStatus',
			'setAssociatedLimits',
		]),
		...mapActions('assets', ['resetSecondaryAssetsToQuote']),
		updateApartmentId() {
			this.setApartmentId(this.selectedApartmentId)
			this.setAssetMasterType(this.getApartmentInfo.assetType)
			if (
				this.getApartmentInfo.associationStatus == 'BIEN_DESVINCULADO' ||
				this.getApartmentInfo.associationStatus == 'BIEN_ASOCIADO'
			) {
				this.resetSecondaryAssetsToQuote()
			}
			if (this.getApartmentInfo.discount) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'GRUPO_BIENES'
				) {
					this.setOpportunityStatus(true)
				} else {
					this.setOpportunityStatus(false)
				}
			} else {
				this.setOpportunityStatus(false)
			}
			if (this.getApartmentInfo.associationStatus) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_DESVINCULADO'
				)
					this.setAssociatedStatus('BIEN_DESVINCULADO')
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_ASOCIADO'
				) {
					this.setAssociatedStatus('BIEN_ASOCIADO')
					const obj = {
						max: this.getApartmentInfo.associationLimits.max,
						min: this.getApartmentInfo.associationLimits.min,
					}
					this.setAssociatedLimits(obj)
				}
			} else {
				this.setAssociatedStatus(null)
			}
		},
		colorName(color) {
			if (color == 'Verde') {
				return 'green'
			} else if (color == 'Rojo') {
				return 'red'
			} else {
				return 'blue'
			}
		},
	},
}
</script>
